<template>
    <div class="row tms-track-zone">

        <div class="col-10 col-xxl-11 tms-track-zone__name">
            <div class="list__item-content">
                <router-link :to="`/zones/${zone.zone.uuid}`" class="tms-objects__object-name list__item-link">{{ zone.zone.name }}</router-link> 
            </div>
        </div>

        <div class="col-2 col-xxl-1 text-end tms-track-zone__control">
            <div class="list__item-btns">
                <div class="me-2 list__item-btn tms-track-zone__control-edit">
                    <span class="material-symbols-outlined" @click="showEdit = !showEdit, setUpdateTrackZonesListMsg({})">
                        edit
                    </span>
                </div>
                <div class="list__item-btn tms-track-zone__control-delete">
                    <span class="material-symbols-outlined" @click.prevent="$emit('trackZoneDeleteProps', { uuid: zone.zone.uuid, show: true })">
                        delete
                    </span>
                </div>
            </div>
        </div>

        <form class="col-12 tms-track-zone__form" v-if="showEdit" @submit.prevent="submitTrackZoneUpdate">

            <div class="mb-4 alert alert--danger tms-track-zone__form-message" v-if="formErrors.length">
                <div class="alert__text">
                    {{ getTranslate.EFormFields }}:
                    <span class="alert__text-item" v-for="(error, index) in formErrors" :key="error">
                        {{ error }}{{ index !== formErrors.length - 1 ? ', ' : '' }} 
                    </span>
                </div>
                <div class="alert__close">
                    <span class="material-symbols-outlined" @click="formErrors = []">close</span>
                </div>
            </div>

            <div class="tms-track-zone__form-field tms-track-zone__form-field--type">
                <div class="tms-track-zone__form-title">{{ getTranslate.TRACK_ZONES_LIST_TYPE }}</div>
                <div class="tms-track-zone__form-value">
                    <select
                        id="TMSTrackZoneListType"
                        @change="TMSTrackZoneListType = $event.target.value"
                        class="input input--block"
                        :class="{ 'is-invalid' : formInvalids.TMSTrackZoneListType }"
                    >
                        <option value="null">...</option>
                        <option 
                            v-for="(trackZoneType, index) in getTrackZonesListTypes" 
                            :key="index" 
                            :value="trackZoneType.purpose"
                            :selected="trackZoneType.purpose == zone.purpose"
                        >
                            {{ trackZoneType.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="tms-track-zone__form-field tms-track-zone__form-field--minspeed">
                <div class="tms-track-zone__form-title">{{ getTranslate.TRACK_ZONES_LIST_MIN_SPEED }}</div>
                <div class="tms-track-zone__form-value">
                    <input
                        id="TMSTrackZoneListMinSpeed" 
                        v-model="TMSTrackZoneListMinSpeed"
                        type="number"
                        class="input input--block"
                    />
                </div>
            </div>

            <div class="tms-track-zone__form-field tms-track-zone__form-field--maxspeed">
                <div class="tms-track-zone__form-title">{{ getTranslate.TRACK_ZONES_LIST_MAX_SPEED }}</div>
                <div class="tms-track-zone__form-value">
                    <input
                        id="TMSTrackZoneListMaxSpeed" 
                        v-model="TMSTrackZoneListMaxSpeed"
                        type="number"
                        class="input input--block"
                    />
                </div>
            </div>

            <div class="tms-track-zone__form-field tms-track-zone__form-field--maxspeed">
                <div class="tms-track-zone__form-title">
                    <input
                        id="TMSTrackZoneListAvailable" 
                        v-model="TMSTrackZoneListAvailable"
                        type="checkbox"
                    />
                    {{ getTranslate.TRACK_ZONES_LIST_AVAILABLE }}
                </div>
            </div>

            <div class="btns tms-track-zone__form-save">
                <button
                    type="submit"
                    :disabled="trackZonesListUpdateProcess"
                    class="btn btn--primary"
                    :class="{ 'btn--spinner' : trackZonesListUpdateProcess }"
                >   
                    <template v-if="trackZonesListUpdateProcess">
                        <div class="spinner" role="status"></div>
                    </template>
                    <span class="btn__text">{{ getTranslate.BTN_SAVE }}</span>
                </button>
            </div>

        </form>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    
    name: 'TMSTrackZoneList',

    data() {

        return {

            TMSTrackZoneListType:      null,
            TMSTrackZoneListMinSpeed:  null,
            TMSTrackZoneListMaxSpeed:  null,
            TMSTrackZoneListAvailable: null,

            showEdit: false,
            formInvalids: {},
            formErrors: []
        }
    },

    props: {

        zone: {

            type: Object,
            required: true
        }
    },

    computed: {

        ...mapState({

            trackZonesListUpdateProcess:    state => state.trackZonesList.trackZonesListUpdateProcess, 
            trackZonesListUpdateMsg:        state => state.trackZonesList.trackZonesListUpdateMsg
        }), 

        ...mapGetters([

            'getTranslate',
            'getTrackZonesListTypes'
        ])
    },

    methods: {

        ...mapActions([

            'updateTrackZonesList'
        ]),

        ...mapMutations([

            'setUpdateTrackZonesListMsg'
        ]),

        submitTrackZoneUpdate() {

            this.formInvalids = {}
            this.formErrors = []

            let data = {

                is_set:     this.zone.is_set,
                trackUuid:  this.zone.trackUuid,
                zoneUuid:   this.zone.zoneUuid,
                available:  this.TMSTrackZoneListAvailable
            }

            if(this.TMSTrackZoneListType) {

                data.purpose = this.TMSTrackZoneListType

            } else {

                this.formInvalids.TMSTrackZoneListType = true
                this.formErrors.push(this.getTranslate.TRACK_ZONES_LIST_TYPE)
            }

            if(this.TMSTrackZoneListMinSpeed) 
                data.minSpeed = this.TMSTrackZoneListMinSpeed

            if(this.TMSTrackZoneListMaxSpeed) 
                data.maxSpeed = this.TMSTrackZoneListMaxSpeed

            if(this.formErrors.length == 0) {

                this.updateTrackZonesList(data)
            }
        }
    },

    mounted() {

        this.TMSTrackZoneListType       = this.zone.purpose     || null
        this.TMSTrackZoneListMinSpeed   = this.zone.minSpeed    || null
        this.TMSTrackZoneListMaxSpeed   = this.zone.maxSpeed    || null
        this.TMSTrackZoneListAvailable  = this.zone.available   || false
    },

    watch: {

        trackZonesListUpdateMsg(msg) {

            if('success' in msg && msg.success) {

                this.showEdit = false
            }
        }
    },

    emits: [

        'trackZoneDeleteProps'
    ]
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/track-zone';
</style>