<template>

    <div class="my-5 tms-track-groups">

        <div class="card tms-track-groups__wrapper">

            <h2 class="page__subheader">{{ getTranslate.TRACK_GROUPS }}</h2>

            <div
                v-if="'success' in trackGroupsMsg && !trackGroupsMsg.success"
                class="alert alert--danger"
            >
                {{ trackGroupsMsg.text }}
            </div>

            <div
                v-if="trackGroupsDeleteMsg.success"
                class="alert alert--success"
            >
                <div class="alert__text">
                    {{ trackGroupsDeleteMsg.text }}
                </div>
                <div class="alert__close" @click="setDeleteTrackGroupsMsg({})">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div>
            </div>

            <div class="list tms-track-groups__list" v-if="trackGroups.array">
                <div class="spinner-overlay" v-if="trackGroupsProcess">
                    <div class="spinner spinner--lg" role="status"></div>
                </div>
                <div class="tms-track-groups__group list__item" v-for="(group, index) in trackGroups.array" :key="index" :to="'/groups/' + group.uuid">
                    <div class="row">
                        <div class="col-10 col-xxl-11">
                            <div class="list__item-content">
                                <router-link :to="'/groups/' + index" class="tms-objects__object-name list__item-link">{{ group.name }}</router-link> 
                            </div>
                        </div>
                        <div class="col-2 col-xxl-1 text-end">
                            <div class="list__item-btns">
                                <div class="list__item-btn tms-objects__object-delete">
                                    <span class="material-symbols-outlined" @click.prevent="deleteTrackGroupUUID = group.uuid, showTrackGroupDelete = true">
                                        delete
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid my-3">
                <div class="row d-flex align-items-center">
                    <div class="col">
                        <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="trackGroups.c?.isBefore || trackGroups.c?.isAfter">
                            <div class="tms-pagination__prev">
                                <button 
                                    type="button" 
                                    aria-label="Previous page" 
                                    class="tms-pagination__prev-btn"
                                    :class="{ 'tms-pagination__prev-btn--disabled' : !trackGroups.c.isBefore }"
                                    :disabled="!trackGroups.c.isBefore"
                                    @click.prevent="prevPage(trackGroups.c.start)"
                                >
                                    <span class="material-symbols-outlined">
                                        chevron_left
                                    </span>
                                </button>
                            </div>
                            <div class="tms-pagination__current">
                                <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                            </div>
                            <div class="tms-pagination__next">
                                <button 
                                    type="button" 
                                    aria-label="Next page" 
                                    class="tms-pagination__next-btn" 
                                    :class="{ 'tms-pagination__next-btn--disabled' : !trackGroups.c.isAfter }"
                                    :disabled="!trackGroups.c.isAfter"
                                    @click.prevent="nextPage(trackGroups.c.stop)"
                                >
                                    <span class="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </button>
                            </div>
                        </nav>
                    </div>
                    <div class="col">
                        <div class="btns btns--right">
                            <a 
                                href="#"
                                class="btn btn--round btn--secondary"
                                @click.prevent="showTrackGroupCreate = true"
                            >
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <transition name="bounce">
            <Modal
                v-if="showTrackGroupCreate"
                max-width="600"
                :header="getTranslate.TRACK_GROUPS_CREATE"
                size="lg"
                :show="showTrackGroupCreate"
                @closeModal="showTrackGroupCreate = false, deleteTrackGroupUUID = null"
            >
                <template v-slot:body>
                    <TMSTrackGroupsCreate :trackUuid="trackUuid" @closeModal="showTrackGroupCreate = false" />
                </template>
            </Modal>
        </transition>

        <transition name="bounce">
            <Modal
                v-if="showTrackGroupDelete"
                max-width="600"
                :header="getTranslate.TRACK_GROUPS_DELETE"
                size="lg"
                :show="showTrackGroupDelete"
                @closeModal="showTrackGroupDelete = false, deleteTrackGroupUUID = null"
            >
                <template v-slot:body>
                    <TMSTrackGroupsDelete :groupUuid="deleteTrackGroupUUID" :trackUuid="trackUuid" @closeModal="showTrackGroupDelete = false, deleteTrackGroupUUID = null" />
                </template>
            </Modal>
        </transition>

    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import Modal                from '@/components/Modal'
import TMSTrackGroupsCreate from '@/components/TMSTrackGroupsCreate'
import TMSTrackGroupsDelete from '@/components/TMSTrackGroupsDelete'

export default {

    name: 'TMSTrackGroups',

    data() {

        return {

            pageNumber: 1,

            TMSTrackGroupsSelected: [],
            addGroupsSelect:        false,
            showAddGroup:           false,
            showTrackGroupCreate:   false, 
            showTrackGroupDelete:   false,
            deleteTrackGroupUuid:   null,

            formInvalids:           {},
            valid:                  false
        }
    },

    props: {

        trackUuid: {

            required: true,
            type: String
        }
    },

    components: {

        Modal,
        TMSTrackGroupsCreate, 
        TMSTrackGroupsDelete
    },

    computed: {

        ...mapState({

            trackGroupsProcess:         state => state.trackGroups.trackGroupsProcess,
            trackGroupsMsg:             state => state.trackGroups.trackGroupsMsg,
            trackGroups:                state => state.trackGroups.trackGroups,

            trackGroupsCreateMsg:       state => state.trackGroups.trackGroupsCreateMsg,

            trackGroupsDeleteProcess:   state => state.trackGroups.trackGroupsDeleteProcess,
            trackGroupsDeleteMsg:       state => state.trackGroups.trackGroupsDeleteMsg,

            groups:                     state => state.groups.groups,
            profile:                    state => state.profile.profile
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readTrackGroups',
            'readGroups',
            'createTrackGroups',
            'deleteTrackGroup'
        ]),

        ...mapMutations([

            'setTrackGroups',
            'setTrackGroupsMsg',
            'setTrackGroupsCreateListMsg',
            'setCreateTrackGroupsMsg',
            'setDeleteTrackGroupsMsg'
        ]),

        
        prevPage(cursor) {

            let params = {

                'c.limit': 6, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor),
                trackUuid: this.trackUuid
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTrackGroups(params).then(msg => {
                
                if(!msg.error) {
                    
                    this.pageNumber-- 
                }
            })
        },

        nextPage(cursor) {

            let params = {

                'c.limit': 6, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor),
                trackUuid: this.trackUuid
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTrackGroups(params).then(msg => {
                
                if(!msg.error) {
                    
                    this.pageNumber++
                }
            })
        },

        submitTrackGroups() {

            this.valid = false
            this.formInvalids = {}

            let data = {

                array: []
            }

            if(this.TMSTrackGroupsSelected.length) {

                this.TMSTrackGroupsSelected.map( uuid => {

                    data.array.push({

                        groupUuid: uuid,
                        is_set: true,
                        trackUuid: this.trackUuid
                    })
                })

                this.valid = true

            } else {

                this.formInvalids.TMSTrackGroupsSelected = true
                this.valid = false
            }

            if(this.valid) {

                this.createTrackGroups(data)
            }
        }
    },

    created() {

        this.readTrackGroups({ 'c.limit': 6, trackUuid: this.trackUuid })
    },

    beforeUnmount() {

        this.setTrackGroups({})
        this.setTrackGroupsMsg({})
        this.setCreateTrackGroupsMsg({})
        this.setDeleteTrackGroupsMsg({})
    },

    watch: {

        trackGroupsCreateMsg(msg) {

            if(msg && msg.success) {

                this.readTrackGroups({ 'c.limit': 6 , trackUuid: this.trackUuid })
            }
        },

        trackGroupsDeleteMsg(msg) {

            if(msg && msg.success) {

                this.deleteTrackGroupUuid = null
                this.readTrackGroups({ 'c.limit': 6 , trackUuid: this.trackUuid })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/track-groups';
</style>