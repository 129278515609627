<template>
    <ul class="tms-menu">
        <li
            v-for="item in items"
            :key="item.title"
            class="tms-menu__item"
        >
            <router-link :to="item.route" class="tms-menu__item-link" @click.prevent="controlAsideLeft()">
                <span class="material-symbols-outlined tms-menu__item-link-icon">
                    {{ item.icon }}
                </span>
                {{ getTranslate[item.title] }}
            </router-link>
        </li>
    </ul>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {

    data() {

        return {

            mobileViewWidth: 1200
        }
    },

    computed: {

        ...mapState({

            profile: state => state.profile.profile
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        items() {

            let items = [
                { 
                    title: 'PAGE_DASHBOARD', 
                    route: '/',
                    icon: 'home' 
                },
                { 
                    title: 'PAGE_GROUPS', 
                    route: '/groups',
                    icon: 'workspaces' 
                },
                { 
                    title: 'PAGE_OBJECTS', 
                    route: '/objects',
                    icon: 'directions_car' 
                },
                { 
                    title: 'PAGE_ZONES', 
                    route: '/zones',
                    icon: 'activity_zone' 
                },
                {
                    title: 'PAGE_TRACKS',
                    route: '/tracks',
                    icon: 'timeline'
                },
                {
                    title: 'PAGE_REPORTS',
                    route: '/reports',
                    icon: 'history'
                }
            ]

            if(this.profile.type == 'adminT') {

                items.push({ 
                    title: 'PAGE_USERS', 
                    route: '/users',
                    icon: 'group' 
                })
            }

            return items
        }
    },

    methods: {

        ...mapMutations([

            'setAsideLeft'
        ]),

        controlAsideLeft() {

            if(window.innerWidth < this.mobileViewWidth) {

                this.setAsideLeft(false)
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/menu';
</style>