<template>

    <div class="tms-auth">

        <div class="tms-auth__card">

            <form @submit.prevent="submitForm($event)" class="tms-auth__form">

                <h1 class="my-4 tms-auth__header">{{ getTranslate.PAGE_AUTH }}</h1>

                <div class="alert alert--danger alert--single" v-if="authMsg && !authMsg.success">
                    {{ authMsg.text }}
                </div>

                <div class="tms-auth__form-fields">

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <input 
                                id="TMSAuthLogin" 
                                type="text"
                                :label="getTranslate.LOGIN" 
                                v-model="TMSAuthLogin"
                                @input="clearError('TMSAuthLogin')"
                                class="my-4 input input--block"
                            />
                        </div>
                        <div class="col-12 col-lg-6">
                            <input 
                                id="TMSAuthPass" 
                                type="password"
                                autocomplete
                                :label="getTranslate.PASSWORD" 
                                v-model="TMSAuthPass"
                                @input="clearError('TMSAuthPass')"
                                class="my-4 input input--block"
                            />
                        </div>
                    </div>

                </div>

                <button 
                    type="submit"
                    class="mt-4 mb-3 btn btn--primary btn--block"
                >

                    <template v-if="!authProcess">
                        Войти
                    </template>

                    <template v-else>
                        ...
                    </template>

                </button>

            </form>

        </div>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {

    name: 'TMSAuth',

    data() {

        return {

            TMSAuthLogin: null,
            TMSAuthPass: null,

            valid: true,
            formInvalids: {}
        }
    },

    computed: {

        ...mapState({

            token:          state => state.token.token,
            authProcess:    state => state.auth.authProcess,
            authMsg:        state => state.auth.authMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'sendAuth'
        ]),

        clearError(field_id) {

            if(this.formInvalids[field_id]) {

                delete this.formInvalids[field_id]
            }
        },

        submitForm() {

            let data = {

                type: "webT",
            }

            this.formInvalids   = {}
            this.valid          = true

            if(this.TMSAuthLogin) {

                data.login = this.TMSAuthLogin

            } else {

                this.formInvalids.TMSAuthLogin = true
                this.valid = false
            }

            if(this.TMSAuthPass) {

                data.password = this.TMSAuthPass

            } else {

                this.formInvalids.TMSAuthPass = true
                this.valid = false
            }

            if(this.valid) {

                this.sendAuth(data)
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/auth';
</style>