<template>
    <div class="page page--zones">
        <div class="tms-zones">
            <TMSZonesMap mode="create" />
        </div>
    </div>
</template>

<script>
import TMSZonesMap from "@/components/TMSZonesMap"

export default {
    name: "ViewZonesCreate",

    components: {
        TMSZonesMap
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/scss/components/zones.scss";
</style>
