/**
 * HEXtoLevel
 * 
 * Выделяет из кода цвета в формате HEX [RGBA] уровень прозрачности [alpha]
 * 
 * @param {String} code - код цвета в HEX [RGBA] - #FF000050
 * @returns {Number} - уровень прозрачности цвета [opacity] в интервале 0-1
 * 
 * @author AKopytenko <aleksandr.kopytenko@nami.ru>
 * 
 */
export default function HEXtoLevel(code) {

    if(code.length == 9) {

        let dec = parseInt(String(code.slice(7)), 16)

        let opacityFloat = ((dec / 2.55) / 100).toFixed(2) // 2.5 - 1% от 255

        if(opacityFloat == 1) return 1
        if(opacityFloat == 0) return 0

        return opacityFloat

    } else {

        return 1
    }
}