<template>

    <div class="tms-group">

        <div
            v-if="'success' in groupUpdateMsg"
            class="alert"
            :class="{

                'alert--success' : groupUpdateMsg.success,
                'alert--danger' : !groupUpdateMsg.success
            }"
        >
            <div class="alert__text">
                {{ groupUpdateMsg.text }}
            </div>
            <div class="alert__close" @click="setUpdateGroupMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <form @submit.prevent="submitForm($event)">

            <div class="list">

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.GROUPS_UUID }}: </h4>
                    <span class="list__item-content">
                        {{ group.uuid }}
                        <span class="material-symbols-outlined list__item-content-icon" @click="copyUUID()">
                            content_copy
                        </span>
                    </span>
                </div>

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.GROUPS_NAME }}: </h4>
                    <template v-if="!showGroupEdit">
                        <span class="list__item-content">{{ group.name }}</span>
                    </template>
                    <template v-else>
                        <input
                            type="text"
                            id="TMSGroupName" 
                            class="input input--block"
                            v-model="TMSGroupName"
                        />
                    </template>
                </div>

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.GROUPS_ICON }}: </h4>

                    <div class="list__item-content">
                        <div class="tms-group__icon">
                            <div class="row">
                                <div class="col-6 d-flex align-items-center" v-if="showGroupEdit">
                                    <div class="tms-group__icon-form">
                                        <input
                                            type="file" 
                                            id="TMSGroupIcon"
                                            class="input input--block"
                                            @change="loadIcon($event)"
                                        >
                                    </div>
                                </div>
                                <div class="d-flex align-items-center" :class="showGroupEdit ? 'col-6' : 'col-12'">
                                    <div class="tms-group__icon-preview">
                                        <template v-if="TMSGroupIcon">
                                            <img :src="TMSGroupIcon" alt="" class="tms-group__icon-preview-img">
                                            <span 
                                                v-if="showGroupEdit"
                                                class="tms-group__icon-delete"
                                                @click="TMSGroupIcon = ''"
                                            >
                                                &times;
                                            </span>
                                        </template>
                                        <template v-else>
                                            {{ getTranslate.FIELDS_VALUE_NOT_SET }}
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.GROUPS_COLOR }}: </h4>
                    <template v-if="!showGroupEdit">
                        <span class="list__item-content">
                            <template v-if="group.color">
                                <div class="tms-group__color" :style="`background-color: ${group.color}`"></div>
                            </template>
                            <template v-else>
                                {{ getTranslate.FIELDS_VALUE_NOT_SET }}
                            </template>
                        </span>
                    </template>
                    <template v-else>
                        <input 
                            type="checkbox" 
                            v-model="showGroupColor"
                            id="showGroupColor"
                        >
                        <ColorPicker 
                            v-if="showGroupColor"
                            :color="group.color || '#FF0000'"
                            @color-change="setGroupColor($event.colors.hex)"
                        />
                    </template>
                    <div class="tms-group__color-descr">
                        {{ getTranslate.GROUPS_COLOR_DESCR }}
                    </div>
                </div>

            </div>

            <div class="container-fluid" v-if="!showGroupEdit">
                <div class="btns btns--right my-4">
                    <a 
                        href="#"
                        class="btn btn--round btn--secondary"
                        @click.prevent="showGroupEdit = true"
                    >
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </a>
                </div>
            </div>

            <div class="container-fluid" v-if="showGroupEdit">
                <div class="btns my-4">
                    <button
                        class="btn"
                        type="button"
                        @click="showGroupEdit = false"
                    >
                        {{ getTranslate.BTN_CANCEL }}
                    </button>

                    <button
                        class="btn btn--primary"
                        type="submit"
                    >
                        {{ getTranslate.BTN_SAVE }}
                    </button>
                </div>
            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ColorPicker } from 'vue-accessible-color-picker'

export default {

    name: 'TMSGroup',

    data() {

        return {

            TMSGroupUUID: '',
            TMSGroupName: '',
            TMSGroupColor: '', 
            TMSGroupIcon: null, 

            showGroupEdit: false,
            showGroupColor: false, 

            formInvalids: {},
            formErrors: [],

            groupIconFormats: ['image/png', 'image/jpeg', 'image/svg+xml'] // MIME-типы разрешённых для загрузки иконок
        }
    },

    props: {

        uuid: {

            required: true,
            type: String
        }
    },

    components: {

        ColorPicker
    },

    computed: {

        ...mapState({

            group:              state => state.groups.group,
            groupUpdateProcess: state => state.groups.groupUpdateProcess,
            groupUpdateMsg:     state => state.groups.groupUpdateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readGroup',
            'updateGroup'
        ]),

        ...mapMutations([

            'setUpdateGroupMsg',
            'setPageHeader'
        ]),

        
        setGroupColor(event) {

            this.TMSGroupColor = event.slice(0,7)
        }, 

        async loadIcon(formEvent) {

            delete this.formInvalids.TMSGroupIcon

            const iconFile = formEvent.target.files[0]

            if(this.groupIconFormats.includes(iconFile.type)) {

                const reader = new FileReader()
                await reader.readAsDataURL(formEvent.target.files[0])

                reader.addEventListener("load", event => {

                    this.TMSGroupIcon = event.target.result
                })

                reader.addEventListener('error', () => {

                    this.formInvalids.TMSGroupIcon = true
                })

            } else {

                this.formInvalids.TMSGroupIcon = true
            }
        },

        submitForm() {

            let data = {

                uuid: this.uuid
            }

            this.formInvalids = {}
            this.formErrors = []

            if(this.TMSGroupName) {

                data.name = this.TMSGroupName

            } else {

                this.formInvalids['TMSGroupName'] = true
                this.formErrors.push(this.getTranslate.GROUPS_NAME)
            }

            data.color = this.TMSGroupColor 
            data.icon = this.TMSGroupIcon

            if(this.formErrors.length == 0) {

                this.updateGroup(data)
            }
        }, 

        async copyUUID() {

            await navigator.clipboard.writeText(this.group.uuid)
        }
    },

    mounted() {

        this.readGroup({ uuid: this.uuid }).then(group => {
            
            if(!group.error) {
                
                this.setPageHeader(group.name)
                this.showGroupColor = Boolean(group.color)
            }
        })
    },

    beforeUnmount() {

        this.setUpdateGroupMsg({})
        this.setPageHeader(null)
    },

    watch: {

        groupUpdateMsg(msg) {

            if('success' in msg && msg.success) {

                this.showGroupEdit = false
            }
        },

        group(group) {

            if(!group.error) {

                this.setPageHeader(group.name)

                this.TMSGroupUUID = group.uuid
                this.TMSGroupName = group.name
                this.TMSGroupIcon = group.icon
                this.TMSGroupColor = group.color
            }
        }, 

        showGroupColor(value) {

            this.TMSGroupColor = value ? this.group.name : ''
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/group';
</style>