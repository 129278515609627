<template>
    <div class="page page--reports">

        <h1 class="page__header">{{ pageHeader || getTranslate.PAGE_REPORTS }}</h1>

        <div class="page__content">
            <TMSReports />
        </div>

    </div>
</template>

<script>
import TMSReports from '@/components/TMSReports'
import { mapGetters, mapState } from 'vuex'

export default {
    
    name: "ViewReports",

    computed: {
        
        ...mapState({

            pageHeader: state => state.pageOptions.pageHeader
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    components: {

        TMSReports
    }
}
</script>