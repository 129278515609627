export default {

    state: {

        ws: {},
        wsMsg: {},
        wsRestore: null,
        wsPing: null,
        wsData: null
    },

    actions: {

        async openWs({rootState, state, dispatch, commit}, payload) {

            if(typeof WebSocket !== 'undefined') {

                if(rootState.token.token) {

                    let ws,
                        params = ''

                    if(payload) {

                        for( let param in payload) {

                            if(param == 'vin_array') {

                                payload.vin_array.map( vin => {

                                    params.length ?
                                        params += '&vin_array=' + vin :
                                        params = '?vin_array=' + vin
                                })

                            } else {

                                params.length ?
                                    params += '&' + param + '=' + payload[param] :
                                    params = '?' + param + '=' + payload[param]
                            }
                        }
                    }

                    (function connect() {

                        ws = new WebSocket(
                            `${rootState.config.wsServer}${params}`, 
                            ['Bearer', rootState.token.token]
                        )

                        ws.onmessage = function(msg) {

                            msg = JSON.parse(msg.data)

                            state.wsData = msg

                            if( !msg.error ) {

                                state.wsMsg = { success: true, text: '' }

                                if(msg.action == 'InfoA') {

                                    if(
                                        msg.eventConfirm && 
                                        msg.eventConfirm.confirm == true &&
                                        rootState.events.events[msg.eventConfirm.eventId] 
                                    ) {

                                        const events = rootState.events.events
                                        let hasNeedConfirm = true
                                        
                                        events[msg.eventConfirm.eventId].confirm = true

                                        for(let eventKey in events) {

                                            const event = events[eventKey]
                                            const eventLevel = rootState.events.eventsConfig[event.type]?.level

                                            if(eventLevel == 'alert' || eventLevel == 'warning') {

                                                if(event.confirm == false) {

                                                    hasNeedConfirm = false
                                                    break
                                                }
                                            }
                                        }

                                        if(hasNeedConfirm) {

                                            commit('setHasWarnings', false)
                                        }

                                        commit('deleteEventAlert', msg.eventConfirm.eventId)
                                    }
                                }

                                if(msg.action == 'PointA') {

                                    dispatch('addPoint', msg)
                                }

                                if(msg.eventSource) dispatch('addEvent', msg)
                                if(msg.confirm)     dispatch('addConfirm', msg.confirm)

                                if(msg.eventId) localStorage.setItem('TMS_LAST_EVENT_ID', msg.eventId)
                            }
                        }

                        ws.onopen = () => {

                            if(state.wsRestore) {

                                clearInterval(state.wsRestore)
                                state.wsRestore = null
                            }

                            state.ws = ws
                            state.wsMsg = { success: true }

                            const lastEventId = localStorage.getItem('TMS_LAST_EVENT_ID')

                            if(lastEventId) {

                                ws.send(JSON.stringify({

                                    eventId: lastEventId
                                }))
                            }

                            state.wsPing = setInterval(() => {

                                state.ws.send('{"ping":{}}')
                            }, 5000)
                        }

                        ws.onerror = () => {

                            state.wsMsg = { success: false, text: 'EWSDisconnected' }

                            clearInterval(state.wsPing)

                            if(!state.wsRestore) state.wsRestore = setInterval( () => connect(), 5000 )

                            ws = {}
                        }

                        ws.onclose = event => {

                            if(event.wasClean) {

                                if(state.wsRestore) {

                                    clearInterval(state.wsRestore)
                                    state.wsRestore = null
                                }

                                return
                            }

                            if(!state.wsRestore) {
                                
                                clearInterval(state.wsPing)
                                state.wsRestore = setInterval( () => connect(), 5000 )
                            }

                            ws = {}
                        }
                    })()

                } else {

                    state.wsMsg = { success: false, text: 'EUnauthRequest' }
                }
            
            } else {

                state.wsMsg = { success: false, text: 'EWSNotSuport' }
            }
        },

        closeWS({state}) {

            if(state.wsRestore) {

                clearInterval(state.wsRestore)
            }

            if(state.ws.readyState == 1) {

                state.ws.close()
            }

            state.ws = {}
        }
    },

    mutations: {

        setWsRestore: (state, data) => state.wsRestore = data
    }
}