<template>

    <div class="tms-users-delete">

        <div
            class="mx-4 my-4 pt-6 pb-4 alert alert--danger tms-user-delete__message"
            v-if="userDeleteMsg && !userDeleteMsg.success"
        >
            {{ getTranslate[userDeleteMsg.text] || userDeleteMsg.text }}
        </div>

        <div class="my-4">Выбранный пользователь будет удалён без возможности восстановления.</div>

        <form class="tms-user-delete__form" @submit.prevent="submitForm()">

            <div class="btns text-right">

                <button
                    class="mr-2 btn btn--secondary"
                    @click="$emit('showDeleteUser', false)"
                >
                    Отмена
                </button>

                <button
                    class="btn btn--primary"
                    :disabled="userDeleteProcess"
                >
                    <span>Удалить</span>
                </button>

            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSUsersCreate',

    props: {

        uuid: {

            required: true,
            type: String
        }
    },

    computed: {

        ...mapState({

            userDeleteProcess: state => state.users.userDeleteProcess,
            userDeleteMsg: state => state.users.userDeleteMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'deleteUser'
        ]),

        ...mapMutations([

            'setDeleteUserMsg'
        ]),

        submitForm() {

            let data = {

                uuid: this.uuid
            }

            this.deleteUser(data)
        }
    },

    mounted() {

        this.setDeleteUserMsg('')
    },

    watch: {

        userDeleteMsg(msg) {

            if(msg.success) {

                this.$emit('showDeleteUser', false)
            }
        }
    },

    emits: [

        'showDeleteUser'
    ]
}
</script>