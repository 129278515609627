<template>

    <div class="tms-objects-create">

        <form class="tms-objects-create__form" @submit.prevent="submitForm()">

            <div
                v-if="'success' in objCreateMsg && !objCreateMsg.success"
                class="alert alert--danger"
            >
                <div class="alert__text">
                    {{ objCreateMsg.text }}
                </div>
                <div class="alert__close" @click="setCreateObjMsg({})">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div>
            </div>

            <input 
                type="text"
                id="TMSObjectName" 
                v-model="TMSObjectName"
                :placeholder="getTranslate.OBJECTS_NAME" 
                @input="clearError('TMSObjectName')"
                class="my-3 input input--block"
                :class="{ 'is-invalid': formInvalids.TMSObjectName }" 
            />

            <input 
                type="text"
                id="TMSObjectImei" 
                v-model="TMSObjectImei"
                :placeholder="getTranslate.OBJECTS_IMEI" 
                datamax="15"
                @input="$event.target.value = $event.target.value.length <= $event.target.datamax ? $event.target.value : $event.target.value.substring(0, 15)"
                class="my-3 input input--block"
                :class="{ 'is-invalid': formInvalids.TMSObjectImei }" 
            />

            <select
                id="TMSObjectGroup" 
                v-model="TMSObjectGroup"
                @input="clearError('TMSObjectGroup')"
                class="my-3 input input--block"
                :class="{ 'is-invalid': formInvalids.TMSObjectGroup }" 
            >
                <option value="null" selected disabled>{{ getTranslate.OBJECTS_GROUP }}</option>
                <option v-for="(group, index) in groups.array" :key="index" :value="index">{{ group.name }}</option>
            </select>

            <input 
                type="password"
                id="TMSObjectPassword" 
                v-model="TMSObjectPassword"
                :placeholder="getTranslate.OBJECTS_PASSWORD" 
                @input="clearError('TMSObjectPassword')"
                autocomplete="off"
                maxlength="15"
                class="my-3 input input--block"
                :class="{ 'is-invalid': formInvalids.TMSObjectPassword }" 
            />

            <input 
                type="password"
                id="TMSObjectPasswordRepeat" 
                v-model="TMSObjectPasswordRepeat"
                :placeholder="getTranslate.OBJECTS_PASSWORD_REPEAT" 
                @input="clearError('TMSObjectPasswordRepeat')"
                autocomplete="off"
                maxlength="15"
                class="my-3 input input--block"
                :class="{ 'is-invalid': formInvalids.TMSObjectPasswordRepeat }" 
            />

            <div class="mt-2 btns btns--right">

                <button
                    class="btn"
                    @click.prevent="$emit('closeModal', false)"
                >
                    {{ getTranslate.BTN_CANCEL }}
                </button>

                <button
                    class="btn btn--primary"
                    type="submit"
                >
                    <span>{{ getTranslate.BTN_CREATE }}</span>
                </button>

            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {

    name: 'TMSObjectsCreate',

    data() {

        return {

            valid: true,

            TMSObjectName: null,
            TMSObjectImei: null,
            TMSObjectGroup: null,
            TMSObjectPassword: null,
            TMSObjectPasswordRepeat: null,

            formInvalids: {}
        }
    },

    computed: {

        ...mapState({

            objCreateProcess:   state => state.objects.objCreateProcess,
            objCreateMsg:       state => state.objects.objCreateMsg,

            groups:             state => state.groups.groups
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'createObj',
            'readObjects',
            'readGroups'
        ]),

        ...mapMutations([

            'setCreateObjMsg'
        ]),

        clearError(field_id) {

            if(this.formInvalids[field_id]) {

                delete this.formInvalids[field_id]
            }
        },

        submitForm() {

            let data = {}

            this.formInvalids   = {}
            this.valid          = true

            if(this.TMSObjectName) {

                data.name = this.TMSObjectName

            } else {

                this.formInvalids.TMSObjectName = true
                this.valid = false
            }

            if(this.TMSObjectImei) {

                data.imei = this.TMSObjectImei

            } else {

                this.formInvalids.TMSObjectImei = true
                this.valid = false
            }

            if(this.TMSObjectPassword) {

                data.password = this.TMSObjectPassword

            } else {

                this.formInvalids.TMSObjectPassword = true
                this.valid = false
            }

            if(!this.TMSObjectPasswordRepeat || this.TMSObjectPassword !== this.TMSObjectPasswordRepeat) {

                this.formInvalids.TMSObjectPasswordRepeat = true
                this.valid = false
            }

            if(this.TMSObjectGroup) {

                data.group_uuid = this.TMSObjectGroup

            } else {

                this.formInvalids.TMSObjectGroup = true
                this.valid = false
            }

            if(this.valid) {

                this.createObj(data)
            }
        }
    },

    mounted() {

        this.readGroups()
    },

    watch: {

        objCreateMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal', false)
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>