<template>

    <div class="page page--user">

        <h1 class="page__header">{{ pageHeader || getTranslate.PAGE_USER }}</h1>

        <div class="page__content">
            <TMSUser :uuid="uuid" />
        </div>

    </div>

</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import TMSUser from '@/components/TMSUser'

export default {

    name: 'ViewUser',

    props: {

        uuid: {

            required: true,
            type: String
        }
    },

    components: {

        TMSUser
    },

    methods: {

        ...mapMutations([

            'setPageHeader'
        ])
    },

    computed: {

        ...mapState({

            pageHeader: state => state.pageOptions.pageHeader
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    beforeUnmount() {

        this.setPageHeader(null)
    }
}
</script>