<template>

    <div class="tms-groups-create">

        <div
            v-if="'success' in groupCreateMsg && !groupCreateMsg.success"
            class="my-4 alert alert--danger tms-groups-create__alert"
        >
            {{ groupCreateMsg.text }}
        </div>

        <form class="tms-groups-create__form" @submit.prevent="submitForm()">

            <div class="mb-4 alert alert--danger tms-track-zone__form-message" v-if="formErrors.length">
                <div class="alert__text">
                    {{ getTranslate.EFormFields }}:
                    <span class="alert__text-item" v-for="(error, index) in formErrors" :key="error">
                        {{ error }}{{ index !== formErrors.length - 1 ? ', ' : '' }} 
                    </span>
                </div>
                <div class="alert__close">
                    <span class="material-symbols-outlined" @click="formErrors = []">close</span>
                </div>
            </div>

            <div class="my-4 input-group">
                <input 
                    id="TMSGroupName" 
                    :placeholder="getTranslate.GROUPS_NAME"
                    v-model="TMSGroupName"
                    @input="clearError('TMSGroupName')"
                    class="input input--block"
                    :class="{ 'is-invalid': formInvalids.TMSGroupName }" 
                />
            </div>
            
            <div class="my-4 input-group">
                <label for="TMSGroupIcon">{{ getTranslate.GROUPS_ICON }}</label>
                <input
                    type="file" 
                    id="TMSGroupIcon"
                    class="input input--block"
                    @change="loadIcon($event)"
                >
            </div>

            <div class="my-4 input-group">
                <input 
                    type="checkbox" 
                    @change="showGroupColor = !showGroupColor" 
                    id="showGroupColor"
                >
                <label for="showGroupColor">{{ getTranslate.GROUPS_COLOR }}</label>
                <ColorPicker 
                    v-if="showGroupColor"
                    @color-change="setGroupColor($event.colors.hex)"
                />
            </div>

            <div class="mt-2 btns btns--right">
                <button
                    type="button"
                    class="btn"
                    @click="$emit('closeModal', true)"
                >
                    {{ getTranslate.BTN_CANCEL }}
                </button>
                <button
                    class="btn btn--primary"
                    type="submit"
                >
                    <template v-if="groupCreateProcess">
                        <div class="spinner-border" role="status"></div>
                    </template>
                    <template v-else>
                        {{ getTranslate.BTN_CREATE }}
                    </template>
                </button>
            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ColorPicker } from 'vue-accessible-color-picker'

export default {

    name: 'TMSGroupsCreate',

    data() {

        return {

            TMSGroupName: null,
            TMSGroupColor: null,
            TMSGroupIcon: null,

            showGroupColor: false, 

            formInvalids: {},
            formErrors: [],

            groupIconFormats: ['image/png', 'image/jpeg'] // MIME-типы разрешённых для загрузки иконок групп
        }
    },

    components: {

        ColorPicker
    }, 

    computed: {

        ...mapState({

            groups:             state => state.groups.groups,
            groupCreateProcess: state => state.groups.groupCreateProcess,
            groupCreateMsg:     state => state.groups.groupCreateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'createGroup'
        ]),

        ...mapMutations([

            'setCreateGroupMsg'
        ]),

        clearError(field_id) {

            if(this.formInvalids[field_id]) {

                delete this.formInvalids[field_id]
            }
        },

        setGroupColor(event) {

            this.TMSGroupColor = event.slice(0,7)
        }, 

        async loadIcon(formEvent) {

            delete this.formInvalids.TMSGroupIcon

            const iconFile = formEvent.target.files[0]

            if(this.groupIconFormats.includes(iconFile.type)) {

                const reader = new FileReader()
                await reader.readAsDataURL(formEvent.target.files[0])

                reader.addEventListener("load", event => {

                    this.TMSGroupIcon = event.target.result
                })

                reader.addEventListener('error', () => {

                    this.formInvalids.TMSGroupIcon = true
                })
            
            } else {

                this.formInvalids.TMSGroupIcon = true
            }
        },

        submitForm() {

            let data = {}

            this.formInvalids = {}
            this.formErrors = []

            if(this.TMSGroupName) {

                data.name = this.TMSGroupName

            } else {

                this.formInvalids['TMSGroupName'] = true
                this.formErrors.push(this.getTranslate.GROUPS_NAME)
            }

            if(this.TMSGroupColor)  data.color  = this.TMSGroupColor 
            if(this.TMSGroupIcon)   data.icon   = this.TMSGroupIcon

            if(this.formErrors.length == 0) {

                this.createGroup(data)
            }
        }
    },

    mounted() {

        this.setCreateGroupMsg({})
    },

    beforeUnmount() {

        this.valid = false
        this.TMSGroupName = null
    },

    watch: {

        groupCreateMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal', false)
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>