<template>

    <div class="page page--users">

        <h1 class="page__header">{{ getTranslate.PAGE_USERS }}</h1>

        <div class="page__content">
            <TMSUsers />
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import TMSUsers from '@/components/TMSUsers'

export default {

    name: 'ViewUsers',

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    components: {

        TMSUsers
    }
}
</script>