<template>

    <div class="page page--objects">

        <h1 class="page__header">{{ getTranslate.PAGE_OBJECTS }}</h1>

        <div class="page__content">
            <TMSObjects />
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import TMSObjects from '@/components/TMSObjects'

export default {

    name: 'ViewObjects',

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    components: {

        TMSObjects
    }
}
</script>