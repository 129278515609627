<template>
    <div class="px-3 px-sm-2">
        <form class="row g-0">
            <div class="col-12 mb-2">
                <label for="zoneName" class="form-label">
                    {{ getTranslate.ZONE_NAME }}
                </label>
                <input
                    v-model="zoneName"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': formInvalids.zoneName }"
                    id="zoneName"
                    :placeholder="getTranslate.ZONE_NAME"
                    @input="delete formInvalids.zoneName"
                />
                <div
                    class="invalid-feedback"
                    :class="{ 'd-block': formInvalids.zoneName }"
                >
                    {{ formInvalids.zoneName }}
                </div>
            </div>
            <div class="col-12 mb-2">
                <label for="zoneZIndex" class="form-label">Z-Index</label>
                <input
                    v-model.number="zoneZIndex"
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': formInvalids.zoneZIndex }"
                    id="zoneZIndex"
                    @input="delete formInvalids.zoneZIndex"
                />
                <div
                    class="invalid-feedback"
                    :class="{ 'd-block': formInvalids.zoneZIndex }"
                >
                    {{ formInvalids.zoneZIndex }}
                </div>
            </div>
            <div v-show="!isHideDrawControls" class="col-12">
                <label for="zoneType" class="form-label">
                    {{ getTranslate.ZONE_TYPE }}
                </label>
                <select
                    v-model="zoneType"
                    class="form-select mb-2"
                    id="zoneType"
                >
                    <option selected disabled value="">
                        {{ getTranslate.ZONES_CREATE_ERROR_TYPE }}
                    </option>
                    <option
                        v-for="(type, idx) in zoneTypes"
                        :key="idx"
                        :value="type.value"
                    >
                        {{ type.name }}
                    </option>
                </select>
            </div>
            <div class="col-12">
                <label class="form-label">
                    {{ getTranslate.ZONE_FIGURE_TYPE }}
                </label>
            </div>
            <div class="col-12">
                <input
                    v-if="zoneType == zoneTypes[0].value"
                    v-model="figureType"
                    value="polyline"
                    type="radio"
                    class="btn-check"
                    name="options-base"
                    id="polyline"
                    autocomplete="off"
                />
                <label
                    v-if="zoneType == zoneTypes[0].value"
                    class="btn"
                    for="polyline"
                >
                    <span class="material-symbols-outlined">polyline</span>
                </label>

                <input
                    v-if="zoneType == zoneTypes[0].value"
                    v-model="figureType"
                    value="polygon"
                    type="radio"
                    class="btn-check"
                    name="options-base"
                    id="polygon"
                    autocomplete="off"
                />
                <label
                    v-if="zoneType == zoneTypes[0].value"
                    class="btn"
                    for="polygon"
                >
                    <span class="material-symbols-outlined">hexagon</span>
                </label>

                <input
                    v-if="zoneType == zoneTypes[0].value"
                    v-model="figureType"
                    value="circle"
                    type="radio"
                    class="btn-check"
                    name="options-base"
                    id="circle"
                    autocomplete="off"
                />
                <label
                    v-if="zoneType == zoneTypes[0].value"
                    class="btn"
                    for="circle"
                >
                    <span class="material-symbols-outlined">circle</span>
                </label>

                <input
                    v-model="figureType"
                    value="sector"
                    type="radio"
                    class="btn-check"
                    name="options-base"
                    id="sector"
                    autocomplete="off"
                />
                <label class="btn" for="sector">
                    <span class="material-symbols-outlined">signal_wifi_0_bar</span>
                </label>
            </div>

            <div
                v-if="figureType === 'circle' || figureType === 'sector'"
                class="col-12"
            >
                Радиус: {{ Math.round(figureRadius) }} м.
            </div>

            <hr class="mt-2" />

            <div
                class="d-flex justify-content-end"
                :class="{ 'mb-3': !formInvalids.isDrawn && isHideDrawControls }"
            >
                <button
                    class="btn btn-outline-secondary me-2"
                    type="button"
                    @click="$emit('finishDrawing')"
                    :disabled="
                        figureType == '' ||
                        figureType == 'circle' ||
                        figureType == 'sector' ||
                        isFinishBtnDisabled
                    "
                >
                    <span class="material-symbols-outlined">done</span>
                </button>
                <button
                    class="btn btn-outline-secondary me-2"
                    type="button"
                    @click="cancelDrawing"
                    :disabled="figureType == '' || isCancelBtnDisabled"
                >
                    <span class="material-symbols-outlined">close</span>
                </button>
                <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="clearDrawing"
                    :disabled="!isDrawn"
                >
                    <span class="material-symbols-outlined">delete</span>
                </button>
            </div>
            <div v-if="formInvalids.isDrawn" class="col-12">
                <div
                    class="invalid-feedback my-2"
                    :class="{ 'd-block': formInvalids.isDrawn }"
                >
                    {{ formInvalids.isDrawn }}
                </div>
            </div>

            <div
                class="col-12"
                v-show="!isHideDrawControls && figureType !== 'polyline'"
            >
                <div class="mb-3 mt-3">
                    <label class="form-label">
                        {{ getTranslate.ZONE_FIGURE_FILL }}
                    </label>
                    <ColorPicker
                        theme="light"
                        :color="fillColor"
                        @color-change="updateFillColor"
                    />
                </div>
            </div>
            <div v-if="!isHideDrawControls && figureType !== 'polyline'" class="col-12">
                <div class="form-check my-2">
                    <input
                        v-model="isStroke"
                        class="form-check-input"
                        type="checkbox"
                        id="isStroke"
                    />
                    <label class="form-check-label" for="isStroke">
                        {{ getTranslate.ZONE_ISSTROKE }}
                    </label>
                </div>
            </div>
            <div class="col-12" v-show="!isHideDrawControls && isStroke">
                <div class="mb-3">
                    <label class="form-label">
                        {{ getTranslate.ZONE_FIGURE_STROKE }}
                    </label>
                    <ColorPicker
                        theme="light"
                        :color="strokeColor"
                        @color-change="updateStrokeColor"
                    />
                </div>
            </div>

            <div class="col-12" v-show="!isHideDrawControls && isStroke">
                <label for="zoneLineThickness" class="form-label">
                    {{ getTranslate.ZONE_FIGURE_THICKNESS }}:
                    {{ zoneLineThickness }}
                </label>
                <input
                    v-model="zoneLineThickness"
                    type="range"
                    class="form-range mb-2"
                    min="1"
                    max="100"
                    step="1.0"
                    id="zoneLineThickness"
                />
            </div>
            <div v-if="zoneType == zoneTypes[2].value" class="col-12 mb-2">
                <VDatePicker
                    v-model="scheduleRange"
                    mode="dateTime"
                    :locale="lang"
                    :popover="{ placement: 'bottom', visibility: 'click' }"
                    is24hr
                    is-range
                >
                    <template #default="{ inputValue, inputEvents }">
                        <label for="openTime" class="form-label">
                            {{ getTranslate.ZONE_TIME_START }}
                        </label>
                        <input
                            class="form-control"
                            id="openTime"
                            readonly
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                        />
                        <label for="closeTime" class="form-label">
                            {{ getTranslate.ZONE_TIME_END }}
                        </label>
                        <input
                            class="form-control"
                            id="closeTime"
                            readonly
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                        />
                    </template>
                </VDatePicker>
            </div>
            <div class="col-12">
                <div class="form-check my-2">
                    <input
                        v-model="zoneAvailability"
                        class="form-check-input"
                        type="checkbox"
                        id="zoneAvailability"
                    />
                    <label class="form-check-label" for="zoneAvailability">
                        {{ getTranslate.ZONE_AVAILABILITY }}
                    </label>
                </div>
            </div>
            <div class="col-12">
                <div class="form-check my-2">
                    <input
                        v-model="zoneDisplay"
                        class="form-check-input"
                        type="checkbox"
                        id="zoneDisplay"
                    />
                    <label class="form-check-label" for="zoneDisplay">
                        {{ getTranslate.ZONE_DISPLAY }}
                    </label>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import { ColorPicker } from "vue-accessible-color-picker"

export default {   
    data() {

        return {

            figureType: "",

            zoneName: "",
            zoneType: "",
            zoneAvailability: true,
            zoneDisplay: true,
            zoneZIndex: 100,
            zoneLineThickness: "0",
            strokeColor: "#3388ff",
            fillColor: "#3388ff",
            scheduleRange: {
                start: new Date(),
                end: new Date()
            },
            isStroke: false,

            formInvalids: {}
        }
    },

    props: {

        zone: {
            type: Object,
            required: false
        },

        isFinishBtnDisabled: {
            type: Boolean,
            required: true
        },

        isDrawn: {
            type: Boolean,
            required: true
        },

        isCancelBtnDisabled: {
            type: Boolean,
            required: true
        },

        isHideDrawControls: {
            type: Boolean,
            required: true
        },

        figureRadius: {
            type: Number,
            required: true
        }
    },

    expose: [

        "resetFigureType", 
        "submitForm"
    ],
    
    computed: {

        ...mapGetters([

            "getTranslate"
        ]),

        //Список типов зон
        zoneTypes() {

            return [
                {
                    name: this.getTranslate.ZONES_TYPE_ORDINARY,
                    value: "ordinary"
                },
                { 
                    name: this.getTranslate.ZONES_TYPE_GATE, 
                    value: "gate" 
                },
                {
                    name: this.getTranslate.ZONES_TYPE_ROADWORKS,
                    value: "roadworks"
                },
                {
                    name: this.getTranslate.ZONES_TYPE_ACCIDENT,
                    value: "traffic_accident"
                },
                {
                    name: this.getTranslate.ZONES_TYPE_DANGER,
                    value: "dangerous_intersection"
                },
                { 
                    name: this.getTranslate.ZONES_TYPE_STOP, 
                    value: "stop" 
                },
                {
                    name: this.getTranslate.ZONES_TYPE_ROAD_CLOSED,
                    value: "road_closed"
                },
            ]

        },

        //Выбранный язык
        lang() {

            return localStorage.getItem("TMS_LANG") || "RU"
        },
    },

    components: { 
        ColorPicker 
    },

    methods: {
        ...mapActions([

            "createZone", 
            "updateZone"
        ]),

        //Отмены рисования
        cancelDrawing() {

            this.figureType = ""

            this.$emit("cancelDrawing")
        },

        //Очистка нарисованной фигуры
        clearDrawing() {

            this.resetFigureType()

            this.$emit("clearFigureLayer")
        },

        //Сбросить тип фигуры
        resetFigureType() {

            this.figureType = ""
        },

        //Цвет линии
        updateStrokeColor(value) {
            
            this.strokeColor = value.colors.hex

            this.$emit("changeFigureOptions", {
                weight: this.zoneLineThickness,
                color: value.colors.hex,
                fillColor: this.fillColor
            })
        },

        //Цвет заливки
        updateFillColor(value) {
            
            this.fillColor = value.colors.hex

            this.$emit("changeFigureOptions", {
                weight: this.zoneLineThickness,
                color: this.strokeColor,
                fillColor: value.colors.hex
            })
        },

        async submitForm(figure, submitType) {
            
            if (!this.zoneName) {

                this.formInvalids.zoneName = this.getTranslate.ZONE_ENTER_NAME
            }

            if (this.zoneZIndex === "") {

                this.formInvalids.zoneZIndex = this.getTranslate.ZONE_ENTER_ZINDEX
            }

            if (!this.isDrawn) {

                this.formInvalids.isDrawn = this.getTranslate.ZONE_DRAW_MESSAGE
            }

            if (Object.keys(this.formInvalids).length || !figure) {
                
                return
            } else {

                const payload = {
                    name: this.zoneName,
                    options: {
                        dashArray: "",
                        display: this.zoneDisplay,
                        fill: this.fillColor,
                        // stroke: this.strokeColor,
                        // strokeWidth: this.zoneLineThickness,
                        zIndex: this.zoneZIndex
                    },
                    purposes: {
                        available: this.zoneAvailability,
                        type: this.zoneType,
                        schedule: []
                    },
                    zone: {}
                }

                if (this.isStroke) {
                    payload.options.stroke = this.strokeColor
                    payload.options.strokeWidth = this.zoneLineThickness
                }

                switch (this.figureType) {
                    case "polyline":
                        payload.zone.line = {
                            array: figure._latlngs.map(item => {
                                return { lat: item.lat, lon: item.lng }
                            }),
                            distance: 0
                        }
                        delete payload.options.fill
                        break
                    case "polygon":
                        payload.zone.polygon = {
                            array: figure._latlngs[0].map(item => {
                                return { lat: item.lat, lon: item.lng }
                            })
                        }
                        payload.zone.polygon.array.push(
                            payload.zone.polygon.array[0]
                        )
                        break
                    case "circle":
                        payload.zone.circle = {
                            point: {
                                lat: figure._latlng.lat,
                                lon: figure._latlng.lng
                            },
                            radius: figure._mRadius
                        }
                        break
                    case "sector":
                        payload.zone.sector = {
                            circle: {
                                point: {
                                    lat: figure._center.lat,
                                    lon: figure._center.lng
                                },
                                radius: figure._outerRadius
                            },
                            endBearing: Math.round(figure._endBearing),
                            startBearing: Math.round(figure._startBearing)
                        }
                        break
                }

                if (this.zoneType === this.zoneTypes[2].value) {

                    payload.purposes.schedule = [
                        {
                            openTime: this.scheduleRange.start
                                .getTime()
                                .toString(),
                            closeTime: this.scheduleRange.end
                                .getTime()
                                .toString()
                        }
                    ]
                }

                if (this.zone && submitType === "update") {

                    payload.uuid = this.zone.uuid
                }

                if (submitType === "create") {

                    const data = await this.createZone(payload)

                    if (data.uuid) {

                        this.$router.push("/zones")
                    } else {

                        console.log(data)
                    }
                }

                if (submitType === "update") {

                    const data = await this.updateZone(payload)

                    if (data.uuid) {

                        this.$router.push("/zones")
                    } else {
                        //Сообщение об ошибке
                        //...
                    }
                }
            }
        }
    },

    created() {

        if (!this.zone) this.zoneType = this.zoneTypes[0].value
    },

    mounted() {

        if (this.zone) {
            
            this.zoneName = this.zone.name
            this.zoneType = this.zone.purposes.type
            this.zoneAvailability = this.zone.purposes.available
            this.zoneDisplay = this.zone.options.display
            this.zoneZIndex = this.zone.options.zIndex
            this.zoneLineThickness = this.zone.options.strokeWidth || "0"
            this.strokeColor = this.zone.options.stroke || this.strokeColor
            this.fillColor = this.zone.options.fill || this.fillColor

            if (!this.zone.options.strokeWidth || !this.zone.options.stroke) {
                this.isStroke = false
            } else {
                this.isStroke = true
            }

            if (this.zone.zone["line"]) {
                this.figureType = "polyline"
            }

            if (this.zone.zone["polygon"]) {
                this.figureType = "polygon"
            }

            if (this.zone.zone["circle"]) {
                this.figureType = "circle"
            }

            if (this.zone.zone["sector"]) {
                this.figureType = "sector"
            }

            if (this.zone.purposes.type === "roadworks") {

                this.scheduleRange.start = new Date(
                    Number(this.zone.purposes.schedule[0].openTime)
                )

                this.scheduleRange.end = new Date(
                    Number(this.zone.purposes.schedule[0].closeTime)
                )
            }
        }
    },

    watch: {

        //Изменение типа зоны
        zoneType(newVal) {

            if (this.figureType !== "sector" && newVal !== this.zoneTypes[0].value) {

                //очистить нарисованную фигуру, которая не соответствует выбранному типу
                this.clearDrawing()
            }
        },

        //Изменение состояния нарисованной фигуры
        isDrawn(newVal) {

            //Сбросить ошибку нарисованной фигуры
            if (newVal) delete this.formInvalids.isDrawn
        },

        //Изменение типа рисуемой фигуры
        figureType(newVal) {

            this.$emit("changeFigureType", newVal)

            if (newVal === "polyline") this.isStroke = true
            
            //Если толщина линии перед рисованием указана 0
            if (newVal === "polyline" && this.zoneLineThickness === "0")
                this.zoneLineThickness = "3"
        },

        //Контур зон
        isStroke(newVal) {
            
            if (newVal) {
                 
                if (!this.zone) {
                    this.zoneLineThickness = "3"
                    this.strokeColor = "#3388ff"
                }

                // this.zoneLineThickness = "3"
                if (this.zoneLineThickness == "0") this.zoneLineThickness = "3"
                if (!this.strokeColor) this.strokeColor = "#3388ff"
                
            } else {
                
                this.zoneLineThickness = "0"
                // this.strokeColor = ""
            }
        },

        //Изменение толщины линии
        zoneLineThickness(newVal) {

            this.$emit("changeFigureOptions", {
                color: this.strokeColor,
                weight: newVal,
                fillColor: this.fillColor
            })
        }
    },

    emits: {

        finishDrawing: null,
        cancelDrawing: null,
        clearFigureLayer: null,
        changeFigureType: null,
        changeFigureOptions: null,
    },
}
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@import "bootstrap/scss/root";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/forms";
</style>
<style lang="scss">
@import "@/assets/styles/scss/components/zones-form2.scss";
</style>
