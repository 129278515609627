<template>

    <div class="page page--profile">

        <h1 class="page__header">{{ getTranslate.PAGE_PROFILE }}</h1>

        <div class="page__content">
            <TMSProfile />
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import TMSProfile from '@/components/TMSProfile'

export default {

    name: 'ViewProfile',

    components: {

        TMSProfile
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>