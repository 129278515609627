<template>

    <div class="tms-objects">

        <div class="p-3 tms-objects__search">
            <div class="input-group input-wrapper">
                <input 
                    type="text" 
                    class="input input--block search__input" 
                    :placeholder="getTranslate['SEARCH_PLACEHOLDER']" 
                    v-model="inputSearch"
                >
                <template v-if="inputSearch.length > 2">
                    <span class="material-symbols-outlined input-wrapper__icon" @click="inputSearch = ''">
                        cancel
                    </span>
                </template>
                <template v-else>
                    <span class="material-symbols-outlined input-wrapper__icon">
                        search
                    </span>
                </template>
            </div>
        </div>

        <div
            v-if="objCreateMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">
                {{ objCreateMsg.text }}
            </div>
            <div class="alert__close" @click="setCreateObjMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="objDeleteMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">
                {{ objDeleteMsg.text }}
            </div>
            <div class="alert__close" @click="setDeleteObjMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="'success' in objectsMsg && !objectsMsg.success"
            class="alert alert--danger"
        >
            {{ objectsMsg.text }}
        </div>

        <div class="list tms-objects__list" v-if="Object.keys(objects.array).length">
            <div class="spinner-overlay" v-if="objectsProcess">
                <div class="spinner spinner--lg" role="status"></div>
            </div>
            <div class="list__item tms-objects__object" v-for="(obj, index) in objects.array" :key="index">
                <div class="row">
                    <div class="col-10 col-xxl-11">
                        <div class="list__item-content">
                            <router-link :to="'/objects/' + index" class="tms-objects__object-name list__item-link">{{ obj.name }}</router-link> 
                        </div>
                    </div>
                    <div class="col-2 col-xxl-1 text-end">
                        <div class="list__item-btns">
                            <div class="list__item-btn tms-objects__object-delete">
                                <span class="material-symbols-outlined" @click.prevent="deleteObjectUUID = index, showDeleteObject = true">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid my-3" v-if="objects.c?.isBefore || objects.c?.isAfter">
            <div class="row d-flex align-items-center">
                <div class="col">
                    <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="showPagination">
                        <div class="tms-pagination__prev">
                            <button 
                                type="button" 
                                aria-label="Previous page" 
                                class="tms-pagination__prev-btn"
                                :class="{ 'tms-pagination__prev-btn--disabled' : !objects.c.isBefore }"
                                :disabled="!objects.c.isBefore"
                                @click.prevent="prevPage(objects.c.start)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_left
                                </span>
                            </button>
                        </div>
                        <div class="tms-pagination__current">
                            <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                        </div>
                        <div class="tms-pagination__next">
                            <button 
                                type="button" 
                                aria-label="Next page" 
                                class="tms-pagination__next-btn" 
                                :class="{ 'tms-pagination__next-btn--disabled' : !objects.c.isAfter }"
                                :disabled="!objects.c.isAfter"
                                @click.prevent="nextPage(objects.c.stop)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_right
                                </span>
                            </button>
                        </div>
                    </nav>
                </div>
                <div class="col">
                    <div class="btns btns--right">
                        <a 
                            href="#"
                            class="btn btn--round btn--secondary"
                            @click.prevent="showCreateObject = true"
                        >
                            <span class="material-symbols-outlined">
                                add
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <transition name="bounce">
            <Modal
                v-if="showCreateObject"
                max-width="600"
                :header="getTranslate.OBJECTS_CREATE"
                size="lg"
                :show="showCreateObject"
                @closeModal="showCreateObject = false"
            >
                <template v-slot:body>
                    <TMSObjectsCreate @closeModal="showCreateObject = false" />
                </template>
            </Modal>
        </transition>

        <transition name="bounce">
            <Modal
                v-if="showDeleteObject"
                max-width="600"
                :header="getTranslate.OBJECTS_DELETE"
                size="lg"
                :show="showDeleteObject"
                @closeModal="showDeleteObject = false, deleteObjectUUID = null"
            >
                <template v-slot:body>
                    <TMSObjectsDelete :uuid="deleteObjectUUID" @closeModal="showDeleteObject = false, deleteObjectUUID = null" />
                </template>
            </Modal>
        </transition>

    </div>

</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import TMSObjectsCreate from '@/components/TMSObjectsCreate'
import TMSObjectsDelete from '@/components/TMSObjectsDelete'
import Modal            from '@/components/Modal'

export default {

    name: 'TMSObjects',

    data() {

        return {

            showCreateObject: false,
            showDeleteObject: false,

            deleteObjectUUID: null,

            pageNumber: 1,
            inputSearch: ''
        }
    },

    components: {

        TMSObjectsCreate,
        TMSObjectsDelete,
        Modal
    },

    computed: {

        ...mapState({

            objects:            state => state.objects.objects,
            objectsProcess:     state => state.objects.objectsProcess,
            objectsMsg:         state => state.objects.objectsMsg,
            objDeleteMsg:       state => state.objects.objDeleteMsg,
            objCreateMsg:       state => state.objects.objCreateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        showPagination() {

            return Object.keys(this.objects.array).length && 
                (
                    this.objects.c.isBefore || 
                    this.objects.c.isAfter
                )
        }
    },

    methods: {

        ...mapActions([

            'readObjects'
        ]),

        ...mapMutations([

            'setCreateObjMsg',
            'setDeleteObjMsg'
        ]),

        prevPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readObjects(params).then(() => this.pageNumber-- )
        },

        nextPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readObjects(params).then(() => this.pageNumber++)
        }
    },

    mounted() {

        this.readObjects({ 'c.limit': 15 })
    },

    beforeUnmount() {

        this.setCreateObjMsg({})
        this.setDeleteObjMsg({})
    },

    watch: {

        inputSearch(input) {

            let params = {

                'c.limit': 15
            }

            if(input.length > 2)
                params['c.q'] = input

            this.readObjects(params).then(() => this.pageNumber = 1)
        },

        objCreateMsg(msg) {

            if(msg && msg.success) {

                this.readObjects({ 'c.limit': 15 })
            }
        },

        objDeleteMsg(msg) {

            if(msg && msg.success) {

                this.readObjects({ 'c.limit': 15 })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/objects.scss';
</style>