import router from '@/router/index.js'

export default {

    state: {

        auth:           false,
        authProcess:    false,
        authMsg:        null,
    },

    actions: {

        /**
         * Отправляет запрос на авторизацию
         * 
         * @param {Object} payload - объект со значениями полей формы авторизации
         */
        async sendAuth ({state, getters, dispatch}, payload) {

            state.authProcess = true

            const request = await dispatch('fetchRequest', {
                address: `${ getters.getHttpServer}/auth`, 
                method: 'POST', 
                token: false,
                data: payload
            })

            if(!request.error) {

                state.auth = true
                state.authMsg = { success: true }

                localStorage.setItem('TMS_UUID', request.user.uuid)

                dispatch('addToken', request.token)

                router.push('/')

            } else {

                state.auth = false
                state.authMsg = { success: false }
                state.authMsg.text = getters.getTranslate[request.error] || request.error
            }

            state.authProcess = false
        }
    },

    mutations: {

        setAuthMsg(state, data) {

            state.authMsg = data
        },

        setRequestCode(state, data) {

            state.singleAuthMsg = data
        }
    }
}
