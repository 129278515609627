export default {

    state: {

        testResponse: null,
        testRequestProcess: false,
        testRequestMsg: {}
    },

    actions: {

        async sendTestRequest ({rootState, state, dispatch}, data) {

            const token = rootState.token.token

            if(token) {

                state.testRequestProcess = true

                let request

                if(data.method == 'POST' || data.method == 'PUT') {

                    request = await dispatch('fetchRequest', {
                        address: data.address, 
                        method: data.method, 
                        token,
                        data: data.params
                    })

                } else {

                    let params = ''

                    if(data.params) {

                        for( let param in data.params ) {

                            if(!params.length) {

                                params = '?' + param + '=' + data.params[param]
                            } else {

                                params += '&' + param + '=' + data.params[param]
                            }
                        }
                    }

                    request = await dispatch('fetchRequest', {
                        address: data.address + params, 
                        method: data.method, 
                        token
                    })
                }

                if(!('error' in request)) {

                    state.testResponse = request
                    state.testRequestMsg = { success: true, status: request.status }

                } else {

                    state.testResponse = request
                    state.testRequestMsg = { success: false, status: request.status, text: { statusText: `Ошибка запроса: ${request.error.statusText}`, response: request.error.response } }
                }

                state.testRequestProcess = false

            } else {

                state.testRequestMsg = { success: false, status: '', text: 'Сначала авторизуйся' }
            }
        }
    },

    mutations: {

        setTestRequestMsg(state, data) {

            state.testRequestMsg = data
        }
    }
}
