<template>
    <div class="h-100">
        <div class="tms-zones-list">
            <div class="tms-zones-list__header">
                <div class="form-floating">
                    <input
                        v-model="searchInput"
                        type="text"
                        class="form-control pe-5"
                        id="tms-zones-list__search"
                        placeholder="Поиск"
                        @input="startSearching"
                        @blur="searchInputBlur"
                    />
                    <label for="tms-zones-list__search">
                        {{
                            searchLengthError ? 
                                getTranslate.ZONE_SEARCH_MESSAGE : 
                                getTranslate.ZONE_SEARCH_PLACEHOLDER
                        }}
                    </label>
                    <button
                        v-if="searchInput"
                        class="btn btn-clear-search"
                        type="button"
                        @click="searchInput = ''"
                    >
                        <span class="material-symbols-outlined">close</span>
                    </button>
                </div>
            </div>
            <div v-if="!loader" class="tms-zones-list__body">
                <ul v-if="zonesList.length" class="tms-zones-list__body-list">
                    <li
                        v-for="zone in zonesList"
                        :key="zone.uuid"
                        class="tms-zones-list__body-list-item d-flex justify-content-between align-items-center"
                    >
                        <button
                            type="button"
                            class="tms-zones-list__body-list-btn_moveto btn-zone-list"
                            @click="flyToZone(zone)"
                        >
                            <span class="material-symbols-outlined">location_on</span>
                        </button>
                        <router-link
                            :to="`/zones/${zone.uuid}`"
                            class="tms-zones-list__body-list-link"
                        >
                            {{ zone.name }}
                        </router-link>
                        <button
                            type="button"
                            class="tms-zones-list__body-list-btn_delete btn-zone-list"
                            @click="showModal(zone.uuid)"
                        >
                            <span class="material-symbols-outlined">delete</span>
                        </button>
                    </li>
                </ul>
                <div v-else class="tms-zones-list__body-text">Зон нет</div>
            </div>
            <div v-else class="tms-zones-list__body">
                <Loader />
            </div>
            <div v-if="searchInput.length < 3" class="tms-zones-list__footer">
                <div
                    class="footer-wrapper d-flex align-items-center h-100 w-100"
                >
                    <nav
                        class="tms-pagination w-100 d-flex justify-content-between"
                    >
                        <div class="tms-pagination__prev p-0">
                            <button
                                class="tms-pagination__prev-btn"
                                :class="{
                                    'tms-pagination__prev-btn--disabled':
                                        pageNumber === 1,
                                }"
                                :disabled="pageNumber === 1"
                                @click="prevPage(zonesData.c.start)"
                            >
                                <span class="material-symbols-outlined">chevron_left</span>
                            </button>
                        </div>
                        <div class="tms-pagination__current">
                            <div class="tms-pagination__current-text">
                                {{ getTranslate.PAGINATION_PAGE }}
                                {{ pageNumber }}
                            </div>
                        </div>
                        <div class="tms-pagination__next p-0">
                            <button
                                class="tms-pagination__next-btn"
                                :class="{
                                    'tms-pagination__prev-btn--disabled':
                                        !zonesData.c.isAfter
                                }"
                                :disabled="!zonesData.c.isAfter"
                                @click.prevent="nextPage(zonesData.c.stop)"
                            >
                                <span class="material-symbols-outlined">chevron_right</span>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>

            <transition name="bounce">
                <Modal
                    v-if="isShowModal"
                    max-width="400"
                    :header="getTranslate.ZONES_DELETE"
                    size="md"
                    @closeModal="isShowModal = false"
                >
                    <template v-slot:body>
                        {{ getTranslate.ZONES_DELETE_TEXT }}

                        <div class="tms-zones-form__btns-wrapper">
                            <div class="btns text-right">
                                <button
                                    class="btn btn--secondary"
                                    @click="cancelDeleteZone"
                                >
                                    {{ getTranslate.BTN_CANCEL }}
                                </button>
                                <button
                                    class="btn btn--primary"
                                    @click="deleteZoneByUuid"
                                >
                                    {{ getTranslate.BTN_DELETE }}
                                </button>
                            </div>
                        </div>
                    </template>
                </Modal>
            </transition>

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import Modal from "@/components/Modal"
import Loader from "@/components/Loader"

export default {
    data() {

        return {

            loader: false,
            isShowModal: false,
            currentUuid: null,
            searchInput: "",
            zonesBySearch: [],
            pageNumber: 1,
            searchTimeOut: null,
            searchLengthError: false
        }
    },

    computed: {

        ...mapGetters([
            
            "getTranslate"
        ]),

        ...mapState({

            zonesData: state => state.zones.zones
        }),

        zonesList() {

            if (this.searchInput?.length > 2) {
                // search...
                return this.zonesBySearch
            }

            return this.zonesData?.array || []
        },
    },

    components: {
        Modal,
        Loader,
    },

    methods: {

        ...mapActions([
            
            "readZones", 
            "deleteZone"
        ]),

        // Показать модальное окно
        showModal(uuid) {

            this.isShowModal = true
            this.currentUuid = uuid
        },

        // Отменить удаление
        cancelDeleteZone() {

            this.currentUuid = null
            this.isShowModal = false
        },

        flyToZone(value) {

            let latLng = null

            if (value.zone["line"]) {
                latLng = [
                    value.zone.line.array[0].lat,
                    value.zone.line.array[0].lon
                ]
            }

            if (value.zone["polygon"]) {
                latLng = [
                    value.zone.polygon.array[0].lat,
                    value.zone.polygon.array[0].lon
                ]
            }

            if (value.zone["circle"]) {
                latLng = [
                    value.zone.circle.point.lat,
                    value.zone.circle.point.lon
                ]
            }

            if (value.zone["sector"]) {
                latLng = [
                    value.zone.sector.circle.point.lat,
                    value.zone.sector.circle.point.lon
                ]
            }

            this.$emit("moveTo", latLng)
        },

        // Удаление зоны по uuid
        async deleteZoneByUuid() {

            if (this.currentUuid) {

                //Удалить зону
                const request = await this.deleteZone(this.currentUuid)

                this.cancelDeleteZone()

                if (request.uuid) {

                    this.$emit("removeFromList")

                    // Обновить список поиска
                    if (this.searchInput?.length > 2) {
                        await this.searchZone()
                    }

                    // Обновить список зон
                    this.updateList()
                }
            }
        },

        // Обновление списка зон
        async updateList() {

            this.loader = true

            // Загрузка всех зон
            await this.readZones({

                payload: { "c.limit": 15 },
                isUseStore: true
            })

            this.pageNumber = 1

            this.loader = false
        },

        // Пагинация назад
        async prevPage(cursor) {

            const params = {
                "c.direction": "before",
                "c.cursor": encodeURIComponent(cursor),
                "c.limit": 15
            }

            this.loader = true

            await this.readZones({ 
                
                payload: params, 
                isUseStore: true 
            })

            if (this.zonesData) this.pageNumber--

            this.loader = false
        },

        // Пагинация вперед
        async nextPage(cursor) {

            const params = {
                "c.direction": "after",
                "c.cursor": encodeURIComponent(cursor),
                "c.limit": 15
            }

            this.loader = true

            await this.readZones({ 
                
                payload: params, 
                isUseStore: true 
            })

            if (this.zonesData) this.pageNumber++

            this.loader = false
        },

        // Потеря фокуса поиска
        searchInputBlur() {

            //Сбросить ошибку длины поиска
            if (this.searchInput.length === 0) this.searchLengthError = false
        },

        // Начало поиска
        startSearching() {

            this.loader = true

            // Показать ошибку длины поиска
            this.searchInput.length > 2 ? 
                this.searchLengthError = false : 
                this.searchLengthError = true

            if (this.searchTimeOut) clearInterval(this.searchTimeOut)

            this.searchTimeOut = setTimeout(this.searchZone, 1000)
        },

        // Поиск зон при вводе
        async searchZone() {

            if (this.searchInput.length > 2) {

                const data = await this.readZones({
                    payload: {
                        "c.q": this.searchInput
                    },
                    isUseStore: false
                })

                !data.error ? 
                    this.zonesBySearch = data.array : 
                    this.zonesBySearch = []

            } else {

                this.zonesBySearch = []
            }

            this.loader = false
        }
    },

    emits: {
        moveTo: null,
        removeFromList: null
    }
}
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/forms";
</style>

<style lang="scss">
@import "@/assets/styles/scss/components/zones-list2.scss";
</style>
