export default {

    state: {

        points: [],
        pointProcess: false,
        pointMsg: null
    }, 

    actions: {

        async addPoint({dispatch, commit}, payload) {

            // В блоке ниже мы определяем по какой сущности пришла информация в сообщении WebSocket (пользователь, объект, трек и т.д.)
            // Если по полученному UUID сущности нет данных - запрашиваем информацию новую с сервера
            // Данные в приложении могут обновляться оперативно, через WS, без лишних HTTP-запросов
            if(payload.group) {

                // Обновление группы: создание новой, редактирование свойств
                commit('insertGroup', payload.group)

            } else if(payload.groupTrack) {

                // Изменение групп в треке
                const trackUUID = payload.groupTrack.trackUuid
                dispatch('readTrackGroups', { uuid: trackUUID })

            } else if(payload.object) {

                // Изменение объекта: создание нового, редактирование свойств
                commit('insertObject', payload.object)

            } else if(payload.point) {

                // Новая точка: события с объектом на треке
                // TODO: Пока непонятно...

            } else if(payload.track) {

                // Изменение трека: создание нового, редактирование свойств
                commit('insertTrack', payload.track)

            } else if(payload.trackZone) {

                // Изменение зон трека
                const trackUUID = payload.trackZone.trackUuid
                dispatch('readTrackZones', { trackUuid: trackUUID })

            } else if(payload.user) {

                // Изменение пользователя: создание нового, редактирование свойств
                commit('insertUser', payload.user)

            } else if(payload.zone) {

                // Изменение зоны: создание новой, редактирование свойств
                commit('insertZone', payload.zone)
            }
        }
    }
}