<template>
    <div class="tms-zones-wrapper">
        <div id="TMSZonesMap" class="tms-zones-map">
            <div class="tms-zones-map__left-top">
                <button
                    class="tms-zones-map__btn tms-zones-map__btn_white"
                    type="button"
                    @click="visibleSidebar = true"
                >
                    <span class="material-symbols-outlined">arrow_forward</span>
                </button>
            </div>
            <div class="tms-zones-map__right-top d-flex flex-column">
                <button
                    class="tms-zones-map__btn"
                    type="button"
                    @click="isShowSpecialLayers = !isShowSpecialLayers"
                >
                    <svg
                        class="tms-zones-map__icon"
                        :class="{
                            'tms-zones-map__icon--active': isShowSpecialLayers,
                        }"
                    >
                        <use xlink:href="#icon_map_layers"></use>
                    </svg>
                </button>
                <button 
                    v-if="mode === 'edit'"
                    class="tms-zones-map__btn tms-zones-map__btn_white" 
                    type="button"
                    @click="isShowModal = true"
                >
                    <span class="material-symbols-outlined">timeline</span>
                </button>
            </div>
        </div>

        <TMSZonesMapSidebar
            :isOpen="visibleSidebar"
            :title="sidebarTitle"
            @hidden="visibleSidebar = false"
        >
            <template v-slot:body>
                <div v-if="mode === 'read' && zones.array" class="h-100">
                    <TMSZonesList
                        @moveTo="flyToZone"
                        @removeFromList="loadVisibleZones"
                    />
                </div>
                <div v-if="mode === 'create'">
                    <TMSZonesForm
                        ref="zonesForm"
                        :isFinishBtnDisabled="isFinishBtnDisabled"
                        :isCancelBtnDisabled="isCancelBtnDisabled"
                        :isDrawn="isDrawnItems"
                        :isHideDrawControls="isDrawing"
                        :payloadFigure="payloadFigure"
                        :figureRadius="currentRadius"
                        @changeFigureType="changeFigureType"
                        @changeFigureOptions="changeFigureOptions"
                        @clearFigureLayer="clearLayer"
                        @finishDrawing="finishDrawing"
                        @cancelDrawing="cancelDrawing"
                    />
                </div>
                <div v-if="mode === 'edit' && zone">
                    <TMSZonesForm
                        ref="zonesForm"
                        :zone="zone"
                        :isFinishBtnDisabled="isFinishBtnDisabled"
                        :isCancelBtnDisabled="isCancelBtnDisabled"
                        :isDrawn="isDrawnItems"
                        :isHideDrawControls="isDrawing"
                        :payloadFigure="payloadFigure"
                        :figureRadius="currentRadius"
                        @changeFigureType="changeFigureType"
                        @changeFigureOptions="changeFigureOptions"
                        @clearFigureLayer="clearLayer"
                        @finishDrawing="finishDrawing"
                        @cancelDrawing="cancelDrawing"
                    />
                </div>
            </template>
            <template v-slot:footer>
                <div
                    v-if="mode === 'read'"
                    class="d-flex justify-content-center align-items-center h-100"
                >
                    <router-link
                        class="btn btn--primary btn--block"
                        to="/zones/create"
                    >
                        {{ getTranslate.BTN_ADD }}
                    </router-link>
                </div>
                <div
                    v-if="mode === 'create'"
                    class="d-flex justify-content-center align-items-center h-100"
                >
                    <button
                        type="button"
                        class="btn btn--primary me-2"
                        @click="onSubmit('create')"
                    >
                        {{ getTranslate.BTN_CREATE }}
                    </button>

                    <button
                        type="button"
                        class="btn btn--secondary"
                        @click="$router.push('/zones')"
                    >
                        {{ getTranslate.BTN_CANCEL }}
                    </button>
                </div>
                <div
                    v-if="mode === 'edit'"
                    class="d-flex justify-content-center align-items-center h-100"
                >
                    <button
                        type="button"
                        class="btn btn--primary me-2"
                        @click="onSubmit('update')"
                    >
                        {{ getTranslate.BTN_SAVE }}
                    </button>

                    <button
                        type="button"
                        class="btn btn--secondary"
                        @click="$router.push('/zones')"
                    >
                        {{ getTranslate.BTN_CANCEL }}
                    </button>
                </div>
            </template>
        </TMSZonesMapSidebar>

        <transition name="bounce">
            <Modal
                v-if="isShowModal"
                max-width="400"
                :header="getTranslate.ZONES_TRACKS"
                size="lg"
                @closeModal="isShowModal = false"
            >
                <template v-slot:body>
                    <TMSZonesTracksList />
                </template>
            </Modal>
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import TMSZonesForm from "@/components/TMSZonesForm"
import TMSZonesMapSidebar from "@/components/TMSZonesMapSidebar"
import TMSZonesList from "@/components/TMSZonesList"
import TMSZonesTracksList from "@/components/TMSZonesTracksList"
import Modal from "@/components/Modal"
import L from "leaflet"
import "leaflet-draw/dist/leaflet.draw-src.js"
import "leaflet.sector/leaflet.sector.js"
import "leaflet.draw-sector/Leaflet.draw-sector.js"
import "leaflet-event-forwarder/src/L.EventForwarder.js"
import "@maptiler/leaflet-maptilersdk"
import makePopup from "@/helpers/zones/makePopup.js"

export default {    
    data() {
        return {
            key: 'TqS25W3SZjyvDdCkuP0Y',
            map: null,
            mapCenter: null,
            mapZoomMax: 20,
            mapZoomMin: 5,
            mapZoomCurrent: 16,
            mapLayers: null,
            isShowSpecialLayers: false,

            isCancelBtnDisabled: true,
            isFinishBtnDisabled: true,

            //Процесс рисования
            isDrawing: false,

            //Слой для рисования
            drawnItems: null,
            //Слой для зон видимой областти карты
            visibleZoneItems: null,

            editToolbar: null,
            editHandler: null,

            //Окончательный вариант фигуры для отправки на сервер
            payloadFigure: null,

            figureOptions: {
                stroke: true, // наличие border: true (Для Polyline - линия)
                color: "#3388ff", //цвет border
                weight: 4, //толщина border (Для Polyline - толщина линии) --- Default: 3
                fillColor: "#3388ff", // цвет заливки
                fillOpacity: 0.9 //прозрачность заливки --- Default: 0.2
            },

            //Фигура Leaflet.Draw
            currentFigure: null,
            //Фигура Leaflet
            editingFigure: null,

            //Наличие зоны для редактирования
            isCurrentZone: false,

            namiMap: L.tileLayer(
                "https://dev.shatl-t.ru/Tiles/{z}/{x}/{y}.png",
                {
                    minZoom: this.mapZoomMin,
                    maxZoom: this.mapZoomMax
                }
            ),

            //Сайдбар карты
            visibleSidebar: true,

            //Тип устройства
            isMobile: false,

            //Слой нарисованной на карте фигуры
            createdLayer: null,
            //Радиус круга/сектора для формы
            currentRadius: 0,

            //Погружение в слоях Leaflet
            eventForwarder: null,

            //Пересекающиеся зоны
            intersectingZones: [],

            //Popup
            popup: null,
            //состояние попапа: открыть/закрыть
            isPopupOpen: false,
            //Ссылки в Popup 
            popupLinks: null,

            //Модальное окно
            isShowModal: false
        }
    },

    props: {
        mode: {
            type: String,
            required: true
        },
        zone: {
            type: Object,
            required: false
        }
    },

    computed: {
        ...mapState({

            zones: state => state.zones.zones,
        }),

        ...mapGetters([

            "getTranslate"
        ]),

        isDrawnItems() {
            return this.drawnItems?.getLayers().length ? true : false
        },

        sidebarTitle() {

            return this.mode === "read" ? 
                this.getTranslate.ZONE_LIST : 
                this.mode === "create" ? 
                this.getTranslate.ZONE_CREATE : 
                this.mode === "edit" ? 
                this.getTranslate.ZONE_EDIT : ""
        }

    },

    components: { 

        TMSZonesForm, 
        TMSZonesMapSidebar, 
        TMSZonesList,
        TMSZonesTracksList,
        Modal
    },

    methods: {

        ...mapActions([

            "readZones"
        ]),

        /**
         * * Определение экрана мобильных устройств
         */
        identifyDevice() {

            document.getElementsByTagName("body")[0].clientWidth < 576 ? 
            (this.isMobile = true) : 
            (this.isMobile = false)
        },

        /**
         * * Инициализация карты
         */
        initLMap() {

            // установка центра карты
            if (!localStorage.getItem("TMS_MAP_CENTER")) {
                
                this.mapCenter = [55.75, 37.5]

            } else {
                
                let { lat, lng } = JSON.parse(localStorage.getItem("TMS_MAP_CENTER"))

                this.mapCenter = [lat, lng]
            }

            // установка активных слоев
            if (localStorage.getItem("TMS_MAP_LAYERS")) {

                this.isShowSpecialLayers = JSON.parse(localStorage.getItem("TMS_MAP_LAYERS"))
            }

            // установка зума
            if (localStorage.getItem("TMS_MAP_ZOOM")) {

                this.mapZoomCurrent = JSON.parse(localStorage.getItem("TMS_MAP_ZOOM"))
            }

            this.map = L.map("TMSZonesMap", {
                center: this.mapCenter,
                zoomControl: false,
                zoom: this.mapZoomCurrent,
                minZoom: this.mapZoomMin,
                maxZoom: this.mapZoomMax
            })

            // Основная карта
            const mtLayer = L.maptilerLayer({
                apiKey: this.key,
                language: L.MaptilerLanguage.RUSSIAN,
                style: L.MaptilerStyle.OPENSTREETMAP //STREETS, BASIC, OPENSTREETMAP 
            }).addTo(this.map)

            const maptilerMap = mtLayer.getMaptilerSDKMap()

            maptilerMap.on("load", () => {
                //Remove copyright            
                const mapContainer = document.getElementById("TMSZonesMap")
                const copyrightLink = Array.from(mapContainer.getElementsByTagName("a")).find(el => el.href == "https://www.maptiler.com/")
                copyrightLink.remove()
            })

            // карта НАМИ и НИЦИАМТ
            this.mapLayers = L.tileLayer(
                "https://dev.shatl-t.ru/Tiles/{z}/{x}/{y}.png",
                {
                    minZoom: this.mapZoomMin,
                    maxZoom: this.mapZoomMax
                }
            )

            // добавляем управление зумом
            L.control.zoom({ position: "bottomright" }).addTo(this.map)

            // сохраняем в браузер локацию, на которой находится карта клиента
            this.map.on("moveend", () => {
                localStorage.setItem(
                    "TMS_MAP_CENTER",
                    JSON.stringify(this.map.getCenter())
                )
            })

            // сохраняем значение зума при изменении масштаба карты
            this.map.on("zoomend", () => {
                localStorage.setItem("TMS_MAP_ZOOM", this.map._zoom)
            })

            //Слой для видимых зон
            this.visibleZoneItems = L.featureGroup()
            this.map.addLayer(this.visibleZoneItems)
        },

        /**
         * * Инициализация Leaflet Draw и добавление слоя с зонами
         */
        initLDraw() {

            //Слой для рисования
            this.drawnItems = L.featureGroup()
            this.map.addLayer(this.drawnItems)

            //Инициализация редактирования фигуры
            this.editToolbar = new L.EditToolbar({
                featureGroup: this.drawnItems
            })
            this.editHandler = this.editToolbar.getModeHandlers()[0].handler
            this.editHandler._map = this.map

            //Успешное завершение редактирования, cохранение нарисованной фигуры на карте
            this.map.on(L.Draw.Event.CREATED, event => {

                //Применить опции созданной зоны
                this.editHandler.options.selectedPathOptions = {
                    ...this.figureOptions
                }

                this.createdLayer = event.layer

                this.drawnItems.addLayer(this.createdLayer)
                //Включить редактирование фигуры
                this.editHandler.enable()

                if (event.layerType == "sector") {
                    //FIX: удалить leaflet-draw-tooltip после добавления Sector на карту
                    document.getElementsByClassName("leaflet-draw-tooltip")[0]?.remove()

                    //Скрыть кнопку редактирования внутреннего радиуса сектора
                    event.layer.editing._innerRadiusMarker._icon.outerHTML = ""
                }

            })

            //Начало рисования
            this.map.on("draw:drawstart", event => {

                //Скрыть сайдбар когда начато рисование на мобильных устройствах
                if (
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
                        navigator.userAgent
                    )
                ) {
                    //Используется мобильное устройство (телефон/планшет)
                    this.visibleSidebar = false
                }

                if (event.layerType === "circle") {

                    this.map.on("click", this.checkDrawCircle)

                } else {

                    this.map.off("click", this.checkDrawCircle)

                }

            })

            //Прекращение рисования
            this.map.on("draw:drawstop", event => {

                //сброс стиля курсора
                L.DomUtil.removeClass(this.map._container, "crosshair-cursor-enabled")

                this.currentFigure = null

                document.removeEventListener("keydown", this.checkCancelDrawKeydown)

                if (event.layerType == "sector") {
                    //FIX: удалить leaflet-draw-tooltip после добавления Sector на карту
                    document.getElementsByClassName("leaflet-draw-tooltip")[0]?.remove()
                }

                this.isFinishBtnDisabled = true
                this.isDrawing = false

            })

            //Добавление новых точек в Polyline и Polygon
            this.map.on("draw:drawvertex", event => {

                if (
                    event.layers.getLayers().length > 1 &&
                    this.currentFigure.type == "polyline"
                ) {

                    this.isFinishBtnDisabled = false

                }
                if (
                    event.layers.getLayers().length > 2 &&
                    this.currentFigure.type == "polygon"
                ) {

                    this.isFinishBtnDisabled = false

                }

            })

            //Fix: отмена рисования при перетаскивании карты (Проверить на мобильных устройствах!!!)
            L.Draw.Polyline.prototype._onTouch = L.Util.falseFn
        },

        /**
         * * Инициализация погружения в слои Leaflet
         */
        initEventForwarder() {

            this.eventForwarder = new L.eventForwarder({
                map: this.map,
                events: {
                    click: true
                }
            })

            this.eventForwarder.enable()
        },

        /**
         * * Инициализация попапа
         */
        initPopup() {

            this.popup = L.popup()

            // очищаем список пересекающихся зон при закрытии попапа
            this.popup.on("remove", () => {
                this.intersectingZones = []
                this.isPopupOpen = false
                
                //Очистка ссылок из Popup
                this.clearPopupLinks()
            })
        },

        /**
         * * Нанесение всех зон на карту
         */
        renderAllZones(zones) {

            if (this.visibleZoneItems?.getLayers().length) {
                this.visibleZoneItems.clearLayers()
            }

            let renderZones = null

            if (this.zone && zones && this.mode == "edit") {

                //Исключить на карте из области видимости зону для редактирования
                renderZones = zones.array.filter(zone => zone.uuid !== this.zone.uuid)

            } else {

                renderZones = zones.array
            }

            if (renderZones?.length) {

                renderZones.forEach(zone => {
                    const options = {
                        stroke: true, // наличие border: true (Для Polyline - линия)
                        color: zone.options.stroke, //цвет border 
                        weight: zone.options.strokeWidth, //толщина border (Для Polyline - толщина линии) --- Default: 3
                        fillColor: zone.options.fill, // цвет заливки
                        fillOpacity: 0.9 //прозрачность заливки --- Default: 0.2
                    }

                    let layer = null

                    if (zone.zone["line"]) {

                        layer = L.polyline(
                            zone.zone.line.array.map(latLon => [
                                latLon.lat,
                                latLon.lon
                            ]),
                            options
                        )

                        layer.type = "line"

                    }

                    if (zone.zone["polygon"]) {

                        layer = L.polygon(
                            zone.zone.polygon.array.map(latLon => [
                                latLon.lat,
                                latLon.lon
                            ]),
                            options
                        )

                        layer.type = "polygon"

                    }

                    if (zone.zone["circle"]) {

                        layer = L.circle(
                            [
                                zone.zone.circle.point.lat,
                                zone.zone.circle.point.lon
                            ],
                            zone.zone.circle.radius,
                            options
                        )

                        layer.type = "circle"

                    }

                    if (zone.zone["sector"]) {

                        layer = L.sector({
                            center: [
                                zone.zone.sector.circle.point.lat,
                                zone.zone.sector.circle.point.lon
                            ],
                            innerRadius: 0,
                            outerRadius: zone.zone.sector.circle.radius,
                            startBearing: zone.zone.sector.startBearing,
                            endBearing: zone.zone.sector.endBearing,
                            ...options
                        })

                        layer.type = "sector"

                    }

                    layer.uuid = zone.uuid
                    layer.name = zone.name

                    layer
                        .addTo(this.visibleZoneItems)
                        .on("click", this.clickZoneHandler)
                })
            }
        },

        /**
         * * Добавление пересекающихся зон в Popup
         */
        clickZoneHandler(e) {
            
            this.intersectingZones.push(e.target)

            const popupContent = makePopup(e.latlng, this.intersectingZones)

            this.popup.setLatLng(e.latlng).setContent(popupContent)
            this.popup.uuid = e.target.uuid

            this.isPopupOpen = true
        },

        /**
         * * Нанесение выбранной для редактирования зоны на карту
         */
        renderCurrentZone() {

            if (this.zone.zone["line"]) {

                this.editingFigure = L.polyline(
                    this.zone.zone.line.array.map(latLon => [
                        latLon.lat,
                        latLon.lon
                    ]),
                    this.figureOptions
                )

            }

            if (this.zone.zone["polygon"]) {

                this.editingFigure = L.polygon(
                    this.zone.zone.polygon.array.map(latLon => [
                        latLon.lat,
                        latLon.lon
                    ]),
                    this.figureOptions
                )

            }

            if (this.zone.zone["circle"]) {

                this.editingFigure = L.circle(
                    [
                        this.zone.zone.circle.point.lat,
                        this.zone.zone.circle.point.lon
                    ],
                    this.zone.zone.circle.radius,
                    this.figureOptions
                )

            }

            if (this.zone.zone["sector"]) {

                this.editingFigure = L.sector({
                    center: [
                        this.zone.zone.sector.circle.point.lat,
                        this.zone.zone.sector.circle.point.lon
                    ],
                    innerRadius: 0,
                    outerRadius: this.zone.zone.sector.circle.radius,
                    startBearing: this.zone.zone.sector.startBearing,
                    endBearing: this.zone.zone.sector.endBearing,
                    ...this.figureOptions
                })

            }

            //Добавить в слой карты
            this.drawnItems.addLayer(this.editingFigure)

            //Применить опции редактируемой зоны
            this.editHandler.options.selectedPathOptions = {
                ...this.figureOptions
            }

            //Включить редактирование фигуры
            this.editHandler.enable()

            //Переместиться на карте к выбранной фигуре
            if (this.zone.zone["circle"]) {

                this.map.setView(
                    [
                        this.zone.zone.circle.point.lat,
                        this.zone.zone.circle.point.lon
                    ],
                    16
                )

            } else {

                this.map.setView(
                    [
                        this.editingFigure.getCenter().lat,
                        this.editingFigure.getCenter().lng
                    ],
                    16
                )

            }

            if (this.zone.zone["sector"]) {
                //FIX: удалить leaflet-edit-resize после добавления Sector на карту
                document.getElementsByClassName("leaflet-edit-resize")[0]?.remove()
            }
        },

        //Перемещение к зоне из списка
        flyToZone(latLng) {

            this.map.flyTo(latLng, 16)

            if (this.isMobile) {
                //Скрыть сайдбар на собильных устройствах
                this.visibleSidebar = false
            }
        },

        checkCancelDrawKeydown(e) {

            //Если рисование было остановлено по нажатию на Esc
            if (e.key == "Escape") {

                this.$refs.zonesForm.resetFigureType()

                this.map.off("click", this.checkDrawCircle)

            }

            if (e.target.nodeName == "BODY") {

                this.cancelDrawing()

            }
        },

        //Проверка радиуса нарисованной окружности (R = 0)
        checkDrawCircle() {

            if (this.currentRadius === 0) {
                //Сбросить значение выбранной фигуры
                this.$refs.zonesForm.resetFigureType()
            }

            this.map.off("click", this.checkDrawCircle)
        },

        //завершить рисование
        finishDrawing() {

            this.currentFigure.completeShape()
        },

        //Отменить рисование
        cancelDrawing() {

            if (this.currentFigure.type == "circle")
                this.map.off("click", this.checkDrawCircle)

            this.currentFigure.disable()
        },

        //Удалить все фигуры слоя с карты
        clearLayer() {

            if (this.drawnItems.getLayers().length) this.drawnItems.clearLayers()

            if (this.currentRadius) this.currentRadius = 0

            this.createdLayer = null
        },

        changeFigureType(value) {

            if (this.isCurrentZone) {

                //Сбросить флаг первостепенного наличия зоны для редактирования
                this.isCurrentZone = false

                return

            }

            if (value) {

                //Очистить все фигуры
                this.clearLayer()

                if (this.currentFigure) {

                    //очистить фигуру
                    this.currentFigure.disable()
                    this.currentFigure = null

                } else {

                    //сменить стиль курсора
                    L.DomUtil.addClass(this.map._container, "crosshair-cursor-enabled")

                }

                switch (value) {
                    case "polyline":
                        //Если толщина линии перед рисованием указана 0
                        if (this.figureOptions.weight === 0)
                            this.figureOptions.weight = 3

                        this.currentFigure = new L.Draw.Polyline(this.map, {
                            shapeOptions: { ...this.figureOptions }
                        })
                        break
                    case "polygon":
                        this.currentFigure = new L.Draw.Polygon(this.map, {
                            shapeOptions: { ...this.figureOptions }
                        })
                        break
                    case "circle":
                        this.currentFigure = new L.Draw.Circle(this.map, {
                            shapeOptions: { ...this.figureOptions }
                        })
                        break
                    case "sector":
                        this.currentFigure = new L.Draw.Sector(this.map, {
                            shapeOptions: {
                                ...this.figureOptions,
                                innerRadius: 0
                            }
                        })
                        break
                }

                this.currentFigure.enable()
                this.isDrawing = true

                //Добавляем проверку отмены рисования через нажатие Esc
                document.addEventListener("keydown", this.checkCancelDrawKeydown)

            }
        },

        //Обновление параметров figureOptions из формы
        changeFigureOptions(values) {

            this.figureOptions.weight = +values.weight
            this.figureOptions.color = values.color
            this.figureOptions.fillColor = values.fillColor

            //Изменить опции уже нарисованной фигуры
            if (this.drawnItems?.getLayers().length) {
                this.drawnItems.getLayers()[0].setStyle({
                    weight: +values.weight,
                    color: values.color,
                    fillColor: values.fillColor
                })
            }
        },

        //Отправка формы
        onSubmit(submitType) {

            this.$refs.zonesForm.submitForm(
                this.drawnItems?.getLayers()[0],
                submitType
            )
        },

        //Получение зон из видимой области на карте
        async loadVisibleZones() {

            //Обновляем координаты текущей видимой области на карте
            const boundsNorthWest = this.map.getBounds().getNorthWest()
            const boundsSouthEast = this.map.getBounds().getSouthEast()

            const payload = {
                "box.min.lat": boundsNorthWest.lat,
                "box.min.lon": boundsNorthWest.lng,
                "box.max.lat": boundsSouthEast.lat,
                "box.max.lon": boundsSouthEast.lng,
                "c.limit": 1000
            }

            const zones = await this.readZones({
                payload,
                isUseStore: false
            })

            //Видимые зоны попапа
            const isZoneVisible = zones.array?.find(zone => zone.uuid == this.popup.uuid)            
           
            //Закрыть Popup если зона не отображается
            if (this.isPopupOpen && !isZoneVisible) {
                delete this.popup.uuid
                this.map.closePopup()
            }

            //Закрыть Popup пересекающихся зон если хотя бы одна зона перестала отображаться
            if (this.isPopupOpen && this.intersectingZones.length > 1) {
                const zonesUuid = zones.array.map(zone => zone.uuid)
                const intersectingZonesUuid = this.intersectingZones.map(zone => zone.uuid)

                if (!intersectingZonesUuid.every(zone => zonesUuid.includes(zone))) {
                    delete this.popup.uuid
                    this.map.closePopup()
                }
            }


            if (!zones.error) {

                //Обновить зоны на карте
                this.renderAllZones(zones)

            } else if (this.visibleZoneItems?.getLayers().length) {

                //Очистить карту после удаления зоны из списка
                this.visibleZoneItems.clearLayers()
            }
        },

        clearPopupLinks () {
            if (this.popupLinks) {

                for (let link of this.popupLinks) {
                    link.removeEventListener('click', this.popupLinkHandler )                        
                }
            }          

            this.popupLinks = null
        },

        popupLinkHandler(e) {
            e.preventDefault()

            //Очистка ссылок из Popup
            this.clearPopupLinks()

            this.$router.push(e.target.pathname)
        }

    },

    async mounted() {

        window.addEventListener("resize", this.identifyDevice)

        //Определить мобильное устройство
        this.identifyDevice()

        //инициализируем карту
        this.initLMap()

        //инициализируем Leaflet Draw
        this.initLDraw()

        // инициализируем погружение в слои Leaflet
        this.initEventForwarder()

        // инициализируем попап
        this.initPopup()

        //Добавить на карту выбранную для редактирования зону
        if (this.zone) {

            this.isCurrentZone = true

            this.renderCurrentZone()

            if (this.zone.zone.circle)
                this.currentRadius = this.zone.zone.circle.radius

            if (this.zone.zone.sector)
                this.currentRadius = this.zone.zone.sector.circle.radius
        }

        //Получаем зоны для текущей видимой области на карте
        await this.loadVisibleZones()

        //Обновление зан на карте при перемещении
        this.map.on("moveend", () => this.loadVisibleZones())
    },

    beforeUnmount() {

        //на случай, если компонент будет уничтожен
        window.removeEventListener("resize", this.identifyDevice)
    },
    
    watch: {
        
        // показать/скрыть спец. карты
        isShowSpecialLayers() {

            localStorage.setItem("TMS_MAP_LAYERS", this.isShowSpecialLayers)

            if (this.isShowSpecialLayers) {

                this.mapLayers.addTo(this.map)

            } else {

                this.map.removeLayer(this.mapLayers)
            }

        },

        currentFigure(newVal) {
 
            this.isCancelBtnDisabled = newVal ? false : true
        },

        "createdLayer._outerRadius": {
 
            handler(value) {
                if (value) {
                    this.currentRadius = value
                }
            },
 
            deep: true
        },

        "createdLayer._mRadius": {

            handler(value) {
                if (value) {
                    this.currentRadius = value
                }
            },
            deep: true

        },

        "editingFigure._outerRadius": {

            handler(value) {
                if (value) {
                    this.currentRadius = value
                }
            },
            deep: true

        },

        "editingFigure._mRadius": {

            handler(value) {
                if (value) {
                    this.currentRadius = value
                }
            },
            deep: true

        },

        // открываем попап
        isPopupOpen:  {
            handler (value) {
                
                if (value) {
                    this.popup.openOn(this.map)

                    this.popupLinks = document.getElementsByClassName('tms-zones-map__tip-link')

                    for (let link of this.popupLinks) {
                        link.addEventListener('click', this.popupLinkHandler )                        
                    }
                }
            },

            deep: true  
        }
    }

}
</script>

<style lang="scss">
@import "@/assets/styles/scss/components/zones-map2.scss";
</style>
