<template>

    <div class="tms-users-create">

        <div
            v-if="'success' in userCreateMsg && !userCreateMsg.success"
            class="my-4 alert alert--danger tms-users-create__alert"
        >
            {{ userCreateMsg.text }}
        </div>

        <form class="tms-users-create__form" @submit.prevent="submitForm()">

            <div class="row">
                <div class="col-6">
                    <input
                        id="TMSUserLogin" 
                        v-model="TMSUserLogin"
                        :placeholder="getTranslate.USERS_CREATE_LOGIN"
                        @input="clearError('TMSUserLogin')"
                        class="mb-4 input input--block"
                        :class="{ 'is-invalid': formInvalids.TMSUserLogin }" 
                    />
                </div>
                <div class="col-6">
                    <input
                        id="TMSUserPass" 
                        :type="showPassword ? 'text' : 'password'"
                        v-model="TMSUserPass"
                        :placeholder="getTranslate.USERS_CREATE_PASS" 
                        @input="clearError('TMSUserPass')"
                        autocomplete="off"
                        class="mb-4 input input--block"
                        :class="{ 'is-invalid': formInvalids.TMSUserPass }" 
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <select
                        id="TMSUserType" 
                        v-model="TMSUserType"
                        @input="clearError('TMSUserType')"
                        class="mb-4 input input--block"
                        :class="{ 'is-invalid': formInvalids.TMSUserType }" 
                    >
                        <option value="noT">{{ getTranslate.USER_TYPE_NO_TYPE }}</option>
                        <option value="operT">{{ getTranslate.USER_TYPE_OPERATOR }}</option>
                        <option value="adminT">{{ getTranslate.USER_TYPE_ADMINISTRATOR }}</option>
                    </select>
                </div>
                <div class="col-6">
                    <select
                        id="TMSUserAccess" 
                        v-model="TMSUserAccess"
                        @input="clearError('TMSUserAccess')"
                        class="mb-4 input input--block"
                        :class="{ 'is-invalid': formInvalids.TMSUserAccess }" 
                    >
                        <option value="nA">{{ getTranslate.ACCESS_NO }}</option>
                        <option value="rA">{{ getTranslate.ACCESS_READ }}</option>
                        <option value="rwA">{{ getTranslate.ACCESS_READ_WRITE }}</option>
                        <option value="rwdA">{{ getTranslate.ACCESS_READ_WRITE_DELETE }}</option>
                    </select>
                </div>
            </div>

            <div class="mt-2 btns btns--right">
                <button
                    class="btn"
                    @click="$emit('closeModal')"
                >
                    {{ getTranslate.BTN_CANCEL }}
                </button>
                <button
                    class="btn btn--primary"
                    type="submit"
                >
                    <template v-if="userCreateProcess">
                        <div class="spinner-border" role="status"></div>
                    </template>
                    <template v-else>
                        {{ getTranslate.BTN_CREATE }}
                    </template>
                </button>
            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {

    name: 'TMSUsersCreate',

    data() {

        return {

            valid: true,

            TMSUserLogin: null,
            TMSUserPass: null,
            TMSUserType: 'noT',
            TMSUserAccess: 'nA',

            showPassword: false,

            formInvalids: {}
        }
    },

    computed: {

        ...mapState({

            userCreateProcess: state => state.users.userCreateProcess,
            userCreateMsg: state => state.users.userCreateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'createUser'
        ]),

        clearError(field_id) {

            if(this.formInvalids[field_id]) {

                delete this.formInvalids[field_id]
            }
        },

        submitForm() {

            let data = {}

            this.formInvalids   = {}
            this.valid          = true

            if(this.TMSUserLogin) {

                data.login = this.TMSUserLogin

            } else {

                this.formInvalids['TMSUserLogin'] = true
                this.valid = false
            }

            if(this.TMSUserPass) {

                data.password = this.TMSUserPass

            } else {

                this.formInvalids['TMSUserPass'] = true
                this.valid = false
            }

            if(this.TMSUserType) {

                data.type = this.TMSUserType

            } else {

                this.formInvalids['TMSUserType'] = true
                this.valid = false
            }

            if(this.TMSUserAccess) {

                data.access = this.TMSUserAccess

            } else {

                this.formInvalids['TMSUserAccess'] = true
                this.valid = false
            }

            if(this.valid) {

                this.createUser(data)
            }
        }
    },

    watch: {

        userCreateMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal', false)
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>