
<template>

    <div class="tms-track">

        <div
            v-if="'success' in trackUpdateMsg"
            class="alert"
            :class="{

                'alert--success' : trackUpdateMsg.success,
                'alert--danger' : !trackUpdateMsg.success
            }"
        >
            <div class="alert__text">
                {{ trackUpdateMsg.text }}
            </div>
            <div class="alert__close" @click="setUpdateTrackMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <form @submit.prevent="submitForm()">

            <div class="list">

                <div class="list__item">
                    <div class="list__item-title">UUID: </div>
                    <span class="list__item-value">
                        {{ track.uuid }} 
                        <span class="material-symbols-outlined list__item-content-icon" @click="copyUUID()">
                            content_copy
                        </span>
                    </span>
                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_NAME }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.name }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackName" 
                            :label="getTranslate.TRACKS_NAME"
                            v-model="TMSTrackName"
                            class="input input--block"
                            :class="{ 'in-invalid' : formInvalids.TMSTrackName}"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_DESCR }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.description || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <textarea
                            id="TMSTrackDescr"
                            :label="getTranslate.TRACKS_DESCR"
                            v-model="TMSTrackDescr"
                            class="input input--block"
                        ></textarea>
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_AVAILABLE }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">
                            {{ 
                                track.options ? 
                                    track.options?.available ? 
                                        getTranslate.YES :
                                        getTranslate.NO : 
                                    '&mdash;' 
                            }}
                        </span>
                    </template>

                    <template v-else>
                        <input
                            type="checkbox"
                            id="TMSTrackAvailable" 
                            :label="getTranslate.TRACKS_AVAILABLE"
                            v-model="TMSTrackAvailable"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_MAX_CARS }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.maxCars || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackMAXCars" 
                            type="number"
                            :label="getTranslate.TRACKS_MAX_CARS"
                            v-model="TMSTrackMAXCars"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_MAX_SPEED }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.maxSpeed || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackMAXSpeed" 
                            type="number"
                            :label="getTranslate.TRACKS_MAX_SPEED"
                            v-model="TMSTrackMAXSpeed"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_MAX_SPEED_OFFSET }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.maxSpeedOffset || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackMAXSpeedOffset" 
                            type="number"
                            :label="getTranslate.TRACKS_MAX_SPEED_OFFSET"
                            v-model="TMSTrackMAXSpeedOffset"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_MIN_SPEED }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.minSpeed || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackMINSpeed" 
                            type="number"
                            :label="getTranslate.TRACKS_MIN_SPEED"
                            v-model="TMSTrackMINSpeed"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_MIN_SPEED_OFFSET }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.minSpeedOffset || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackMINSpeedOffset" 
                            type="number"
                            :label="getTranslate.TRACKS_MIN_SPEED_OFFSET"
                            v-model="TMSTrackMINSpeedOffset"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_IS_STOP }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">
                            {{ track.options?.isStop ? getTranslate.YES : getTranslate.NO }}
                        </span>
                    </template>

                    <template v-else>
                        <input
                            type="checkbox"
                            id="TMSTrackIsStop" 
                            :label="getTranslate.TRACKS_IS_STOP"
                            v-model="TMSTrackIsStop"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item" v-if="TMSTrackIsStop">

                    <div class="list__item-title">{{ getTranslate.TRACKS_MAX_STOP_TIME }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.maxStopTime || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackMAXStopTime" 
                            type="number"
                            :label="getTranslate.TRACKS_MAX_STOP_TIME"
                            v-model="TMSTrackMAXStopTime"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_MIN_CARS_INTERVAL }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ track.options ? track.options.minCarsInterval || '&mdash;' : '&mdash;' }}</span>
                    </template>

                    <template v-else>
                        <input
                            id="TMSTrackMINCarsInterval" 
                            type="number"
                            :label="getTranslate.TRACKS_MIN_CARS_INTERVAL"
                            v-model="TMSTrackMINCarsInterval"
                            class="input input--block"
                        />
                    </template>

                </div>

                <div class="list__item">

                    <div class="list__item-title">{{ getTranslate.TRACKS_DIRECTION }}: </div>

                    <template v-if="!showEditForm">
                        <span class="list__item-value">
                            {{ 
                                TMSTrackDirection ? 
                                    this.getTranslate['TRACKS_DIRECTION_' + TMSTrackDirection.toUpperCase()] : 
                                    "&mdash;"
                            }}
                        </span>
                    </template>

                    <template v-else>
                        <select
                            id="TMSTrackDirection" 
                            v-model="TMSTrackDirection"
                            :label="getTranslate.TRACKS_DIRECTION"
                            class="input input--block"
                        >
                            <option value="null">...</option>
                            <option v-for="(dir, index) in trackDirections" :key="index" :value="dir.value">{{ dir.name }}</option>
                        </select>
                    </template>

                </div>

            </div>

            <div class="container-fluid" v-if="!showEditForm">
                <div class="btns btns--right my-4">
                    <a 
                        href="#"
                        class="btn btn--round btn--secondary"
                        @click.prevent="showEditForm = true"
                    >
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </a>
                </div>
            </div>

            <div class="container-fluid" v-if="showEditForm">
                <div class="btns my-4">
                    <button
                        class="btn"
                        type="button"
                        @click="showEditForm = false"
                    >
                        {{ getTranslate.BTN_CANCEL }}
                    </button>
                    <button
                        class="btn btn--primary"
                        type="submit"
                    >
                        {{ getTranslate.BTN_SAVE }}
                    </button>
                </div>
            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSTrack',

    data() {

        return {

            valid: true,

            TMSTrackName:               null,
            TMSTrackAvailable:          null,
            TMSTrackMAXCars:            null,
            TMSTrackMINCarsInterval:    null,
            TMSTrackMAXSpeed:           null,
            TMSTrackMAXSpeedOffset:     null,
            TMSTrackMINSpeed:           null,
            TMSTrackMINSpeedOffset:     null,
            TMSTrackIsStop:             null,
            TMSTrackMAXStopTime:        null,
            TMSTrackDescr:              null,
            TMSTrackDirection:          null,

            showEditForm: false,

            formInvalids: {},
            formErrors: []
        }
    },

    props: {

        trackUuid: {

            required: true,
            type: String
        }
    },

    computed: {

        ...mapState({

            track:              state => state.tracks.track,
            trackUpdateProcess: state => state.tracks.trackUpdateProcess,
            trackUpdateMsg:     state => state.tracks.trackUpdateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        trackDirections() {

            return [

                { name: this.getTranslate.TRACKS_DIRECTION_NO,                  value: 'no'                 },
                { name: this.getTranslate.TRACKS_DIRECTION_CLOCKWISE,           value: 'clockwise'          },
                { name: this.getTranslate.TRACKS_DIRECTION_COUNTER_CLOCKWISE,   value: 'counter_clockwise'  }
            ]
        }
    },

    methods: {

        ...mapActions([

            'readTrack',
            'updateTrack'
        ]),

        ...mapMutations([

            'setUpdateTrackMsg',
            'setPageHeader'
        ]),

        submitForm() {

            let data = {

                uuid: this.trackUuid,
                options: {}
            }

            this.formInvalids = {}
            this.formErrors = []

            if(this.TMSTrackName) {

                data.name = this.TMSTrackName
                this.valid = true

            } else {

                this.formInvalids['TMSTrackName'] = true
                this.valid = false
            }

            if(this.TMSTrackAvailable) {

                data.options.available = this.TMSTrackAvailable
            }

            if(this.TMSTrackMAXCars) {

                data.options.max_cars = this.TMSTrackMAXCars
            }

            if(this.TMSTrackMAXSpeed) {

                data.options.max_speed = this.TMSTrackMAXSpeed
            }

            if(this.TMSTrackMAXSpeedOffset) {

                data.options.max_speed_offset = this.TMSTrackMAXSpeedOffset
            }

            if(this.TMSTrackMINSpeed) {

                data.options.min_speed = this.TMSTrackMINSpeed
            }

            if(this.TMSTrackMINSpeedOffset) {

                data.options.min_speed_offset = this.TMSTrackMINSpeedOffset
            }

            if(this.TMSTrackIsStop) {

                data.options.isStop = this.TMSTrackIsStop
            }

            if(this.TMSTrackMAXStopTime) {

                data.options.max_stop_time = this.TMSTrackMAXStopTime
            }

            if(this.TMSTrackMINCarsInterval) {

                data.options.min_cars_interval = this.TMSTrackMINCarsInterval
            }

            if(this.TMSTrackDescr) {

                data.options.description = this.TMSTrackDescr
            }

            if(this.TMSTrackDirection) {

                data.options.direction = this.TMSTrackDirection
            }

            if(this.valid) {

                this.showEditForm = false
                this.updateTrack(data)
            }
        },

        async copyUUID() {

            await navigator.clipboard.writeText(this.track.uuid)
        }
    },

    mounted() {

        this.readTrack({ uuid: this.trackUuid }).then(track => {
            
            if(!track.error) {
                
                this.setPageHeader(track.name)
            }
        })
    },

    beforeUnmount() {

        this.setUpdateTrackMsg({})
        this.setPageHeader(null)
    },

    watch: {

        track(track) {

            this.TMSTrackName           = track.name
            this.TMSTrackAvailable      = track.options?.available
            this.TMSTrackDescr          = track.options?.description
            this.TMSTrackMAXCars        = track.options?.maxCars
            this.TMSTrackDirection      = track.options?.direction
            this.TMSTrackMAXSpeed       = track.options?.maxSpeed
            this.TMSTrackMAXSpeedOffset = track.options?.maxSpeedOffset
            this.TMSTrackMINSpeed       = track.options?.minSpeed
            this.TMSTrackMINSpeedOffset = track.options?.minSpeedOffset
            this.TMSTrackMINCarsInterval= track.options?.minCarsInterval
            this.TMSTrackMAXStopTime    = track.options?.maxStopTime
        },

        trackUpdateMsg(msg) {

            if(msg && msg.success) {

                this.readTrack({ uuid: this.uuid })
            }
        }
    }
}
</script>