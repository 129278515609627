<template>

    <div class="page page--object">

        <h1 class="page__header">{{ pageHeader || getTranslate.PAGE_OBJECT }}</h1>

        <div class="page__content">
            <TMSObject :uuid="uuid" />
        </div>

    </div>

</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import TMSObject from '@/components/TMSObject'

export default {

    name: 'ViewObject',

    props: {

        uuid: {

            required: true,
            type: String
        }
    },

    components: {

        TMSObject
    },

    methods: {

        ...mapMutations([

            'setPageHeader'
        ])
    },

    computed: {

        ...mapState({

            pageHeader: state => state.pageOptions.pageHeader
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    beforeUnmount() {

        this.setPageHeader(null)
    }
}
</script>