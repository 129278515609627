export default {

    state: {

        events:                     {},
        eventsProcess:              false,
        eventsMsg:                  '',

        eventsToast:                [],
        eventsAlert:                {},

        confirmEventAlert:          false,

        eventOnMap:                 {},

        currentEvent:               {},

        eventsConfig:               {

            HeightSpeed: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            LowSpeed: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            Exited: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            EnterForbidden: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            EnterAllowed: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            StopForbidden: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            StopAllowed: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            LowBattery: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            ChargeOn: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            ChargeOff: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            Emergency: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            Assistance: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            GateOpen: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            GateClose: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            TrackLoadExceeded: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            },
            InsufficientDistance: {

                audio: "disable",
                level: "none",
                color: null,
                toast: "disable",
                vibrate: false
            }
        },
        eventsConfigProcess:        false,
        eventsConfigMsg:            {},

        saveEventsConfigProcess:    false,
        saveEventsConfigMsg:        {},

        warnings:                   []
    },

    actions: {

        async addConfirm({state}, payload) {

            state.events.array.map( stateEvent => {

                if(
                    stateEvent.track.uuid   == payload.trackUuid &&
                    stateEvent.type         == payload.type &&
                    stateEvent.obj.uuid     == payload.objectUuid && 
                    stateEvent.ntm          == payload.ntm
                ) {

                    stateEvent.confirm = payload.confirm
                }
            })
        },

        /**
         * Добавляет новое событие из сообщения по WS
         * 
         * @param {Object} payload - сообщение из WS в формате { action: String, eventId: String, eventSource: Object }
         */
        async addEvent({rootState, state, getters, dispatch}, payload) {

            const currentEvent = payload.eventSource

            // eventParam - текущее обрабатывемое событие из точки
            function buildEvent(track, obj) {

                let event = {

                    ntm: currentEvent.ntm ? Number(currentEvent.ntm) : null,
                    coords: { 

                        lat: currentEvent.point.lat, 
                        lon: currentEvent.point.lon 
                    },
                    obj: {

                        name: obj.name,
                        uuid: currentEvent.objectUuid
                    },
                    track:  { 

                        name: track.name, 
                        uuid: currentEvent.trackUuid 
                    },
                    eventId: payload.eventSource.eventId,
                    name:  'EVENT_' + currentEvent.type.toUpperCase(),
                    confirm: false, // eventParam.confirm,
                    type: currentEvent.type
                }

                // События в плашках в нижней части карты
                if(['web', 'both'].includes(state.eventsConfig[currentEvent.type].toast)) {

                    if(state.eventsToast.length > 1) {

                        state.eventsToast.shift()
                    }

                    state.eventsToast.push(event)
                }

                // События во всплывающих окнах
                if(state.eventsConfig[currentEvent.type].level == 'alert') {

                    state.eventsAlert[payload.eventSource.eventId] = event
                    state.hasWarnings = true // Индикатор для работы иконки-колокольчика
                }

                if(Object.keys(state.events).length > 1000) {

                    state.events.array.pop()
                }

                state.events[event.eventId] = event

                state.currentEvent = event
            }

            const token = getters.getToken
            let result = {}

            if(token) {

                let obj = {

                    name: '',
                    uuid: currentEvent.point.objectUuid
                }

                // Если в системе ещё нет объекта, который пришёл в точке
                if(!rootState.objects.objects.array[obj.uuid]) {

                    const requestEventObject = await dispatch('fetchRequest', {
                        address: `${rootState.config.httpServer}/objects?uuid=${obj.uuid}`, 
                        method: 'GET', 
                        token
                    })

                    if(!requestEventObject.error) {

                        obj.name = requestEventObject.array[0].name
                    } 

                } else {

                    obj.name = rootState.objects.objects[obj.uuid].name
                }

                // Если в системе ещё нет трека, который пришёл в точке
                if(!rootState.tracks.tracks[currentEvent.trackUuid]) {

                    dispatch('fetchRequest', {

                        address: `${rootState.config.httpServer}/tracks?uuid=${currentEvent.trackUuid}`, 
                        method: 'GET', 
                        token

                    }).then( track => {

                        if(!track.error) {

                            buildEvent(track.array[0], obj)
                        }
                    })

                } else {

                    buildEvent(rootState.tracks.tracks[currentEvent.trackUuid], obj)
                }

            } else {

                state.eventsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Сохраняет конфигурацию для уведомлений о событиях
         * 
         * @param {Object} eventTypeUUID - название типа события [см.swagger]
         */
         async readEventsConfig({rootState, state, getters, dispatch}, eventTypeUUID) {

            const token = getters.getToken

            if(token) {

                state.eventsConfigProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/settings/${eventTypeUUID}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.eventsConfigMsg = { success: true }

                    const audio     = request.setting ? request.setting.audio   : null
                    const level     = request.setting ? request.setting.level   : null
                    const color     = request.setting ? request.setting.color   : null
                    const toast     = request.setting ? request.setting.toast   : null
                    const vibrate   = request.setting ? request.setting.vibrate : null

                    if(audio)   state.eventsConfig[request.uuid].audio      = audio
                    if(level)   state.eventsConfig[request.uuid].level      = level
                    if(color)   state.eventsConfig[request.uuid].color      = color
                    if(toast)   state.eventsConfig[request.uuid].toast      = toast
                    if(vibrate) state.eventsConfig[request.uuid].vibrate    = vibrate

                } else {

                    state.eventsConfigMsg = { success: false }
                    state.eventsConfigMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.eventsConfigProcess = false

            } else {

                state.eventsConfigMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Сохраняет конфигурацию для уведомлений о событиях
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
         async saveEventsConfig({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.saveEventsConfigProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/settings`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.saveEventsConfigMsg = { success: true }

                } else {

                    state.saveEventsConfigMsg = { success: false }
                    state.saveEventsConfigMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.saveEventsConfigProcess = false

            } else {

                state.saveEventsConfigMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },
    },

    mutations: {

        setCurrentEvent:    (state, data) => state.currentEvent                         = data,
        setEventsConfigMsg: (state, data) => state.eventsConfigMsg                      = data,
        setHasWarnings:     (state, data) => state.hasWarnings                          = data, 
        setEventConfig:     (state, data) => state.eventsConfig[data.key][data.option]  = data.value,
        deleteEventToast:   (state, data) => state.eventsToast = state.eventsToast.filter(event => event.eventId !== data),

        deleteEventAlert(state, data) {
            
            delete state.eventsAlert[data]
            state.confirmEventAlert = false
        },

        setEventToast(state, event) {

            state.eventsToast[event.id] = event
        },

        setEventOnMap(state, data) { 
            
            state.eventOnMap = {}
            if(data) state.eventOnMap = data 
        }
    }
}