/**
 * * Функция для формирования разметки L Popup
 * @param   { Object }  location - {lat: 55.84744569048049, lng: 37.54875898361207}
 * @param   { Array }   zones - массив зон для рендеринга
 * @returns { String } - html разметка всплывающей подсказки
 */
export default function makePopup(location, zones) {
    
    let coords = `
        <div>Широта: ${location.lat}</div>
        <div>Долгота: ${location.lng}</div>
    `

    let links = ``

    if (zones.length > 0) {
        links += `
            <br>
            <div>Зоны:</div>
        `
    }

    zones.forEach(zone => {
        if (zone.type == 'line') {

            links += `
                <div class="tms-zones-map__tip-item">
                    <a class="tms-zones-map__tip-link" href='/zones/${zone.uuid}'>${zone.name}
                    </a>
                    <div class="tms-zones-map__tip-color" style="background-color: ${zone.options.color};"></div>
                </div>
            `
        } else {

            links += `
                <div class="tms-zones-map__tip-item">
                    <a class="tms-zones-map__tip-link" href='/zones/${zone.uuid}'>${zone.name}
                    </a>
                    <div class="tms-zones-map__tip-color" style="background-color: ${zone.options.fillColor};"></div>
                </div>
            `
        }
    })

    let markup = `
        <div class="tms-zones-map__tip">
            ${coords}
            ${links}
        </div> 
    `

    return markup
}
