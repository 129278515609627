<template>

    <div class="page page--tracks">

        <h1 class="page__header">{{ getTranslate.PAGE_TRACKS }}</h1>

        <div class="page__content">
            <TMSTracks />
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import TMSTracks from '@/components/TMSTracks'

export default {

    name: 'ViewTracks',

    components: {

        TMSTracks
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>