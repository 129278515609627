export default {

    state: {

        asideLeft: true,
        asideRight: true
    },

    mutations: {

        setAsideLeft:   (state, data) => state.asideLeft    = data,
        setAsideRight:  (state, data) => state.asideRight   = data
    }
}