<template>

    <div class="tms-track-groups-delete">

        <div
            v-if="'success' in trackGroupsDeleteMsg && !trackGroupsDeleteMsg.success"
            class="alert alert--danger"
        >
            {{ getTranslate[trackGroupsDeleteMsg.text] || trackGroupsDeleteMsg.text }}
        </div>

        <div class="my-4">Эта группа будет удалена из трека.</div>

        <form @submit.prevent="submitForm()">

            <div class="btns">

                <button
                    class="btn"
                    @click="$emit('closeModal', false)"
                >
                    {{ getTranslate.BTN_CANCEL }}
                </button>

                <button
                    type="submit"
                    class="btn btn--primary"
                >
                    {{ getTranslate.BTN_DELETE }}
                </button>

            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSTrackGroupsDelete',

    props: {

        groupUuid: {

            required: true,
            type: String
        },

        trackUuid: {

            required: true,
            type: String
        }
    },

    computed: {

        ...mapState({

            trackGroupsDeleteProcess:   state => state.trackGroups.trackGroupsDeleteProcess,
            trackGroupsDeleteMsg:       state => state.trackGroups.trackGroupsDeleteMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'deleteTrackGroup'
        ]),

        ...mapMutations([

            'setDeleteTrackGroupsMsg'
        ]),

        submitForm() {

            let data = {

                groupUuid: this.groupUuid,
                trackUuid: this.trackUuid
            }

            this.deleteTrackGroup(data)
        }
    },

    mounted() {

        this.setDeleteTrackGroupsMsg({})
    },

    watch: {

        trackGroupsDeleteMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal', false)
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>