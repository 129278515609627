<template>

    <div class="my-4 tms-widget-profile">

        <div class="tms-widget-profile__text">
            <span class="tms-widget-profile__text-greeting">{{ getTranslate.PROFILE_HELLO }}, </span> 
            <router-link to="/profile" class="tms-widget-profile__text-link">{{ profile.login }}</router-link>
        </div>

        <a
            href="#"
            @click.prevent="logout()"
            class="tms-widget-profile__logout btn"
        >
            {{ getTranslate.BTN_LOGOUT }}
        </a>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {

    name: 'TMSWidgetProfile',

    computed: {

        ...mapState({

            profile: state => state.profile.profile
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readProfile',
            'logout'
        ])
    },

    mounted() {

        this.readProfile()
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/widget-profile';
</style>