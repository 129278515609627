<template>
    <div class="tms-events-settings">

        <div class="alert alert--danger mb-4 tms-events-settings__msg" v-if="'success' in saveEventsConfigMsg && !saveEventsConfigMsg.success">
            {{ saveEventsConfigMsg.text }}
        </div>

        <form action="" @submit.prevent="submitForm($event)" class="tms-events-settings__form">

            <div class="tms-events-settings__types">

                <label for="TMSEventsSettingsType">{{ getTranslate.EVENTS_SETTINGS_TYPE }}</label>
                <select id="TMSEventsSettingsType" class="input input--block tms-events-settings__type" v-model="selectedEventType" @change="readEventsConfig(selectedEventType)">
                    <option :value="null" selected>...</option>
                    <option v-for="eventType in Object.keys(eventsConfig)" :key="eventType" :value="eventType">
                        {{ getTranslate['EVENT_' + eventType.toUpperCase()] }}
                    </option>
                </select>

                <template v-if="selectedEventType">

                    <div class="mt-5 mb-3 tms-events-settings__type-audio">
                        <label 
                            :for="'TMSNotify_' + selectedEventType +'_audio'"
                        >
                            {{ getTranslate.EVENTS_SETTINGS_AUDIO }}
                        </label>
                        <select 
                            class="input input--block"
                            :id="'TMSNotify_' + selectedEventType +'_audio'" 
                            @change="submitEventConfig({ key: selectedEventType, option: 'audio', value: $event.target.value })"
                            :value="eventsConfig[selectedEventType].audio"
                        >
                            <option value="disable">{{ getTranslate.EVENTS_SETTINGS_DISABLE }}</option>
                            <option value="mobile">{{ getTranslate.EVENTS_SETTINGS_MOBILE }}</option>
                            <option value="web">{{ getTranslate.EVENTS_SETTINGS_WEB }}</option>
                            <option value="both">{{ getTranslate.EVENTS_SETTINGS_BOTH }}</option>
                        </select>
                    </div>

                    <div class="my-3 vents-types__type-level">
                        <label 
                            :for="'TMSNotify_' + selectedEventType +'_level'"
                        >
                            {{ getTranslate.EVENTS_SETTINGS_LEVEL }}
                        </label>
                        <select 
                            class="input input--block"
                            :id="'TMSNotify_' + selectedEventType +'_level'" 
                            @change="submitEventConfig({ key: selectedEventType, option: 'level', value: $event.target.value })"
                            :value="eventsConfig[selectedEventType].level"
                        >
                            <option value="none">{{ getTranslate.EVENTS_SETTINGS_DISABLE }}</option>
                            <option value="inform">{{ getTranslate.EVENTS_SETTINGS_INFORM }}</option>
                            <option value="warning">{{ getTranslate.EVENTS_SETTINGS_WARNING }}</option>
                            <option value="alert">{{ getTranslate.EVENTS_SETTINGS_ALERT }}</option>
                        </select>
                    </div>

                    <div class="my-3 vents-types__type-toast">
                        <label 
                            :for="'TMSNotify_' + selectedEventType +'_toast'"
                        >
                            {{ getTranslate.EVENTS_SETTINGS_TOAST }}
                        </label>
                        <select 
                            class="input input--block"
                            :id="'TMSNotify_' + selectedEventType +'_toast'" 
                            @change="submitEventConfig({ key: selectedEventType, option: 'toast', value: $event.target.value })"
                            :value="eventsConfig[selectedEventType].toast"
                        >
                            <option value="disable">{{ getTranslate.EVENTS_SETTINGS_DISABLE }}</option>
                            <option value="mobile">{{ getTranslate.EVENTS_SETTINGS_MOBILE }}</option>
                            <option value="web">{{ getTranslate.EVENTS_SETTINGS_WEB }}</option>
                            <option value="both">{{ getTranslate.EVENTS_SETTINGS_BOTH }}</option>
                        </select>
                    </div>

                    <div class="my-4 row">
                        
                        <div class="col-12 col-sm-6">
                            <input 
                                type="checkbox" 
                                :id="'TMSNotify_' + selectedEventType +'_color'" 
                                @change="$event => eventsConfig[selectedEventType].color = $event.currentTarget.checked ? '#FF0000' : null "
                                :checked="eventsConfig[selectedEventType].color"
                            >
                            <label :for="'TMSNotify_' + selectedEventType +'_color'">{{ getTranslate.EVENTS_SETTINGS_COLOR }}</label>
                            <ColorPicker 
                                v-if="eventsConfig[selectedEventType].color"
                                :color="eventsConfig[selectedEventType].color"
                                @color-change="submitEventConfig({ key: selectedEventType, option: 'color', value: $event.colors.hex })"
                            />
                        </div>
                        
                        <div class="col-12 col-sm-6">
                            <div class="tms-events-settings__type-vibrate">
                                <input 
                                    type="checkbox" 
                                    :id="'TMSNotify_' + selectedEventType +'_vibrate'" 
                                    @change="submitEventConfig({ key: selectedEventType, option: 'vibrate', value: $event.target.checked ? true : false })"
                                    :checked="eventsConfig[selectedEventType].vibrate"
                                >
                                <label :for="'TMSNotify_' + selectedEventType +'_vibrate'">{{ getTranslate.EVENTS_SETTINGS_VIBRATE }}</label>
                            </div>
                        </div>

                    </div>

                </template>

                <template v-else>
                    <div class="tms-events-settings__descr mt-4">
                        {{ getTranslate.EVENTS_SETTINGS_DESCR }}
                    </div>
                </template>

            </div>

        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ColorPicker } from 'vue-accessible-color-picker'

export default {

    name: 'TMSEventsSettings',

    data() {

        return {

            selectedEventType: null
        }
    },

    components: {

        ColorPicker
    },

    computed: {

        ...mapState({

            eventsConfig:               state => state.events.eventsConfig,
            eventsConfigProcess:        state => state.events.eventsConfigProcess,
            eventsConfigMsg:            state => state.events.eventsConfigMsg,

            saveEventsConfigProcess:    state => state.events.saveEventsConfigProcess,
            saveEventsConfigMsg:        state => state.events.saveEventsConfigMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readEventsConfig',
            'saveEventsConfig'
        ]),

        ...mapMutations([

            'setEventConfig'
        ]),

        submitEventConfig(config) {

            const eventType = this.eventsConfig[config.key]

            this.setEventConfig(config)

            this.saveEventsConfig({

                setting: {

                    audio:      eventType.audio,
                    level:      eventType.level,
                    toast:      eventType.toast,
                    vibrate:    eventType.vibrate,
                    color:      eventType.color
                },
                uuid: config.key
            })
        }
    }
}
</script>