import HEXtoLevel from '@/helpers/zones/HEXtoLevel.js'

/**
 * Функция для создания массива координат для отрисовки в L Draw
 * @param { Array } coords - массив объектов { lat: '', lon: '' }
 * @returns { Array } - массив [ [ Number, Number ], [ Number, Number ] ]
 */
function makeRenderCoords(coords) {
    let res  = []

    coords.map(el => {
        res.push([
            el.lat,
            el.lon
        ])
    })

    return res
}

/**
 * Функция для создания объекта опций для отрисовки в L Draw
 * @param { Object } zone - объект зоны с сервера
 * geom: ''
 * name: ''
 * options: {
 *  dashArray: null
 *  display: true           // показать / скрыть фигуру
 *  fill: "#FF000080"       // цвет фона
 *  stroke: "#FF0000FF"     // цвет контура / линии    
 *  strokeWidth: "7"        // толщина контура / линии
 *  zIndex: 100             // z-index
 * }
 * purposes: '' ...
 * @param { Number | Undefined } metersPerPixel - коэффициент соотношения метр / пиксель для текущего масштаба карты
 * 
 * @returns { Object } - объект options для L Draw
 * color: "#FF0000FF"           // цвет контура
 * fill: true                   // фон фигуры
 * fillColor: "#FF000080"       // цвет фона фигуры
 * fillOpacity: 1               // прозрачность фона
 * weight: 2                    // толщина линии контура
 * zIndex: 100                  // z-index
 */
function makeRenderOptions(zone, metersPerPixel) {
    const options = {
        color: '#FF0000FF',
        weight: 0,
        fill: false,
        fillColor: '#FF000080',
        fillOpacity: 1.0
    }

    if (zone.options.stroke) {
        options.color = zone.options.stroke
    } 

    if (zone.options.strokeWidth) {
        options.weight = zone.options.strokeWidth
    }

    if (zone.options.fill) {
        options.fill = true
        options.fillColor = zone.options.fill
        options.fillOpacity = zone.options.fill.length == 9 ? HEXtoLevel(zone.options.fill.slice(7)) : 0.2
    }

    if (zone.options.zIndex) {
        options.zIndex = zone.options.zIndex
    }

    if ('line' in zone.zone) {
        options.fill = false
        options.weight = zone.zone.line.distance * 2 / metersPerPixel
    }

    return options
}

/**
 * Функция для формирования объекта options для L Draw
 * @param { Object } zoneFormOptions - объект формы создания новой зоны во Vuex
 * figure : "polygon"           // тип фигуры
 * fill : "#FF000080"           // цвет фона
 * name : "figName"             // название зоны
 * stroke : "#FF0000FF"         // цвет контура
 * strokeMode: Boolean          // контур фигуры
 * strokeWidth: null | 5        // толщина контура
 * type: "ordinary"             // тип зоны
 * zIndex: 100                  // z-index
 * @param { Number | Undefined } metersPerPixel - коэффициент соотношения метр / пиксель для текущего масштаба карты
 * 
 * @returns { Object } - объект options для L Draw
 * color: "#FF0000FF"           // цвет контура
 * fill: true                   // фон фигуры
 * fillColor: "#FF000080"       // цвет фона фигуры
 * fillOpacity: 1               // прозрачность фона
 * weight: 2                    // толщина линии контура
 */
function makeDrawOptions(zoneFormOptions, metersPerPixel) {
    const options = {
        color: '#ffffffff',         // цвет контура
        fill: false,                // фон фигуры
        fillColor: '#FF000080',     // цвет фона фигуры
        fillOpacity: 1.0,           // прозрачность фона
        weight: 0,                  // толщина контура
        draw: true                  // флаг для обозначения и поиска слоя во время рисования фигуры (слой еще не добавлен в dranItems)
    }

    if (zoneFormOptions.strokeMode) {
        options.color = zoneFormOptions.stroke
    } 

    if (zoneFormOptions.strokeWidth) {
        options.weight = zoneFormOptions.strokeWidth
    }

    if (zoneFormOptions.fill) {
        options.fill = true
        options.fillColor = zoneFormOptions.fill
        options.fillOpacity = zoneFormOptions.fill.length == 9 ? HEXtoLevel(zoneFormOptions.fill.slice(7)) : 0.2
    }

    // отключаем закрашивание фона для линии
    if (zoneFormOptions.figure === 'line') {
        options.fill = false
        options.fillColor = null
        options.color = zoneFormOptions.stroke
        options.weight = zoneFormOptions.strokeWidth * 2 / metersPerPixel
    }

    return options
}

/** Функция для создания массива координат линий и многоугольников для отправки на сервер
 * @param { Array } arr - массив координат [{ lat: '', lng: '' }]
 * @param { String } type - тип 
 * @returns { Array } - массив координат [{ lat: '', lon: '' }] для отправки на сервер
 */
function makeSaveCoords(arr, type) {
    let res = arr.map(el => {
        return {
            lat: el.lat,
            lon: el.lng
        }
    })

    if (type === 'polygon') {
        let firstPoint = res[0]
        res.push(firstPoint)
    }

    return res
}

/**
 * Функция для создания payload для сохранения зоны на сервере
 * @param { Object } options - опции внешнего вида фигуры из формы
 * closeTime : "new Date()"     // время закрытия
 * openTime : "new Date()"      // время открытия
 * figure : "polygon"           // тип фигуры
 * fill : "#FF000080"           // цвет фона
 * name : "figName"             // название зоны
 * stroke : "#FF0000FF"         // цвет контура
 * strokeMode: Boolean          // контур фигуры
 * strokeWidth: null | 5        // толщина контура
 * type: "ordinary"             // тип зоны
 * zIndex: 100                  // z-index
 * @param { Object } layer - экземпляр класса L Draw
 * @returns { Object } - объект для сохранения зоны на сервере
 * name: ''
 * options: ''
 * purposes: ''
 * zone: { polygon: {} }
 */
function makePayloadCreate(options, layer) {
    // опции внешнего вида зоны для сохранения на сервер
    const saveOptions = {
        dashArray: '',
        display: true
    }

    if (options.fill) {
        saveOptions.fill = options.fill
    } else {
        saveOptions.fill = ''
    }

    // если включен контур - определяем опции контура
    if (options.strokeMode) {
        saveOptions.stroke = options.stroke
        saveOptions.strokeWidth = String(options.strokeWidth)
    } else {
        saveOptions.stroke = ''
        saveOptions.strokeWidth = ''
    }

    // для линии заливка всегда отключена, контур всегда включен
    if (options.figure === 'line') {
        saveOptions.fill = ''
        saveOptions.stroke = options.stroke
        saveOptions.strokeWidth = String(options.strokeWidth)
    }

    if (options.zIndex) {
        saveOptions.zIndex = options.zIndex
    } else {
        saveOptions.zIndex = 0
    }

    const payload = {
        name: options.name,
        options: { ...saveOptions },
        purposes: {
            available: true, 
            schedule: [], 
            type: options.type
        },
        zone: {}
    }

    // линия
    if (options.figure === 'line') {
        let coords = makeSaveCoords(layer.editing.latlngs[0], 'line');

        payload.zone.line = {
            array : coords,
            distance: Number(options.strokeWidth)
        } 
    }

    // многоугольник
    if (options.figure === 'polygon') {
        let coords = makeSaveCoords(layer.editing.latlngs[0][0], 'polygon');

        payload.zone.polygon = {
            array : coords
        }
    }

    // окружность
    if (options.figure === 'circle') {
        payload.zone.circle = {
            point: {
                lat: layer._latlng.lat,
                lon: layer._latlng.lng
            },
            radius: Math.round(layer._mRadius)
        }
    }

    // рисуем сектор
    if (options.figure === 'sector') {
        payload.zone.sector = {
            circle: {
                point: {
                    lat: layer._center.lat,
                    lon: layer._center.lng
                },
                radius: Math.round(layer._outerRadius)
            },
            endBearing: Math.round(layer._endBearing),
            startBearing: Math.round(layer._startBearing)
        }
        
        // для дорожных работ добавляем интервал времени
        if(options.type === 'roadworks') {
            payload.purposes.schedule.push({ 
                openTime: new Date(options.openTime).getTime(), 
                closeTime: new Date(options.closeTime).getTime()
            })
        }
    }

    return payload
}

/**
 * Функция для создания объекта полей формы зоны
 * @param { Object } options - объект зоны с сервера
 * geom: ''
 * name: ''
 * options: {
 *  dashArray: null
 *  display: true           // показать / скрыть фигуру
 *  fill: "#FF000080"       // цвет фона
 *  stroke: "#FF0000FF"     // цвет контура / линии    
 *  strokeWidth: "7"        // толщина контура / линии
 *  zIndex: 100             // z-index
 *  purposes: {}            // содержит массив schedule [{ openTime, closeTime }]
 * }
 * purposes: '' ...
 * @returns { Object } - объект с полями формы зоны
 * figure : "polygon"           // тип фигуры
 * fill : "#FF000080"           // цвет фона
 * name : "figName"             // название зоны
 * stroke : "#FF0000FF"         // цвет контура
 * strokeMode: Boolean          // контур фигуры
 * strokeWidth: null | 5        // толщина контура
 * type: "ordinary"             // тип зоны
 * zIndex: 100                  // z-index
 * openTime: null | timestamp   // время открытия
 * closeTime: null | timestamp  // время закрытия
 */
function makeFormOptions(options) {
    let keys = Object.keys(options.zone)

    const res = {
        name: options.name,
        figure: keys[0],
        fill: options.options.fill,
        strokeWidth: options.options.strokeWidth,
        stroke: options.options.stroke,
        openTime: null,
        closeTime: null
    }

    if (options.options.stroke) {
        res.strokeMode = true
    } else {
        res.strokeMode = false
    }

    if (options.purposes?.schedule[0]?.openTime) {
        res.openTime = new Date(+options.purposes.schedule[0].openTime)
    }

    if (options.purposes?.schedule[0]?.closeTime) {
        res.closeTime = new Date(+options.purposes.schedule[0].closeTime)
    }

    // type и z-index - в предыдущей версии TMS, при создании фигуры, данные параметры отсутствовали
    if(options?.purposes?.type) {
        res.type = options.purposes.type
    } else {
        res.type = 'ordinary'
    }

    if(options?.options?.zIndex) {
        res.zIndex = options.options.zIndex
    } else {
        res.zIndex = 50
    }

    return res
}

export { makeRenderCoords, makeRenderOptions, makeDrawOptions, makePayloadCreate, makeFormOptions }