<template>
    <div class="tms">

        <TMSSprite />

        <template v-if="token">

            <header class="tms__header">
                <div class="tms__header-btn tms__header-btn--colleft" @click="controlAsideLeft()">
                    <span class="material-symbols-outlined tms__header-btn-icon">
                        menu
                    </span>
                </div>
                <router-link to="/" class="tms__header-logo">
                    <div class="tms__header-logo-name">TMS 2.0</div>
                    <div class="tms__header-logo-descr">Track management system</div>
                </router-link>
                <div class="tms__header-btn tms__header-btn--colright" @click="controlAsideRight()">
                    <span 
                        class="material-symbols-outlined tms__header-btn-icon"
                        :class="{ 'events-has-warnings' : hasWarnings }"
                    >
                        notifications
                    </span>
                </div>
            </header>

            <div class="tms__main">

                <aside class="tms__main-left" :class="{ 'tms__main-left--showed': asideLeft }">
                    <TMSMenu />
                    <TMSProfileWidget v-if="profile" />
                </aside>

                <main class="tms__main-middle" :class="{
                    'tms__main-middle--has-left-aside'  : asideLeft,
                    'tms__main-middle--has-right-aside' : asideRight
                }">

                    <div
                        v-if="'success' in wsMsg && !wsMsg.success"
                        class="alert alert--danger"
                    >
                        <div class="alert__text">
                            {{ getTranslate[wsMsg.text] || wsMsg.text }}
                        </div>
                    </div>

                    <router-view />

                </main>

                <aside class="tms__main-right" :class="{ 'tms__main-right--showed': asideRight }">
                    <TMSEvents />
                </aside>

            </div>

            <footer class="tms__footer">
                Разработано ФГУП "НАМИ", 2023
            </footer>

            <TMSEventsAlert />

        </template>

        <template v-else>

            <router-view />

        </template>

    </div>

</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import TMSSprite            from '@/components/TMSSprite'
import TMSProfileWidget     from '@/components/TMSProfileWidget'
import TMSMenu              from '@/components/TMSMenu'
import TMSEvents            from '@/components/TMSEvents'
import TMSEventsAlert       from '@/components/TMSEventsAlert'

export default {

    name: 'App',

    data() {

        return { 

            mobileViewWidth: 1200,
            EVENT_MAP_RESIZE: new CustomEvent('MAP_RESIZE', {})
        }
    },

    components: {

        TMSSprite,
        TMSProfileWidget,
        TMSMenu,
        TMSEvents,
        TMSEventsAlert
    },

    computed: {

        ...mapState({

            lang:           state => state.language.lang,
            profile:        state => state.profile.profile,
            httpServer:     state => state.config.httpServer,
            wsServer:       state => state.config.wsServer,
            token:          state => state.token.token,
            pageHeader:     state => state.pageOptions.pageHeader,
            zones:          state => state.zones.zones,
            wsMsg:          state => state.ws.wsMsg,
            asideLeft:      state => state.elements.asideLeft,
            asideRight:     state => state.elements.asideRight,
            eventOnMap:     state => state.events.eventOnMap,
            hasWarnings:    state => state.events.hasWarnings
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'updateLanguage',
            'readProfile',
            'addToken',
            'openWs',
            'closeWS'
        ]),

        ...mapMutations([

            'setHttpServer',
            'setWsServer',
            'setAsideLeft',
            'setAsideRight'
        ]),

        controlAsideLeft() {

            if(this.asideLeft) {

                this.setAsideLeft(false)
                localStorage.setItem('TMS_ASIDE_LEFT', false)

            } else {

                this.setAsideLeft(true)
                localStorage.setItem('TMS_ASIDE_LEFT', true)

                if(window.innerWidth < this.mobileViewWidth && this.asideRight) {

                    this.setAsideRight(false)
                    localStorage.setItem('TMS_ASIDE_RIGHT', false)
                }
            }

            if(window.innerWidth > this.mobileViewWidth) {

                setTimeout(() => window.dispatchEvent(this.EVENT_MAP_RESIZE), 300)
            }
        },

        controlAsideRight() {

            if(this.asideRight) {

                this.setAsideRight(false)
                localStorage.setItem('TMS_ASIDE_RIGHT', false)

            } else {

                this.setAsideRight(true)
                localStorage.setItem('TMS_ASIDE_RIGHT', true)

                if(window.innerWidth < this.mobileViewWidth && this.asideRight) {

                    this.setAsideLeft(false)
                    localStorage.setItem('TMS_ASIDE_LEFT', false)
                }
            }

            if(window.innerWidth > this.mobileViewWidth) {

                setTimeout(() => window.dispatchEvent(this.EVENT_MAP_RESIZE), 300)
            }
        },

        logout() {

            localStorage.removeItem('TMS_TOKEN')
            localStorage.removeItem('TMS_UUID')

            document.location.reload()
        }
    },

    created() {

        const BASE_URL = window.location.host
        const BASE_PROTOCOL = window.location.protocol

        if(process.env.NODE_ENV == 'development') {

            this.setHttpServer(`https://tms.shatl-t.ru/v1`) 
            this.setWsServer('wss://tms.shatl-t.ru/v1/events')

        } else {

            this.setHttpServer( BASE_PROTOCOL + '//' + BASE_URL + '/v1' )
            this.setWsServer( ( BASE_PROTOCOL == 'http:' ? 'ws' : 'wss') + '://' + BASE_URL + '/v1/events')
        }

        this.updateLanguage( localStorage.getItem('TMS_LANG') || 'RU' )

        document.getElementsByTagName('html')[0].setAttribute('lang', this.lang.toLowerCase())

        this.addToken()
    },

    mounted() {

        const self = this

        // Сохранённое состояние сайдбаров
        if(window.innerWidth >= this.mobileViewWidth) {

            this.setAsideLeft(
                localStorage.getItem('TMS_ASIDE_LEFT') ? 
                    JSON.parse(localStorage.getItem('TMS_ASIDE_LEFT')) : 
                    false
            )

            this.setAsideRight(
                localStorage.getItem('TMS_ASIDE_RIGHT') ? 
                    JSON.parse(localStorage.getItem('TMS_ASIDE_RIGHT')) : 
                    false
            )

        } else {

            this.setAsideLeft(false)
            this.setAsideRight(false)
        }

        setTimeout(() => window.dispatchEvent(this.EVENT_MAP_RESIZE), 300)

        window.addEventListener('resize', () => {

            if(window.innerWidth < self.mobileViewWidth) {

                self.setAsideLeft(false)
                self.setAsideRight(false)
            }
        })
    },

    watch: {

        eventOnMap() {

            if(window.outerWidth <= 575) {

                this.setAsideLeft(false)
                this.setAsideRight(false)
            }
        },

        token(token) {

            if(token) {

                this.closeWS().then(
                    () => this.openWs()
                )

            } else {

                this.closeWS()
            }
        }
    }
}

</script>

<style lang="scss">
@import '@/assets/styles/scss/layout.scss';
</style>