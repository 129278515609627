<template>

    <div class="tms-reports">

        <div
            v-if="'success' in reportsMsg && !reportsMsg.success"
            class="alert alert--danger"
        >
            {{ reportsMsg.text }}
        </div>

        <form action="" @submit.prevent="submitForm()" class="mb-5 p-4 tms-reports__form">

            <div class="mb-4 row">
                <div class="col">
                    <label for="TMSReportsType">{{ getTranslate.REPORTS_TYPE }}:</label>
                    <select 
                        id="TMSReportsType" 
                        v-model="TMSReportsType" 
                        class="input input--block"
                        :class="{ 'is-invalid' : formInvalids.TMSReportsType }"
                    >
                        <option :value="false" selected disabled>...</option>
                        <option :value="type.id" v-for="(type, index) in reportTypes" :key="index">{{ type.name }}</option>
                    </select>
                </div>
            </div>

            <hr>

            <template v-if="TMSReportsType == 'events'">
                <div class="my-4 row">
                    <div class="col-12">
                        <label for="TMSReportsObjects">{{ getTranslate.REPORTS_OBJECTS }}</label>
                        <select 
                            id="TMSReportsObjects" 
                            v-model="TMSReportsObjects" 
                            class="input input--block" 
                            multiple
                        >
                            <option value="" selected disabled>...</option>
                            <option :value="obj.uuid" v-for="(obj, index) in objects.array" :key="index">{{ obj.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label for="TMSReportsEventTypes">{{ getTranslate.REPORTS_EVENTS_TYPES }}</label>
                        <select 
                            id="TMSReportsEventTypes" 
                            v-model="TMSReportsEventTypes" 
                            class="input input--block" 
                            multiple
                        >
                            <option value="" selected disabled>...</option>
                            <option value="EnterForbidden">{{ translateEventType('EnterForbidden') }}</option>
                            <option value="EnterAllowed">{{ translateEventType('EnterAllowed') }}</option>
                            <option value="Exited">{{ translateEventType('Exited') }}</option>
                            <option value="HeightSpeed">{{ translateEventType('HeightSpeed') }}</option>
                            <option value="LowBattery">{{ translateEventType('LowBattery') }}</option>
                            <option value="LowSpeed">{{ translateEventType('LowSpeed') }}</option>
                            <option value="StopForbidden">{{ translateEventType('StopForbidden') }}</option>
                            <option value="StopAllowed">{{ translateEventType('StopAllowed') }}</option>
                            <option value="ChargeOn">{{ translateEventType('ChargeOn') }}</option>
                            <option value="ChargeOff">{{ translateEventType('ChargeOff') }}</option>
                            <option value="GateOpen">{{ translateEventType('GateOpen') }}</option>
                            <option value="GateClose">{{ translateEventType('GateClose') }}</option>
                            <option value="TrackLoadExceeded">{{ translateEventType('TrackLoadExceeded') }}</option>
                            <option value="InsufficientDistance">{{ translateEventType('InsufficientDistance') }}</option>
                        </select>
                    </div>
                </div>
            </template>

            <template v-if="TMSReportsType == 'tracks'">
                <div class="my-4 row">
                    <div class="col-12">
                        <label for="TMSReportsTracks">{{ getTranslate.REPORTS_TRACKS }}</label>
                        <select 
                            id="TMSReportsTracks" 
                            v-model="TMSReportsTracks" 
                            class="input input--block" 
                            :class="{ 'is-invalid' : formInvalids.TMSReportsTracks }"
                            multiple
                        >
                            <option value="" selected disabled>...</option>
                            <option :value="track.uuid" v-for="(track, index) in tracks.array" :key="index">{{ track.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label for="TMSReportsGroups">{{ getTranslate.REPORTS_GROUPS }}</label>
                        <select 
                            id="TMSReportsGroups" 
                            v-model="TMSReportsGroups" 
                            class="input input--block" 
                            :class="{ 'is-invalid' : formInvalids.TMSReportsGroups }"
                            multiple
                        >
                            <option value="" selected disabled>...</option>
                            <option :value="group.uuid" v-for="(group, index) in groups.array" :key="index">{{ group.name }}</option>
                        </select>
                    </div>
                </div>
            </template>

            <div class="mt-4 row">
                <div class="col-12 col-sm-6">
                    <label for="TMSReportStart">{{ getTranslate.REPORTS_START }}</label>
                    <v-date-picker 
                        v-model="reportStart" 
                        id="TMSReportsStart"
                        mode="dateTime" 
                        is24hr 
                        :locale="lang"
                        :popover="{ placement: 'bottom', visibility: 'click' }"
                        @input="delete formInvalids.reportStart" 
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                class="input input--block"
                                :class="{ 'is-invalid': formInvalids.reportStart }"
                                id="openTime"
                                readonly
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </template>
                    </v-date-picker>
                </div>
                <div class="col-12 col-sm-6">
                    <label for="TMSReportsStop">{{ getTranslate.REPORTS_STOP }}</label>
                    <v-date-picker 
                        v-model="reportStop" 
                        id="TMSReportsStop"
                        mode="dateTime" 
                        is24hr 
                        :locale="lang"
                        :popover="{ placement: 'bottom', visibility: 'click' }"
                        @input="delete formInvalids.reportStop" 
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                class="input input--block"
                                :class="{ 'is-invalid': formInvalids.reportStop }"
                                id="openTime"
                                readonly
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </template>
                    </v-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="mt-4 btns">
                        <button
                            type="submit"
                            :disabled="reportsProcess"
                            class="btn btn--primary"
                            :class="{ 'btn--spinner' : reportsProcess }"
                        >   
                            <template v-if="reportsProcess">
                                <div class="spinner" role="status"></div>
                            </template>
                            <span class="btn__text">{{ getTranslate.BTN_REQUEST }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <template v-if="reports.array && !reportsMsg.error">
            <div class="list">
                <div class="list__item tms-reports__report" v-for="(item, index) in reports.array" :key="index">
                    <div class="list__item-content">
                        <div class="tms-reports__report-item">
                            <div class="d-md-flex my-3 mb-4 align-items-center">
                                <h3 class="tms-reports__report-item-type">
                                    <span 
                                        class="material-symbols-outlined tms-reports__report-item-location" 
                                        @click="
                                            eventModal = item, 
                                            showEventModal = true
                                        "
                                    >
                                        location_on
                                    </span>
                                    {{ getTranslate['EVENT_' + item.type.toUpperCase()] || item.type }}
                                    <span class="tms-reports__report-item-bb" v-if="item.bb">
                                        <span class="material-symbols-outlined tms__header-btn-icon">
                                            warning
                                        </span>
                                    </span>
                                </h3>
                                <div class="ms-auto mt-4 tms-reports__report-item-date">
                                    {{ new Date(item.ntm * 1000).toLocaleString() }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="tms-reports__report-item-obj my-2 d-flex align-items-center">
                                        <span class="material-symbols-outlined tms-reports__report-item-icon"> directions_car </span> 
                                        <router-link :to="'objects/' + item.objectUuid" target="_blank" style="color: #444; text-decoration: none; border-bottom: 1px dashed #999;">{{ item.objectName }}</router-link>
                                    </div>
                                    <div class="tms-reports__report-item-track my-2 d-flex align-items-center">
                                        <span class="material-symbols-outlined tms-reports__report-item-icon"> timeline </span> 
                                        <router-link :to="'tracks/' + item.trackUuid" target="_blank" style="color: #444; text-decoration: none; border-bottom: 1px dashed #999;">{{ item.trackName }}</router-link>
                                    </div>
                                    <div class="tms-reports__report-item-group my-2 d-flex align-items-center">
                                        <span class="material-symbols-outlined tms-reports__report-item-icon"> workspaces </span> 
                                        <router-link :to="'groups/' + item.groupUuid" target="_blank" style="color: #444; text-decoration: none; border-bottom: 1px dashed #999;">{{ item.groupName }}</router-link>
                                    </div>
                                </div>
                                <div class="mt-3 mt-md-0 col-12 col-md-4 d-flex align-items-md-end justify-content-md-end">
                                    <div class="tms-reports__report-item-event-id">
                                        Event ID: {{ item.eventId }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid my-3" v-if="showPagination">
                <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation">
                    <div class="tms-pagination__prev">
                        <button 
                            type="button" 
                            aria-label="Previous page" 
                            class="tms-pagination__prev-btn"
                            :class="{ 'tms-pagination__prev-btn--disabled' : !reports.c.isBefore }"
                            :disabled="!reports.c.isBefore"
                            @click.prevent="prevPage(reports.c.start)"
                        >
                            <span class="material-symbols-outlined">
                                chevron_left
                            </span>
                        </button>
                    </div>
                    <div class="tms-pagination__current">
                        <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                    </div>
                    <div class="tms-pagination__next">
                        <button 
                            type="button" 
                            aria-label="Next page" 
                            class="tms-pagination__next-btn" 
                            :class="{ 'tms-pagination__next-btn--disabled' : !reports.c.isAfter }"
                            :disabled="!reports.c.isAfter"
                            @click.prevent="nextPage(reports.c.stop)"
                        >
                            <span class="material-symbols-outlined">
                                chevron_right
                            </span>
                        </button>
                    </div>
                </nav>
            </div>
        </template>

        <transition name="bounce">
            <Modal
                v-if="showEventModal"
                max-width="600"
                :header="getTranslate['EVENT_' + eventModal.type.toUpperCase()] || eventModal.type"
                size="lg"
                @closeModal="showEventModal = false"
                :show="showEventModal"
            >
                <template v-slot:body>
                    <TMSReportModal :event="eventModal" />
                </template>
            </Modal>
        </transition>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import TMSReportModal from '@/components/TMSReportModal.vue'

export default {
    
    name: 'TMSReportss',

    data() {

        return {

            TMSReportsType:         null, 
            TMSReportsObjects:      [], 
            TMSReportsEventTypes:   [], 
            TMSReportsTracks:       [], 
            TMSReportsGroups:       [], 

            reportStart:            Date.now() - 2592000000, 
            reportStop:             Date.now(), 

            formInvalids:           {}, 
            formErrors:             [], 

            inputSearch:            '', 
            pageNumber:             1,

            eventModal:             {}, 
            showEventModal:         false
        }
    },

    components: {

        Modal, 
        TMSReportModal
    },

    computed: {

        ...mapState({

            lang:               state => state.language.lang,

            reports:            state => state.reports.reports, 
            reportsProcess:     state => state.reports.reportsProcess, 
            reportsMsg:         state => state.reports.reportsMsg, 

            objects:            state => state.objects.objects,
            objectsProcess:     state => state.objects.objectsProcess,

            tracks:             state => state.tracks.tracks,
            tracksProcess:      state => state.tracks.tracksProcess,

            groups:             state => state.groups.groups,
            groupsProcess:      state => state.groups.groupsProcess
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        showPagination() {

            return this.reports.array.length && 
                (
                    this.reports.c.isBefore || 
                    this.reports.c.isAfter
                )
        },

        reportTypes() {

            return [

                { id: 'events', name: this.getTranslate.REPORTS_TYPE_EVENTS },
                { id: 'tracks', name: this.getTranslate.REPORTS_TYPE_TRACKS }
            ]
        }
    },

    methods: {

        ...mapActions([

            'readReports',
            'readObjects',
            'readTracks',
            'readGroups'
        ]),

        ...mapMutations([

            'setReports',
            'setReportsMsg'
        ]),

        prevPage(cursor) {

            let params = {

                'r.c.limit': 15, 
                'r.c.direction': 'before', 
                'r.c.cursor': encodeURIComponent(cursor),
                'r.start': Math.round(new Date(this.reportStart).getTime() / 1000),
                'r.stop': Math.round(new Date(this.reportStop).getTime() / 1000),
                reportType: this.TMSReportsType
            }

            if(this.TMSReportsEventTypes.length)
                params['typeArray'] = this.TMSReportsEventTypes

            if(this.TMSReportsObjects.length)
                params['objectUuidArray'] = this.TMSReportsObjects

            if(this.inputSearch) 
                params['r.c.q'] = this.inputSearch

            this.readReports(params).then( reports => {

                if(!reports.error) 
                    this.pageNumber--
            })
        },

        nextPage(cursor) {

            let params = {

                'r.c.limit': 15, 
                'r.c.direction': 'after', 
                'r.c.cursor': encodeURIComponent(cursor),
                'r.start': Math.round(new Date(this.reportStart).getTime() / 1000),
                'r.stop': Math.round(new Date(this.reportStop).getTime() / 1000),
                reportType: this.TMSReportsType
            }

            if(this.TMSReportsEventTypes.length)
                params['typeArray'] = this.TMSReportsEventTypes

            if(this.TMSReportsObjects.length)
                params['objectUuidArray'] = this.TMSReportsObjects

            if(this.inputSearch) 
                params['r.c.q'] = this.inputSearch

            this.readReports(params).then( reports => {

                if(!reports.error) 
                    this.pageNumber++
            })
        },

        translateEventType(eventType) {

            return this.getTranslate['EVENT_' + eventType.toUpperCase()] || eventType
        },

        submitForm() {

            let data = {

                'r.c.limit': 15
            }

            this.formInvalids   = {}
            this.valid          = true

            if(this.TMSReportsType) {

                if(this.TMSReportsType == 'events') {

                    if(this.TMSReportsObjects.length) {

                        data.objectUuidArray = this.TMSReportsObjects
                    }

                    if(this.TMSReportsEventTypes.length) {

                        data.typeArray = this.TMSReportsEventTypes
                    }

                } else if(this.TMSReportsType == 'tracks') {

                    if(this.TMSReportsTracks.length) {

                        data.trackUuidArray = this.TMSReportsTracks
                    }

                    if(this.TMSReportsGroups.length) {

                        data.groupUuidArray = this.TMSReportsGroups
                    }
                }

                data.reportType = this.TMSReportsType

            } else {

                this.formInvalids.TMSReportsType = true
                this.valid = false
            }

            if(this.reportStart) {

                data['r.start'] = Math.round(new Date(this.reportStart).getTime() / 1000)

            } else {

                this.formInvalids.TMSReportsStart = true
                this.valid = false
            }

            if(this.reportStop) {

                data['r.stop'] = Math.round(new Date(this.reportStop).getTime() / 1000)

            } else {

                this.formInvalids.TMSReportsStop = true
                this.valid = false
            }

            if(this.valid) {

                this.readReports(data)
            }
        }
    },

    beforeUnmount() {

        this.setReports({})
        this.setReportsMsg({})
    },

    watch: {

        TMSReportsType(type) {

            switch(type) {

                case 'events': 
                    this.readObjects({ 'c.limit': 1000 })
                    break

                case 'tracks': 
                    this.readTracks({ 'c.limit': 1000 })
                    this.readGroups({ 'c.limit': 1000 })
                    break
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/reports.scss';
</style>