<template>
    <div class="loader">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
    </div>
</template>

<script>
export default {

    name: 'CLoader'
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/loader';
</style>