<template>

    <div class="page page--test">

        <h1 class="page__header">{{ getTranslate.PAGE_TEST }}</h1>

        <div class="page__content">
            <TestForm />
        </div>

    </div>
    
</template>

<script>
import TestForm from '@/components/TestForm'
import { mapGetters } from 'vuex'

export default {

    name: 'ViewTest',

    components: {

        TestForm
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/test.scss';
</style>