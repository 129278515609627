export default {

    state: {

        users: {},
        usersProcess: false,
        usersMsg: {},

        user: {},
        userProcess: false,
        userMsg: {},

        userInsertProcess: false,
        userInsertMsg: {},

        userCreateProcess: false,
        userCreateMsg: {},

        userUpdateProcess: false,
        userUpdateMsg: {},

        userDeleteProcess: false,
        userDeleteMsg: {}
    },

    actions: {

        /**
         * Создаёт нового пользователя
         * 
         * @param {Object} payload - объект с полями формы создания нового пользователя
         */
        async createUser({state, getters, dispatch}, payload) {

            const token = getters.getToken
            
            if(token) {

                state.userCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/users`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.userCreateMsg = { success: true, text: getters.getTranslate.USERS_CREATE_SUCCESS }
                    state.users[request.uuid] = request

                } else {

                    state.userCreateMsg = { success: false }
                    state.userCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.userCreateProcess = false

            } else {

                state.userCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает пользователей
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readUsers({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.usersProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload[param].map( item => {

                                params.length ?
                                    params += '&' + param + '=' + item :
                                    params = '?' + param + '=' + item
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    let users = {}

                    request.array.map( user => users[user.uuid] = user )

                    state.users = {

                        array: users,
                        c: request.c
                    }

                    state.usersMsg = { success: true }

                    result = state.users

                } else {

                    state.usersMsg = { success: false }
                    state.usersMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.usersProcess = false

            } else {

                state.usersMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Получает пользователя по UUID
         * 
         * @param {String} payload - UUID пользователя в системе
         */
        async readUser({state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.userProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/users/${payload.uuid}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.user = request
                    state.userMsg = { success: true }

                    return state.user

                } else {

                    state.userMsg = { success: false }
                    state.userMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.userProcess = false

            } else {

                state.userMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Обновляет профиль пользователя
         * 
         * @param {Object} payload - объект с полями формы профиля пользователя
         */
        async updateUser({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.userUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/users`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.userUpdateMsg = { success: true, text: getters.getTranslate.USER_UPDATE_SUCCESS }
                    dispatch('readUser', { uuid: payload.uuid } )

                } else {

                    state.userUpdateMsg = { success: false }
                    state.userUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.userUpdateProcess = false
                
            } else {

                state.userUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаление пользователя
         * 
         * @param {Object} payload - payload.uuid - UUID пользователя
         */
        async deleteUser ({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.userDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/users/${payload.uuid}`,
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.userDeleteMsg = { success: true, text: getters.getTranslate.USER_DELETE_SUCCESS }
                    dispatch('readUsers')

                } else {

                    state.userDeleteMsg = { success: false }
                    state.userDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }
            
                state.userDeleteProcess = false
                
            } else {

                state.userDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCreateUserMsg:   (state, data)   => state.userCreateMsg          = data,
        setUpdateUserMsg:   (state, data)   => state.userUpdateMsg          = data,
        setDeleteUserMsg:   (state, data)   => state.userDeleteMsg          = data,
        insertUser:         (state, data)   => state.users.array[data.uuid] = data
    }
}