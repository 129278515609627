export default {

    state: {

        groups:             {},
        groupsProcess:      false,
        groupsMsg:          {},

        groupCreateProcess: false,
        groupCreateMsg:     {},

        group:              {},
        groupProcess:       false,
        groupMsg:           {},

        groupUpdateProcess: false,
        groupUpdateMsg:     {},

        groupDeleteProcess: false,
        groupDeleteMsg:     {}
    },

    actions: {

        /**
         * Создаёт новой группы
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
        async createGroup({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.groupCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/groups`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.groupCreateMsg = { success: true, text: getters.getTranslate.GROUPS_CREATE_SUCCESS }
                    dispatch('readGroups')

                } else {

                    state.groupCreateMsg = { success: false }
                    state.groupCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.groupCreateProcess = false

            } else {

                state.groupCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает группы
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readGroups({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.groupsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload[param].map( item => {

                                params.length ?
                                    params += '&' + param + '=' + item :
                                    params = '?' + param + '=' + item
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/groups${params}`, 
                    methods: 'GET', 
                    token
                })

                if(!request.error) {

                    let groups = {}
                    request.array.map( group => groups[group.uuid] = group )

                    state.groups = {

                        array: groups,
                        c: request.c
                    }

                    state.groupsMsg = { success: true }

                    result = state.groups

                } else {

                    state.groups = {}
                    state.groupsMsg = { success: false }
                    state.groupsMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.groupsProcess = false

            } else {

                state.groupsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Получает группу по UUID для вывода на экране просмотра и редактирования группы
         * 
         * @param {Object} payload - uuid группы
         */
        async readGroup({state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.groupProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/groups/${payload.uuid}`, 
                    methods: 'GET', 
                    token
                })

                if(!request.error) {

                    state.group = request
                    state.groupMsg = { success: true }

                    result = state.group

                } else {

                    state.groupMsg = { success: false }
                    state.groupMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.groupProcess = false

            } else {

                state.groupMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Обновляет группу
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
        async updateGroup({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.groupUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/groups`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.groupUpdateMsg = { success: true, text: getters.getTranslate.GROUPS_UPDATE_SUCCESS }
                    dispatch('readGroup', { uuid: payload.uuid } )

                } else {

                    state.groupUpdateMsg = { success: false }
                    state.groupUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.groupUpdateProcess = false

            } else {

                state.groupUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаление группы
         * 
         * @param {Object} payload - payload.uuid: UUID группы
         */
        async deleteGroup ({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.groupDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/groups/${payload.uuid}`,
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.groupDeleteMsg = { success: true, text: getters.getTranslate.GROUPS_DELETE_SUCCESS }
                    dispatch('readGroups')

                } else {

                    state.groupDeleteMsg = { success: false }
                    state.groupDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.groupDeleteProcess = false

            } else {

                state.groupDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCreateGroupMsg:  (state, data)   => state.groupCreateMsg             = data,
        setUpdateGroupMsg:  (state, data)   => state.groupUpdateMsg             = data,
        setDeleteGroupMsg:  (state, data)   => state.groupDeleteMsg             = data,
        insertGroup:        (state, data)   => state.groups.array[data.uuid]    = data
    }
}