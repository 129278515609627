<template>

    <div class="tms-users">

        <div class="p-3 tms-users__search">
            <div class="input-group input-wrapper">
                <input 
                    type="text" 
                    class="input input--block search__input" 
                    :placeholder="getTranslate['SEARCH_PLACEHOLDER']" 
                    v-model="inputSearch"
                >
                <template v-if="inputSearch.length > 2">
                    <span class="material-symbols-outlined input-wrapper__icon" @click="inputSearch = ''">
                        cancel
                    </span>
                </template>
                <template v-else>
                    <span class="material-symbols-outlined input-wrapper__icon">
                        search
                    </span>
                </template>
            </div>
        </div>

        <div
            v-if="userCreateMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">
                {{ userCreateMsg.text }}
            </div>
            <div class="alert__close" @click="setCreateUserMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="userDeleteMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">{{ userDeleteMsg.text }}</div>
            <div class="alert__close" @click="setDeleteUserMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="'success' in usersMsg && !usersMsg.success"
            class="alert alert--danger"
        >
            <div class="alert__text">{{ usersMsg.text }}</div>
        </div>

        <div class="list tms-users__list" v-if="users.array">
            <div class="spinner-overlay" v-if="usersProcess">
                <div class="spinner spinner--lg" role="status"></div>
            </div>
            <div class="list__item tms-users__user" v-for="(user, index) in users.array" :key="index">
                <div class="row">
                    <div class="col-10 col-xxl-11">
                        <div class="list__item-content">
                            <router-link :to="'/users/' + user.uuid" class="tms-users__user-name list__item-link">{{ user.login }}</router-link> 
                            <div class="tms-users__user-type">[{{ user.type }}]</div>
                        </div>
                    </div>
                    <div class="col-2 col-xxl-1 text-end">
                        <div class="list__item-btns">
                            <div class="list__item-btn tms-users__user-delete">
                                <span class="material-symbols-outlined" @click.prevent="deleteUserUUID = user.uuid, showDeleteUser = true">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid my-3">
            <div class="row d-flex align-items-center">
                <div class="col">
                    <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="users.c?.isBefore || users.c?.isAfter">
                        <div class="tms-pagination__prev">
                            <button 
                                type="button" 
                                aria-label="Previous page" 
                                class="tms-pagination__prev-btn"
                                :class="{ 'tms-pagination__prev-btn--disabled' : !users.c.isBefore }"
                                :disabled="!users.c.isBefore"
                                @click.prevent="prevPage(users.c.start)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_left
                                </span>
                            </button>
                        </div>
                        <div class="tms-pagination__current">
                            <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                        </div>
                        <div class="tms-pagination__next">
                            <button 
                                type="button" 
                                aria-label="Next page" 
                                class="tms-pagination__next-btn" 
                                :class="{ 'tms-pagination__next-btn--disabled' : !users.c.isAfter }"
                                :disabled="!users.c.isAfter"
                                @click.prevent="nextPage(users.c.stop)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_right
                                </span>
                            </button>
                        </div>
                    </nav>
                </div>
                <div class="col">
                    <div class="btns btns--right">
                        <a 
                            href="#"
                            class="btn btn--round btn--secondary"
                            @click.prevent="showCreateUser = true"
                        >
                            <span class="material-symbols-outlined">
                                add
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <transition name="bounce">
            <Modal
                v-if="showCreateUser"
                max-width="600"
                :header="getTranslate.USERS_CREATE"
                size="lg"
                :show="showCreateUser"
                @closeModal="showCreateUser = false"
            >
                <template v-slot:body>
                    <TMSUsersCreate @closeModal="showCreateUser = false" />
                </template>
            </Modal>
        </transition>

        <transition name="bounce">
            <Modal
                v-if="showDeleteUser"
                max-width="600"
                :header="getTranslate.USERS_DELETE"
                size="lg"
                @closeModal="showDeleteUser = false"
                :show="showDeleteUser"
            >
                <template v-slot:body>
                    <TMSUsersDelete :uuid="deleteUserUUID" @showDeleteUser="showDeleteUser = $event" />
                </template>
            </Modal>
        </transition>

    </div>

</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import TMSUsersCreate   from '@/components/TMSUsersCreate'
import TMSUsersDelete   from '@/components/TMSUsersDelete'
import Modal            from '@/components/Modal'

export default {

    name: 'TMSUsers',

    data() {

        return {

            showCreateUser: false,
            showDeleteUser: false,
            deleteUserUUID: null,

            pageNumber: 1,
            inputSearch: ''
        }
    },

    components: {

        TMSUsersCreate,
        TMSUsersDelete,
        Modal
    },

    computed: {

        ...mapState({

            users:          state => state.users.users,
            usersProcess:   state => state.users.usersProcess,
            usersMsg:       state => state.users.usersMsg,
            userCreateMsg:  state => state.users.userCreateMsg,
            userDeleteMsg:  state => state.users.userDeleteMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readUsers'
        ]),

        ...mapMutations([

            'setCreateUserMsg',
            'setDeleteUserMsg'
        ]),

        prevPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readUsers(params).then(result => !result.error ? this.pageNumber-- : null)
        },

        nextPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readUsers(params).then(result => !result.error ? this.pageNumber++ : null)
        }
    },

    created() {

        this.readUsers({ 'c.limit': 15 })
    },

    beforeUnmount() {

        this.setCreateUserMsg({})
        this.setDeleteUserMsg({})
    },

    watch: {

        inputSearch(input) {

            let params = {

                'c.limit': 15
            }

            if(input.length > 2)
                params['c.q'] = input

            this.readUsers(params).then(() => this.pageNumber = 1)
        },

        userCreateMsg(msg) {

            if(msg && msg.success) {

                this.readUsers({ 'c.limit': 15 })
            }
        },

        userDeleteMsg(msg) {

            if(msg && msg.success) {

                this.readUsers({ 'c.limit': 15 })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/users';
</style>