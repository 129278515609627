<template>
    <div class="tms-track-zones-create">

        <div 
            v-if="'success' in trackZonesListListMsg && !trackZonesListListMsg.success"
            class="mb-4 alert alert--danger tms-track-groups-create__message" 
        >
            <div class="alert__text">
                {{ trackZonesListListMsg.text }}
            </div>
        </div>

        <div class="tms-track-groups__search">
            <div class="input-group input-wrapper">
                <input 
                    type="text" 
                    class="input input--block search__input" 
                    :placeholder="getTranslate.SEARCH_PLACEHOLDER" 
                    v-model="inputSearch"
                >
                <template v-if="inputSearch.length > 2">
                    <span class="material-symbols-outlined input-wrapper__icon" @click="inputSearch = ''">
                        cancel
                    </span>
                </template>
                <template v-else>
                    <span class="material-symbols-outlined input-wrapper__icon">
                        search
                    </span>
                </template>
            </div>
        </div>

        <div class="list list--trans">
            <div class="list__item" v-for="(zone, index) in trackZonesListList.array" :key="index">
                <div class="row">
                    <div class="col-10 d-flex align-items-center">
                        {{ zone.name }}
                    </div>
                    <div class="col-2">
                        <a 
                            href="#" 
                            class="btn btn--secondary"
                            @click.prevent="$emit('selectZone', { uuid: zone.uuid, name: zone.name })"
                        >
                            <span class="material-symbols-outlined">
                                add
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid my-3">
            <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="trackZonesListList.c?.isBefore || trackZonesListList.c?.isAfter">
                <div class="tms-pagination__prev">
                    <button 
                        type="button" 
                        aria-label="Previous page" 
                        class="tms-pagination__prev-btn"
                        :class="{ 'tms-pagination__prev-btn--disabled' : !trackZonesListList.c.isBefore }"
                        :disabled="!trackZonesListList.c.isBefore"
                        @click.prevent="prevPage(trackZonesListList.c.start)"
                    >
                        <span class="material-symbols-outlined">
                            chevron_left
                        </span>
                    </button>
                </div>
                <div class="tms-pagination__current">
                    <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                </div>
                <div class="tms-pagination__next">
                    <button 
                        type="button" 
                        aria-label="Next page" 
                        class="tms-pagination__next-btn" 
                        :class="{ 'tms-pagination__next-btn--disabled' : !trackZonesListList.c.isAfter }"
                        :disabled="!trackZonesListList.c.isAfter"
                        @click.prevent="nextPage(trackZonesListList.c.stop)"
                    >
                        <span class="material-symbols-outlined">
                            chevron_right
                        </span>
                    </button>
                </div>
            </nav>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    
    name: 'TMSTrackZonesCreate',

    data() {

        return {

            inputSearch: '',
            pageNumber: 1
        }
    },

    computed: {

        ...mapState({

            trackZonesListList: state => state.trackZonesList.trackZonesListList, 
            trackZonesListListMsg: state => state.trackZonesList.trackZonesListListMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readTrackZonesListList'
        ]),

        prevPage(cursor) {

            let params = {

                'c.limit': 6, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTrackZonesListList(params).then(() => this.pageNumber-- )
        },

        nextPage(cursor) {

            let params = {

                'c.limit': 6, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTrackZonesListList(params).then(() => this.pageNumber++)
        }
    },

    created() {

        this.readTrackZonesListList({ 'c.limit': 6 })
    }, 

    watch: {

        inputSearch(input) {

            let params = {

                'c.limit': 6
            }

            if(input.length > 2)
                params['c.q'] = input

            this.readTrackZonesListList(params).then(() => this.pageNumber = 1)
        }
    }, 

    emits: [

        'selectZone'
    ]
}
</script>