<template>

    <div class="tms-groups">

        <div class="p-3 tms-groups__search">
            <div class="input-group input-wrapper">
                <input 
                    type="text" 
                    class="input input--block search__input" 
                    :placeholder="getTranslate['SEARCH_PLACEHOLDER']" 
                    v-model="inputSearch"
                >
                <template v-if="inputSearch.length > 2">
                    <span class="material-symbols-outlined input-wrapper__icon" @click="inputSearch = ''">
                        cancel
                    </span>
                </template>
                <template v-else>
                    <span class="material-symbols-outlined input-wrapper__icon">
                        search
                    </span>
                </template>
            </div>
        </div>

        <div
            v-if="groupCreateMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">
                {{ groupCreateMsg.text }}
            </div>
            <div class="alert__close" @click="setCreateGroupMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="groupDeleteMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">
                {{ groupDeleteMsg.text }}
            </div>
            <div class="alert__close" @click="setDeleteGroupMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="'success' in groupsMsg && !groupsMsg.success"
            class="alert alert--danger"
        >
            <div class="alert__text">
                {{ groupsMsg.text }}
            </div>
        </div>

        <div class="list tms-groups__list" v-if="groups.array">
            <div class="spinner-overlay" v-if="groupsProcess">
                <div class="spinner spinner--lg" role="status"></div>
            </div>
            <div class="list__item tms-groups__group" v-for="(group, index) in groups.array" :key="index">
                <div class="row">
                    <div class="col-10 col-xxl-11">
                        <div class="list__item-content">
                            <router-link :to="'/groups/' + index" class="tms-groups__group-name list__item-link">{{ group.name }}</router-link> 
                        </div>
                    </div>
                    <div class="col-2 col-xxl-1 text-end">
                        <div class="list__item-btns">
                            <div class="list__item-btn tms-groups__group-delete">
                                <span class="material-symbols-outlined" @click.prevent="deleteGroupUUID = index, showDeleteGroup = true">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid my-3">
            <div class="row d-flex align-items-center">
                <div class="col">
                    <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="groups.c?.isBefore || groups.c?.isAfter">
                        <div class="tms-pagination__prev">
                            <button 
                                type="button" 
                                aria-label="Previous page" 
                                class="tms-pagination__prev-btn"
                                :class="{ 'tms-pagination__prev-btn--disabled' : !groups.c.isBefore }"
                                :disabled="!groups.c.isBefore"
                                @click.prevent="prevPage(groups.c.start)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_left
                                </span>
                            </button>
                        </div>
                        <div class="tms-pagination__current">
                            <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                        </div>
                        <div class="tms-pagination__next">
                            <button 
                                type="button" 
                                aria-label="Next page" 
                                class="tms-pagination__next-btn" 
                                :class="{ 'tms-pagination__next-btn--disabled' : !groups.c.isAfter }"
                                :disabled="!groups.c.isAfter"
                                @click.prevent="nextPage(groups.c.stop)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_right
                                </span>
                            </button>
                        </div>
                    </nav>
                </div>
                <div class="col">
                    <div class="btns btns--right">
                        <a 
                            href="#"
                            class="btn btn--round btn--secondary"
                            @click.prevent="showCreateGroup = true"
                        >
                            <span class="material-symbols-outlined">
                                add
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <transition name="bounce">
            <Modal
                v-if="showCreateGroup"
                max-width="600"
                :header="getTranslate.GROUPS_CREATE"
                size="lg"
                :show="showCreateGroup"
                @closeModal="showCreateGroup = false"
            >
                <template v-slot:body>
                    <TMSGroupsCreate @closeModal="showCreateGroup = false" />
                </template>
            </Modal>
        </transition>

        <transition name="bounce">
            <Modal
                v-if="showDeleteGroup"
                max-width="600"
                :header="getTranslate.GROUPS_DELETE"
                size="lg"
                :show="showDeleteGroup"
                @closeModal="showDeleteGroup = false, deleteGroupUUID = null"
            >
                <template v-slot:body>
                    <TMSGroupsDelete :uuid="deleteGroupUUID" @closeModal="showDeleteGroup = false, deleteGroupUUID = null" />
                </template>
            </Modal>
        </transition>

    </div>

</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import TMSGroupsCreate from '@/components/TMSGroupsCreate'
import TMSGroupsDelete from '@/components/TMSGroupsDelete'
import Modal           from '@/components/Modal'

export default {

    name: 'TMSGroups',

    data() {

        return {

            showCreateGroup: false,
            showDeleteGroup: false,

            deleteGroupUUID: null,

            pageNumber: 1,
            inputSearch: ''
        }
    },

    components: {

        TMSGroupsCreate,
        TMSGroupsDelete,
        Modal
    },

    computed: {

        ...mapState({

            groups:         state => state.groups.groups,
            groupsProcess:  state => state.groups.groupsProcess,
            groupsMsg:      state => state.groups.groupsMsg,
            groupCreateMsg: state => state.groups.groupCreateMsg,
            groupDeleteMsg: state => state.groups.groupDeleteMsg,
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        showPagination() {

            return Object.keys(this.groups.array).length && 
                (
                    this.groups.c.isBefore || 
                    this.groups.c.isAfter
                )
        }
    },

    methods: {

        ...mapActions([

            'readGroups'
        ]),

        ...mapMutations([

            'setCreateGroupMsg',
            'setDeleteGroupMsg'
        ]),

        prevPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readGroups(params).then(() => this.pageNumber-- )
        },

        nextPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readGroups(params).then(() => this.pageNumber++)
        }
    },

    mounted() {

        this.readGroups({ 'c.limit': 15 })
    },

    beforeUnmount() {

        this.setCreateGroupMsg({})
        this.setDeleteGroupMsg({})
    },

    watch: {

        inputSearch(input) {

            let params = {

                'c.limit': 15
            }

            if(input.length > 2)
                params['c.q'] = input

            this.readGroups(params).then(() => this.pageNumber = 1)
        },

        groupCreateMsg(msg) {

            if(msg && msg.success) {

                this.readGroups({ 'c.limit': 15 })
            }
        },

        groupDeleteMsg(msg) {

            if(msg && msg.success) {

                this.readGroups({ 'c.limit': 15 })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/_pagination';
@import '@/assets/styles/scss/components/groups';
</style>