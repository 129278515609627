<template>
    <div 
        class="tms-events" 
        id="TMSDashboardEvents"
    >
        <div class="tms-events__wrapper">

            <div class="tms-events__settings">
                {{ getTranslate.EVENTS_SETTINGS }}
                <span class="material-symbols-outlined tms-events__settings-icon" @click="showSettings = true, settingsSaveFailure = false">
                    settings
                </span>
            </div>

            <div class="tms-events__list">

                <template v-if="Object.keys(events).length">
                    <div 
                        class="tms-events__event" 
                        v-for="(event, index) in events" 
                        :key="index" 
                        :style="eventsConfig[event.type].color ? 'background-color: ' + eventsConfig[event.type].color + '26' : ''"
                    >
                        <h5 
                            class="tms-events__event-name"
                            :style="eventsConfig[event.type].color ? 'color: ' + eventsConfig[event.type].color : ''"
                        >
                            <template v-if="eventsConfig[event.type].level == 'alert' || eventsConfig[event.type].level == 'warning'">
                                <span 
                                    class="material-symbols-outlined tms-events__event-name-check" 
                                    :style="eventsConfig[event.type].color ? 'color: ' + eventsConfig[event.type].color : ''"
                                    v-if="event.confirm"
                                >
                                    check_box
                                </span>
                                <span 
                                    class="material-symbols-outlined tms-events__event-name-check" 
                                    :style="eventsConfig[event.type].color ? 'color: ' + eventsConfig[event.type].color : ''"
                                    v-if="!event.confirm"
                                    @click.prevent="submitEventConfirm(event)"
                                >
                                    check_box_outline_blank
                                </span>
                            </template>

                            {{ getTranslate[event.name] }}

                            <span 
                                class="material-symbols-outlined tms-events__event-name-icon" 
                                :style="eventsConfig[event.type].color ? 'color: ' + eventsConfig[event.type].color : ''"
                                @click="focusOnPoint(event.coords)" 
                                v-if="event.coords.lat && event.coords.lon"
                            >
                                location_on
                            </span>

                        </h5>
                        <router-link :to="'/objects/' + event.obj.uuid" class="tms-events__event-link">{{ event.obj.name }}</router-link>
                        <router-link :to="'/tracks/' + event.track.uuid" class="tms-events__event-link" v-if="event.track.name">{{ event.track.name }}</router-link>
                        <div class="tms-events__event-time">{{ buildTime(event.ntm) }}</div>
                    </div>
                </template>

                <template v-else>
                    <div class="tms-events__empty">
                        {{ getTranslate.EVENTS_EMPTY }}
                    </div>
                </template>

            </div>

            <video id="TMSEventsAudio" preload hidden>
                <source :src="require('@/assets/media/sound/notification/std.mp3')">
            </video>

        </div>

        <transition name="bounce">
            <Modal
                v-if="showSettings"
                max-width="600"
                :header="getTranslate.EVENTS_SETTINGS"
                size="lg"
                @closeModal="showSettings = false"
                :show="showSettings"
            >
                <template v-slot:body>

                    <TMSEventsSettings />

                </template>
            </Modal>
        </transition>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Modal                from '@/components/Modal'
import TMSEventsSettings    from '@/components/TMSEventsSettings'

export default {

    name: 'TMSDashboard',

    data() {

        return {

            showSettings: false,
            msgSettings: null,
            TMSEventsAudio: null
        }
    },

    props: {

        mode: {

            type: String,
            required: false
        }
    },

    components: {

        Modal,
        TMSEventsSettings
    },

    computed: {

        ...mapState({

            ws:                         state => state.ws.ws,

            events:                     state => state.events.events,
            eventsProcess:              state => state.events.eventsProcess,
            eventsMsg:                  state => state.events.eventsMsg,
            eventOnMap:                 state => state.events.eventOnMap,

            eventsConfig:               state => state.events.eventsConfig,
            eventsConfigProcess:        state => state.events.eventsConfigProcess,
            eventsConfigMsg:            state => state.events.eventsConfigMsg,

            currentEvent:               state => state.events.currentEvent,
            eventsAlert:                state => state.events.eventsAlert
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readEventsConfig'
        ]),

        ...mapMutations([

            'setEventOnMap',
            'setEventsConfigMsg'
        ]),

        buildSpeed: speed => speed ? speed + this.getTranslate.UNIT_KMH : '-',
        buildTime:  time => time ? new Date(time).toLocaleString() : '-',

        focusOnPoint(coords) {

            this.setEventOnMap(coords)

            if(this.$route.fullPath !== '/') {

                this.$router.push('/')
            }
        },

        submitEventConfirm(event) {

            let data = {

                action: 'EditA',

                eventConfirm: {

                    eventId: event.eventId,
                    trackUuid: event.track.uuid,
                    type: event.type,
                    confirm: true
                }
            }

            this.ws.send(JSON.stringify(data))
        }
    },

    mounted() {

        Object.keys(this.eventsConfig).map(key => {

            this.readEventsConfig(key)
        })

        this.TMSEventsAudio = document.getElementById('TMSEventsAudio')
    },

    beforeUnmount() {

        this.setEventsConfigMsg({})
    },

    watch: {

        currentEvent(event) {

            if(
                this.eventsConfig[event.type] && 
                ['web', 'both'].includes(this.eventsConfig[event.type].audio)
            ) {

                this.TMSEventsAudio.play()
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/events';
</style>