<template>
    <svg style="display: none;">

        <symbol id="icon_map_layers" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5"  fill="var(--white)" stroke="var(--gold)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.38922 17.4665C8.90948 17.0667 8.90948 16.3299 9.38922 15.9301L19.8272 7.23178C20.1981 6.92274 20.7366 6.92274 21.1075 7.23178L31.5455 15.9301C32.0252 16.3299 32.0252 17.0667 31.5455 17.4665L21.1075 26.1648C20.7366 26.4738 20.1981 26.4738 19.8272 26.1648L9.38922 17.4665ZM20.4673 23.5L12.5 16.6983L20.4673 10L28 16.6983L20.4673 23.5Z" fill="var(--black)"/>
            <path d="M8.29291 23.3727C7.85907 23.8065 7.91461 24.5252 8.41009 24.8872L19.862 33.2559C20.2208 33.5181 20.7094 33.5121 21.0617 33.2411L32.5646 24.3927C33.043 24.0246 33.0888 23.3198 32.662 22.893L32.5646 22.7932C32.2089 22.4374 31.6442 22.4014 31.2462 22.709L21.1996 30.4826C20.848 30.7542 20.3594 30.7613 20 30.5L9.79529 23C9.39734 22.7106 8.79802 22.8521 8.45 23.2L8.29291 23.3727Z" fill="var(--black)"/>
        </symbol>

        <symbol id="icon_map_track" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="var(--white)" stroke="var(--gold)"/>
            <ellipse cx="10.4194" cy="27.6" rx="2.4" ry="2.41935" transform="rotate(-90 10.4194 27.6)" fill="var(--black)"/>
            <path d="M10.4194 27.2002C10.285 24.8002 10.4538 22.2299 10.8227 20.4002C11.6561 16.2654 16.4678 16.0002 18.484 18.0002C20.4646 19.9648 21.3066 26.2002 24.1292 26.8002C26.0109 27.2002 30.9839 27.2002 29.7742 12.4002" stroke="var(--black)" stroke-width="2"/>
            <path d="M29.6645 9.50326C29.8241 9.26385 30.1759 9.26385 30.3355 9.50326L33.5821 14.3731C33.7607 14.6411 33.5686 15 33.2466 15H26.7534C26.4314 15 26.2393 14.6411 26.4179 14.3731L29.6645 9.50326Z" fill="var(--black)"/>
        </symbol>

        <symbol id="icon_map_open" width="15" height="10" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.19204 13.8921C9.59146 14.4398 10.4085 14.4398 10.808 13.8921L19.2333 2.33923C19.7152 1.67841 19.2432 0.75 18.4253 0.75L1.57466 0.75C0.756772 0.75 0.284771 1.67841 0.7667 2.33923L9.19204 13.8921Z" fill="#555"/>
        </symbol>

        <symbol id="icon_map_close" width="15" height="10" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.19204 13.8921C9.59146 14.4398 10.4085 14.4398 10.808 13.8921L19.2333 2.33923C19.7152 1.67841 19.2432 0.75 18.4253 0.75L1.57466 0.75C0.756772 0.75 0.284771 1.67841 0.7667 2.33923L9.19204 13.8921Z" fill="#ffffff"/>
        </symbol>

    </svg>
</template>