<template>

    <div class="tms-tracks">

        <div class="p-3 tms-tracks__search">
            <div class="input-group input-wrapper">
                <input 
                    type="text" 
                    class="input input--block search__input" 
                    :placeholder="getTranslate['SEARCH_PLACEHOLDER']" 
                    v-model="inputSearch"
                >
                <template v-if="inputSearch.length > 2">
                    <span class="material-symbols-outlined input-wrapper__icon" @click="inputSearch = ''">
                        cancel
                    </span>
                </template>
                <template v-else>
                    <span class="material-symbols-outlined input-wrapper__icon">
                        search
                    </span>
                </template>
            </div>
        </div>

        <div
            v-if="trackCreateMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">
                {{ trackCreateMsg.text }}
            </div>
            <div class="alert__close" @click="setCreateTrackMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="trackDeleteMsg.success"
            class="alert alert--success"
        >
            <div class="alert__text">
                {{ trackDeleteMsg.text }}
            </div>
            <div class="alert__close" @click="setDeleteTrackMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <div
            v-if="'success' in tracksMsg && !tracksMsg.success"
            class="alert alert--danger"
        >
            <div class="alert__text">{{ tracksMsg.text }}</div>
        </div>

        <div class="list tms-tracks__list" v-if="tracks.array">
            <div class="spinner-overlay" v-if="tracksProcess">
                <div class="spinner spinner--lg" role="status"></div>
            </div>
            <div class="list__item tms-tracks__track" v-for="(track, index) in tracks.array" :key="index">
                <div class="row">
                    <div class="col-10 col-xxl-11">
                        <div class="list__item-content">
                            <router-link :to="'/tracks/' + track.uuid" class="tms-tracks__track-name list__item-link">{{ track.name }}</router-link> 
                            <div class="tms-tracks__track-cars">[{{ getTranslate.TRACKS_CARS_IN_TRACK }}: {{ track.carsNumber }}]</div>
                        </div>
                    </div>
                    <div class="col-2 col-xxl-1 text-end">
                        <div class="list__item-btns">
                            <div class="list__item-btn tms-tracks__track-delete">
                                <span class="material-symbols-outlined" @click.prevent="deleteTrackUUID = track.uuid, showDeleteTrack = true">
                                    delete
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid my-3">
            <div class="row d-flex align-items-center">
                <div class="col">
                    <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="tracks.c?.isBefore || tracks.c?.isAfter">
                        <div class="tms-pagination__prev">
                            <button 
                                type="button" 
                                aria-label="Previous page" 
                                class="tms-pagination__prev-btn"
                                :class="{ 'tms-pagination__prev-btn--disabled' : !tracks.c.isBefore }"
                                :disabled="!tracks.c.isBefore"
                                @click.prevent="prevPage(tracks.c.start)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_left
                                </span>
                            </button>
                        </div>
                        <div class="tms-pagination__current">
                            <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                        </div>
                        <div class="tms-pagination__next">
                            <button 
                                type="button" 
                                aria-label="Next page" 
                                class="tms-pagination__next-btn" 
                                :class="{ 'tms-pagination__next-btn--disabled' : !tracks.c.isAfter }"
                                :disabled="!tracks.c.isAfter"
                                @click.prevent="nextPage(tracks.c.stop)"
                            >
                                <span class="material-symbols-outlined">
                                    chevron_right
                                </span>
                            </button>
                        </div>
                    </nav>
                </div>
                <div class="col">
                    <div class="btns btns--right">
                        <a 
                            href="#"
                            class="btn btn--round btn--secondary"
                            @click.prevent="showCreateTrack = true"
                        >
                            <span class="material-symbols-outlined">
                                add
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <transition name="bounce">
            <Modal
                v-if="showCreateTrack"
                :header="getTranslate.TRACKS_CREATE"
                :show="showCreateTrack"
                @closeModal="showCreateTrack = false"
                max-width="600"
                size="lg"
            >
                <template v-slot:body>
                    <TMSTracksCreate @closeModal="showCreateTrack = false" />
                </template>
            </Modal>
        </transition>

        <transition name="bounce">
            <Modal
                v-if="showDeleteTrack"
                :header="getTranslate.TRACKS_DELETE"
                :show="showDeleteTrack"
                @closeModal="showDeleteTrack = false"
                max-width="600"
                size="md"
            >
                <template v-slot:body>
                    <TMSTracksDelete :uuid="deleteTrackUUID" @closeModal="showDeleteTrack = false" />
                </template>
            </Modal>
        </transition>

    </div>

</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import TMSTracksCreate from '@/components/TMSTracksCreate'
import TMSTracksDelete from '@/components/TMSTracksDelete'
import Modal           from '@/components/Modal'

export default {

    name: 'TMSTracks',

    data() {

        return {

            showCreateTrack: false,
            showDeleteTrack: false,

            deleteTrackUUID: null,

            pageNumber: 1,
            inputSearch: ''
        }
    },

    components: {

        TMSTracksCreate,
        TMSTracksDelete,
        Modal
    },

    computed: {

        ...mapState({

            tracks:         state => state.tracks.tracks,
            tracksProcess:  state => state.tracks.tracksProcess,
            tracksMsg:      state => state.tracks.tracksMsg,
            trackCreateMsg: state => state.tracks.trackCreateMsg,
            trackDeleteMsg: state => state.tracks.trackDeleteMsg,
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        showPagination() {

            return Object.keys(this.tracks.array).length && 
                (
                    this.tracks.c.isBefore || 
                    this.tracks.c.isAfter
                )
        }
    },

    methods: {

        ...mapActions([

            'readTracks'
        ]),

        ...mapMutations([

            'setCreateTrackMsg',
            'setDeleteTrackMsg'
        ]),

        prevPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTracks(params).then(() => this.pageNumber-- )
        },

        nextPage(cursor) {

            let params = {

                'c.limit': 15, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor)
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTracks(params).then(() => this.pageNumber++)
        }
    },

    mounted() {

        this.readTracks({ 'c.limit': 15 })
    },

    beforeUnmount() {

        this.setCreateTrackMsg({})
        this.setDeleteTrackMsg({})
    },

    watch: {

        inputSearch(input) {

            let params = {

                'c.limit': 15
            }

            if(input.length > 2)
                params['c.q'] = input

            this.readTracks(params).then(() => this.pageNumber = 1)
        },

        trackCreateMsg(msg) {

            if(msg && msg.success) {

                this.readTracks({ 'c.limit': 15 })
            }
        },

        trackDeleteMsg(msg) {

            if(msg && msg.success) {

                this.readTracks({ 'c.limit': 15 })
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/tracks';
</style>