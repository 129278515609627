<template>
    <div class="leaflet-draw" id="leaflet-draw-container" style="height: 100%;"></div>
</template>

<script>

import L from 'leaflet'                             // Leaflet
import 'leaflet-draw/dist/leaflet.draw-src.js'      // Leaflet Draw
import 'leaflet.sector/leaflet.sector.js'           // Leaflet Sector - для Leaflet Draw Sector
import 'leaflet.draw-sector/Leaflet.draw-sector.js' // Leaflet Draw Sector - рисование сектора

export default {
    
    name: "LeafletDraw",

    data() {

        return {

            map: {},
            drawnItems: {},

            zones: {
                "c": {
                    "start": "dsvJASgQS/ePtNpGrZD+Yg==",
                    "stop": "JJLWWP5eTkKhZM9lCLibkA==",
                    "isAfter": false,
                    "isBefore": false
                },
                "array": [
                    {
                        "uuid": "76cbc901-2810-4bf7-8fb4-da46ad90fe62",
                        "name": "Test (mobile)",
                        "zone": {
                            "sector": {
                                "circle": {
                                    "point": {
                                        "lon": 37.247772226701635,
                                        "lat": 56.33970656070092
                                    },
                                    "radius": 142
                                },
                                "startBearing": -2901,
                                "endBearing": -2868
                            },
                            "distance": 0
                        },
                        "geom": null,
                        "userUuid": "bde99bfd-1b14-4c6a-a9d2-7833a787698d",
                        "options": {
                            "stroke": "",
                            "strokeWidth": "",
                            "dashArray": "",
                            "fill": "#15ff0080",
                            "display": true,
                            "zIndex": 100
                        },
                        "purposes": {
                            "type": "gate",
                            "schedule": [],
                            "available": true
                        },
                        "updatedAt": "1693309530"
                    },
                    {
                        "uuid": "f0905c7f-2d47-4717-8052-de5524f8b879",
                        "name": "Остановка перед полигоном",
                        "zone": {
                            "sector": {
                                "circle": {
                                    "point": {
                                        "lon": 37.246978282928474,
                                        "lat": 56.337951645269634
                                    },
                                    "radius": 327
                                },
                                "startBearing": 238,
                                "endBearing": 282
                            },
                            "distance": 0
                        },
                        "geom": null,
                        "userUuid": "bde99bfd-1b14-4c6a-a9d2-7833a787698d",
                        "options": {
                            "stroke": "",
                            "strokeWidth": "",
                            "dashArray": "",
                            "fill": "#ae00ff80",
                            "display": true,
                            "zIndex": 100
                        },
                        "purposes": {
                            "type": "stop",
                            "schedule": [],
                            "available": true
                        },
                        "updatedAt": "1693237794"
                    },
                    {
                        "uuid": "2492d658-fe5e-4e42-a164-cf6508b89b90",
                        "name": "Шлагбаум 1",
                        "zone": {
                            "sector": {
                                "circle": {
                                    "point": {
                                        "lon": 37.24810481071473,
                                        "lat": 56.33802003480269
                                    },
                                    "radius": 198
                                },
                                "startBearing": 245,
                                "endBearing": 279
                            },
                            "distance": 0
                        },
                        "geom": null,
                        "userUuid": "bde99bfd-1b14-4c6a-a9d2-7833a787698d",
                        "options": {
                            "stroke": "",
                            "strokeWidth": "",
                            "dashArray": "",
                            "fill": "#FF000080",
                            "display": true,
                            "zIndex": 100
                        },
                        "purposes": {
                            "type": "gate",
                            "schedule": [],
                            "available": true
                        },
                        "updatedAt": "1693238726"
                    }
                ]
            }
        }
    },

    mounted() {

        // добавление карты
        this.map = L.map('leaflet-draw-container', {
            drawControl: true, 
            center: [55.848349, 37.543011],
            zoomControl: false,
            zoom: 18,
            minZoom: 5,
            maxZoom: 20,
            attributionControl: false
        })

        // основная карта
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: 5,
            maxZoom: 20,
            noWrap: true
        }).addTo(this.map)
    }
}
</script>

<style lang="scss">
@import '~@/../node_modules/leaflet/dist/leaflet.css';              // Leaflet
@import '~@/../node_modules/leaflet-draw/dist/leaflet.draw.css';    // Leaflet Draw
@import '@/assets/styles/scss/components/draw';
</style>