export default {

    state: {

        RU: {

            YES:                        'Да',
            NO:                         'Нет',

            UNIT_KMH:                   'км/ч',

            ACCESS_READ:                'Чтение',
            ACCESS_READ_WRITE:          'Чтение и запись',
            ACCESS_READ_WRITE_DELETE:   'Чтение, запись и удаление',
            ACCESS_NO:                  'Нет прав',

            EVENTS_SETTINGS:            'Настройки',
            EVENTS_SETTINGS_DESCR:      'Выберите тип события для настройки его уведомлений',
            EVENTS_SETTINGS_TYPE:       'Тип события',
            EVENTS_SETTINGS_DISABLE:    'Отключено',
            EVENTS_SETTINGS_MOBILE:     'Мобильные устройства',
            EVENTS_SETTINGS_WEB:        'Десктоп',
            EVENTS_SETTINGS_BOTH:       'Везде',
            EVENTS_SETTINGS_INFORM:     'Информация',
            EVENTS_SETTINGS_WARNING:    'Внимание',
            EVENTS_SETTINGS_ALERT:      'Важное',
            EVENTS_SETTINGS_AUDIO:      'Звук',
            EVENTS_SETTINGS_TOAST:      'Плашка',
            EVENTS_SETTINGS_VIBRATE:    'Вибрация',
            EVENTS_SETTINGS_CONFIRM:    'Требует подтверждения',
            EVENTS_SETTINGS_COLOR:      'Выделение цветом',
            EVENTS_SETTINGS_LEVEL:      'Уровень',

            PAGE_AUTH:                  'Авторизация',
            PAGE_DASHBOARD:             'Главная',
            PAGE_EVENTS:                'События',
            PAGE_PROFILE:               'Профиль',
            PAGE_USERS:                 'Пользователи',
            PAGE_USER:                  'Пользователь',
            PAGE_ZONES:                 'Зоны',
            PAGE_ZONE:                  'Зона',
            PAGE_ZONE_CREATE:           'Новая зона',
            PAGE_GROUPS:                'Группы',
            PAGE_GROUP:                 'Группа',
            PAGE_OBJECTS:               'Объекты',
            PAGE_OBJECT:                'Объект',
            PAGE_TRACKS:                'Треки',
            PAGE_TRACK:                 'Трек',
            PAGE_TEST:                  'Тестовая форма',
            PAGE_REPORTS:               'Отчёты',

            DASHBOARD_MAP_POPUP_OBJECT: 'Объект',
            DASHBOARD_MAP_POPUP_SPY:    'Следить',

            // Текст кнопок
            BTN_ACCEPT:                 'Подтвердить', 
            BTN_ADD:                    'Добавить',
            BTN_CREATE:                 'Создать',
            BTN_NEW:                    'Новый',
            BTN_EDIT:                   'Изменить',
            BTN_SAVE:                   'Сохранить',
            BTN_CANCEL:                 'Отмена',
            BTN_DELETE:                 'Удалить',
            BTN_CTRL:                   'Управление',
            BTN_REQUEST:                'Запросить',
            BTN_SHOW:                   'Показать',
            BTN_UPLOAD:                 'Загрузить',
            BTN_DOWNLOAD:               'Скачать',
            BTN_CLOSE:                  'Закрыть',
            BTN_SELECT:                 'Выбрать',
            BTN_LOGIN:                  'Войти',
            BTN_LOGOUT:                 'Выйти',
            BTN_GOOD:                   'Хорошо',
            BTN_THINK_MORE:             'Подумать ещё',
            BTN_HIDE:                   'Скрыть',
            BTN_CHANGE:                 'Изменить',

            SEARCH:                     'Поиск',
            SEARCH_PLACEHOLDER:         'Поиск...',

            PAGINATION_PAGE:            'Страница',
            PAGINATION_PREV:            'Предыдущая',
            PAGINATION_NEXT:            'Следующая',

            USER_TYPE_NO_TYPE:          'Без группы',
            USER_TYPE_OPERATOR:         'Оператор',
            USER_TYPE_ADMINISTRATOR:    'Администратор',

            LAT:                        'Широта',
            LON:                        'Долгота',

            LOGIN:                      'Логин',
            PASSWORD:                   'Пароль',
            TYPE:                       'Группа',
            ACCESS:                     'Доступ',

            PROFILE_HELLO:              'Здравствуйте',

            FIELDS_VALUE_NOT_SET:       'Не задано', 

            USER_CREATE:                'Создать',
            USER_NEW:                   'Новый пользователь',
            USER_LOGIN:                 'Имя',
            USER_UUID:                  'UUID',
            USER_EMAIL:                 'E-mail',
            USER_PASS:                  'Пароль',
            USER_ACCESS:                'Права',
            USER_TYPE:                  'Группа',
            USER_PHONE:                 'Телефон',
            USER_UPDATE_SUCCESS:        'Профиль пользователя обновлён',
            USER_DELETE:                'Удаление пользователя',
            USER_DELETE_TEXT:           'Этот пользователь будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            USER_DELETE_SUCCESS:        'Пользователь удалён',

            USERS:                      'Пользователи',
            USERS_LOADING:              'Получение пользователей...',
            USERS_EMPTY:                'Пользователи не найдены',
            USERS_CREATE:               'Новый пользователь',
            USERS_CREATE_LOGIN:         'Логин',
            USERS_CREATE_PASS:          'Пароль',
            USERS_CREATE_TYPE:          'Тип',
            USERS_CREATE_ACCESS:        'Права',
            USERS_CREATE_SUCCESS:       'Новый пользователь добавлен',
            USERS_CREATE_ERROR_LOGIN:   'Некорректный логин',
            USERS_CREATE_ERROR_PASS:    'Некорректный пароль',
            USERS_CREATE_ERROR_TYPE:    'Не указана группа пользователя',
            USERS_CREATE_ERROR_ACCESS:  'Не указан уровень доступа',
            USERS_DELETE:               'Удаление пользователя',

            OBJECTS:                    'Объекты',
            OBJECTS_LOADING:            'Получение oбъектов...',
            OBJECTS_EMPTY:              'Объекты не найдены',
            OBJECTS_CREATE:             'Новый объект',
            OBJECTS_NAME:               'Имя',
            OBJECTS_IMEI:               'IMEI',
            OBJECTS_GROUP:              'Группа',
            OBJECTS_USER:               'Владелец',
            OBJECTS_ERROR_NAME:         'Введите имя',
            OBJECTS_ERROR_IMEI:         'Введите IMEI',
            OBJECTS_ERROR_IMEI_FILTER:  'IMEI должен содержать 15 символов',
            OBJECTS_ERROR_GROUP:        'Выберите группу',
            OBJECTS_ERROR_USER:         'Выберите пользователя',
            OBJECTS_PASSWORD:           'Пароль',
            OBJECTS_PASSWORD_REPEAT:    'Повторите пароль',
            OBJECTS_ERROR_PASSWORD:     'Введите пароль для объекта',
            OBJECTS_ERROR_PASSWORD_REPEAT: 'Повторите ввод пароля для объекта',
            OBJECTS_CREATE_SUCCESS:     'Новый объект добавлен',
            OBJECTS_UPDATE_SUCCESS:     'Объект успешно отредактирован',
            OBJECTS_DELETE:             'Удаление объекта',
            OBJECTS_DELETE_SUCCESS:     'Объект успешно удалён',

            OBJECT_UUID :               'UUID',
            OBJECT_NAME :               'Имя',
            OBJECT_IMEI :               'IMEI',
            OBJECT_USER :               'Владелец',
            OBJECT_GROUP :              'Группа',
            OBJECT_PASSWORD:            'Пароль объекта',
            OBJECT_PASSWORD_REPEAT:     'Повторите пароль объекта',
            OBJECT_ERROR_PASSWORD:      'Введите пароль объекта',
            OBJECT_ERROR_PASSWORD_REPEAT: 'Повторите пароль объекта',

            GROUPS:                     'Группы',
            GROUPS_LOADING:             'Получение групп...',
            GROUPS_EMPTY:               'Группы не найдены',
            GROUPS_CREATE:              'Новая группа',
            GROUPS_UUID:                'UUID',
            GROUPS_NAME:                'Имя',
            GROUPS_COLOR:               'Цвет группы', 
            GROUPS_COLOR_DESCR:         'Цвет применяется только к иконке автомобиля по умолчанию', 
            GROUPS_ICON:                'Иконка объектов группы', 
            GROUPS_ICON_INCORRECT:      'Не удалось загрузить выбранное изображение', 
            GROUPS_USER:                'Владелец',
            GROUPS_CREATE_SUCCESS:      'Новая группа успешно создана',
            GROUPS_UPDATE_SUCCESS:      'Информация о группе успешно обновлена',
            GROUPS_DELETE:              'Удаление группы',
            GROUPS_DELETE_DESCR:        'Выбранная группа будет удалёна без возможности восстановления',
            GROUPS_DELETE_SUCCESS:      'Выбранная группа была успешно удалена',
            GROUPS_ERROR_NAME:          'Введите имя',
            GROUPS_ERROR_USER:          'Выберите владельца',

            EVENTS:                     'События',
            EVENTS_EMPTY:               'События не найдены',
            EVENTS_TYPE_UNDEFINED:      'Событие без типа',

            EVENT_TYPE_POINT:           'Точка по объекту',

            EVENT_TYPE_EDIT_GROUP:      'Обновление группы',
            EVENT_TYPE_EDIT_GROUPTRACK: 'Обновление треков группы',
            EVENT_TYPE_EDIT_OBJECT:     'Обновление объекта',
            EVENT_TYPE_EDIT_POINT:      'Новая точка',
            EVENT_TYPE_EDIT_TRACK:      'Обновление трека',
            EVENT_TYPE_EDIT_TRACKZONE:  'Обновление зон трека',
            EVENT_TYPE_EDIT_USER:       'Обновление пользователя',
            EVENT_TYPE_EDIT_ZONE:       'Обновление зоны',

            EVENT_TYPE_DELETE_GROUP:      'Удаление группы',
            EVENT_TYPE_DELETE_GROUPTRACK: 'Удаление треков группы',
            EVENT_TYPE_DELETE_OBJECT:     'Удаление объекта',
            EVENT_TYPE_DELETE_POINT:      'Новая точка',
            EVENT_TYPE_DELETE_TRACK:      'Удаление трека',
            EVENT_TYPE_DELETE_TRACKZONE:  'Удаление зон трека',
            EVENT_TYPE_DELETE_USER:       'Удаление пользователя',
            EVENT_TYPE_DELETE_ZONE:       'Удаление зоны',
            EVENT_TYPE_LOWBATTERY:        'Низкий заряд аккумулятора',

            EVENT_HEIGHTSPEED:          'Превышение скорости',
            EVENT_LOWSPEED:             'Скорость ниже допустимой',
            EVENT_EXITED:               'Выезд с трека',
            EVENT_ENTERFORBIDDEN:       'Въезд на запрещённый трек',
            EVENT_ENTERALLOWED:         'Въезд на трек',
            EVENT_STOPFORBIDDEN:        'Остановка в запрещённом месте',
            EVENT_STOPALLOWED:          'Остановка',
            EVENT_LOWBATTERY:           'Низкий заряд батареи',
            EVENT_CHARGEON:             'Зарядка: включено',
            EVENT_CHARGEOFF:            'Зарядка: выключено',
            EVENT_EMERGENCY:            'Авария',
            EVENT_ASSISTANCE:           'Запрос поддержки',
            EVENT_GATEOPEN:             'Шлагбаум открыт',
            EVENT_GATECLOSE:            'Шлагбаум закрыт',
            EVENT_TRACKLOADEXCEEDED:    'Превышение максимального кол-ва автомобилей на треке',
            EVENT_INSUFFICIENTDISTANCE: 'Нарушение дистанции', 

            EVENTS_ALERT_DESC:          'Это важное уведомление. Для продолжения Вы должны подтвердить его просмотр.',

            ZONES:                      'Зоны',
            // ZONES_LOADING:              'Получение зон...',
            // ZONES_CREATE_ERROR_NAME:    'Введите имя зоны',
            // ZONES_CREATE_ERROR_NAME_CNT:'Имя новой зоны должно содержать не более 256 символов',
            ZONES_CREATE_ERROR_TYPE:    'Выберите тип зоны',
            // ZONES_CREATE_ERROR_FIGURE:  'Выберите фигуру зоны',
            // ZONES_CREATE_ERROR_ZINDEX:  'Выберите значение Z-Index',
            // ZONES_CREATE_ERROR_TIME:    'Выберите корректный интервал',
            // ZONES_FIGURE_CIRCLE:        'Окружность',
            // ZONES_FIGURE_LINE:          'Линия',
            // ZONES_FIGURE_POLYGON:       'Полигон',
            // ZONES_FIGURE_SECTOR:        'Сектор',
            ZONES_TYPE_ORDINARY:        'Обычная',
            ZONES_TYPE_GATE:            'Шлагбаум',
            ZONES_TYPE_ROADWORKS:       'Дорожные работы',
            ZONES_TYPE_ACCIDENT:        'Дорожное происшествие',
            ZONES_TYPE_DANGER:          'Опасный перекресток',
            ZONES_TYPE_STOP:            'Остановка',
            ZONES_TYPE_ROAD_CLOSED:     'Перекрытие дороги',
            ZONES_DELETE:               'Удаление зоны',
            ZONES_DELETE_TEXT:          'Эта зона будет удалена без возможности восстановления. Вы уверены, что хотите удалить её?',
            // ZONES_TIP_LIST_DESCR:       'Кликните на зоне для просмотра списка',
            // ZONES_TIP_LIST_DESCR_PAN:   'Коснитесь зоны для просмотра списка',
            
            // ZONES_LIST_EXPAND:          'Показать список',
            // ZONES_LIST_NARROW:          'Скрыть список',
            // ZONES_FORM_EXPAND:          'Показать форму',
            // ZONES_FORM_NARROW:          'Скрыть форму',

            ZONE_NAME:                  'Название зоны',
            ZONE_TYPE:                  'Тип зоны',
            ZONE_FIGURE:                'Фигура',
            // ZONE_BG:                    'Фон',
            // ZONE_STROKE:                'Контур',
            // ZONE_STROKE_ENABLE:         'Включить контур',
            // ZONE_DISPLAY:               'Активность',
            // ZONE_ZINDEX:                'Z-index',
            // ZONE_LINE_DISTANСE:         'Дистанция линии',
            // ZONE_LINE_COLOR:            'Цвет линии',
            // ZONE_OPEN_TIME:             'Время начала',
            // ZONE_CLOSE_TIME:            'Время окончания',

            //Zones2
            ZONE_LIST:                   'Список зон',
            ZONE_CREATE:                 'Создать зону',
            ZONE_EDIT:                   'Редактировать зону',
            ZONE_SEARCH_PLACEHOLDER:     'Поиск по имени, UUID',
            ZONE_SEARCH_MESSAGE:         'Введите минимум 3 символа',
            ZONE_FIGURE_TYPE:            'Тип фигуры',
            ZONE_FIGURE_STROKE:          'Цвет линии',
            ZONE_FIGURE_FILL:            'Цвет заливки',
            ZONE_FIGURE_THICKNESS:       'Толщина линии',
            ZONE_AVAILABILITY:           'Доступность зоны',
            ZONE_DISPLAY:                'Показать на главной',
            ZONE_ENTER_NAME:             'Введите название зоны',
            ZONE_ENTER_ZINDEX:           'Укажите Z-Index зоны',
            ZONE_DRAW_MESSAGE:           'Нарисуйте зону на карте',
            ZONE_TIME_START:             'Время начала',
            ZONE_TIME_END:               'Время окончания',
            ZONE_ISSTROKE:               'Контур',
            ZONES_CREATE_SUCCESS:        'Новая зона успешно создана',
            ZONES_UPDATE_SUCCESS:        'Зона успешно отредактирована',
            ZONES_DELETE_SUCCESS:        'Зона успешно удалена',
            ZONES_TRACKS:                'Треки с зоной',

            TRACKS:                     'Треки',
            TRACKS_LOADING:             'Получение треков...',
            TRACKS_EMPTY:               'Треки не найдены',
            TRACKS_CREATE:              'Создать трек',
            TRACKS_CREATE_SUCCESS:      'Новый трек успешно создан',
            TRACKS_CARS_IN_TRACK:       'Машин на треке',
            TRACKS_NAME:                'Имя',
            TRACKS_AVAILABLE:           'Трек доступен',
            TRACKS_MAX_CARS:            'Максимальное кол-во машин',
            TRACKS_MIN_CARS_INTERVAL:   'Минимальный интервал',
            TRACKS_MAX_SPEED:           'Максимальная скорость',
            TRACKS_MAX_SPEED_OFFSET:    'Погрешность максимальной скорости',
            TRACKS_MIN_SPEED:           'Минимальная скорость',
            TRACKS_MIN_SPEED_OFFSET:    'Погрешность минимальной скорости',
            TRACKS_IS_STOP:             'Разрешена остановка',
            TRACKS_MAX_STOP_TIME:       'Максимальное время стоянки',
            TRACKS_USER:                'Владелец трека',
            TRACKS_DESCR:               'Описание',
            TRACKS_DIRECTION:           'Направление трека',
            TRACKS_DIRECTION_NO:        'Нет',
            TRACKS_DIRECTION_CLOCKWISE: 'По часовой стрелке',
            TRACKS_DIRECTION_COUNTER_CLOCKWISE: 'Против часовой стрелки',
            TRACKS_UPDATE_SUCCESS:      'Информация о треке успешно обновлена',
            TRACKS_DELETE:              'Удаление трека',
            TRACKS_DELETE_DESCR:        'Этот трек будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            TRACKS_DELETE_SUCCESS:      'Трек удалён',

            TRACK:                      'Трек',

            TRACK_CREATE_NAME:          'Название трека',
            TRACK_CREATE_NAME_ERROR:    'Введите название',
            TRACK_CREATE_USER:          'Владелец',
            TRACK_CREATE_USER_ERROR:    'Выберите владельца трека',
            TRACK_CREATE_DIRECTION:     'Направление трека',
            TRACK_CREATE_MAX_CARS:      'Машин на треке (макс.)',
            TRACK_CREATE_MIN_CARS_INTERVAL: 'Минимальный интервал между машинами',
            TRACK_CREATE_MAX_SPEED:     'Максимальная скорость',
            TRACK_CREATE_MAX_SPEED_OFFSET: 'Погрешность максимальной скорости',
            TRACK_CREATE_MIN_SPEED:     'Минимальная скорость',
            TRACK_CREATE_MIN_SPEED_OFFSET: 'Погрешность минимальной скорости',
            TRACK_CREATE_IS_STOP:       'Разрешена остановка',
            TRACK_CREATE_MAX_STOP_TIME: 'Время остановки (макс.)',

            TRACK_GROUPS:               'Группы трека',
            TRACK_GROUPS_LOADING:       'Получение групп...',
            TRACK_GROUPS_CREATE:        'Добавление группы в трек',
            TRACK_GROUPS_CREATE_SUCCESS:'Группа получила доступ к выбранному треку',
            TRACK_GROUPS_DELETE:        'Удаление группы из трека',
            TRACK_GROUPS_DELETE_TEXT:   'Эта группа потеряет доступ к выбранному треку. Вы уверены, что хотите сделать это?',
            TRACK_GROUPS_DELETE_SUCCESS:'Группа потеряла доступ к треку',
            TRACK_GROUPS_SELECT:        'Выберите группу',
            TRACK_GROUPS_SELECT_ERROR:  'Не выбранна группа',

            TRACK_ZONES_LIST:                'Зоны трека',
            TRACK_ZONES_LIST_LOADING:        'Получение зон...',
            TRACK_ZONES_LIST_CREATE:         'Добавление зоны в трек', 
            TRACK_ZONES_LIST_CREATE_SUCCESS: 'Зона успешно добавлена в трек', 
            TRACK_ZONES_LIST_UPDATE_SUCCESS: 'Зона трека успешно обновлена', 
            TRACK_ZONES_LIST_DELETE:         'Удаление зоны из трека',
            TRACK_ZONES_LIST_DELETE_TEXT:    'Эта зона будет удалена из трека? Вы уверены, что хотите удалить её',
            TRACK_ZONES_LIST_DELETE_SUCCESS: 'Зона успешно удалена из трека',
            TRACK_ZONES_LIST_TYPE_MAIN:      'Зона-обёртка',
            TRACK_ZONES_LIST_TYPE_PARK:      'Парковка',
            TRACK_ZONES_LIST_TYPE_SPD:       'Ограничение скорости',
            TRACK_ZONES_LIST_TYPE_GATE:      'Шлагбаум',
            TRACK_ZONES_LIST_TYPE_CLOSE:     'Запрещённая зона', 
            TRACK_ZONES_LIST_TYPE_ERROR:     'Не выбран тип зоны',
            TRACK_ZONES_LIST_TYPE:           'Тип зоны в треке',
            TRACK_ZONES_LIST_AVAILABLE:      'Активная',
            TRACK_ZONES_LIST_SELECT:         'Выберите зону',
            TRACK_ZONES_LIST_SELECT_ERROR:   'Не выбрана зона',
            TRACK_ZONES_LIST_MIN_SPEED:      'Минимальная скорость',
            TRACK_ZONES_LIST_MIN_SPEED_ERROR:'Не указана минимальная скорость',
            TRACK_ZONES_LIST_MAX_SPEED:      'Максимальная скорость',
            TRACK_ZONES_LIST_MAX_SPEED_ERROR:'Не указана максимальная скорость',
            
            REPORTS_TYPE:               'Тип отчёта',
            REPORTS_TYPE_EVENTS:        'События',
            REPORTS_TYPE_TRACKS:        'Треки',
            REPORTS_TYPE_GROUPS:        'Группы',
            REPORTS_EVENTS_TYPES:       'Типы событий',
            REPORTS_OBJECTS:            'Объекты',
            REPORTS_TRACKS:             'Треки',
            REPORTS_GROUPS:             'Группы',
            REPORTS_START:              'Дата, время [начало]',
            REPORTS_STOP:               'Дата, время [конец]',
            REPORTS_PLACE:              'Местоположение',

            ERROR_AUTH_LOGIN:           'Введите логин',
            ERROR_AUTH_PASS:            'Введите пароль',

            EUserPermissionDenied:      'Введён неверный логин или пароль',
            EZoneNotFound:              'Нет доступных зон',
            EObjectNotFound:            'Нет доступных объектов',
            ETrackNotFound:             'Нет доступных треков',
            EConnection:                'Возможно, нет доступа к Интернет. Обновите страницу.',
            EUnknown:                   'Произошла системная ошибка [#1]; пожалуйста, свяжитесь с системным администратором',
            EPointNotFound:             'События не найдены',
            EUnauthRequest:             'Для этого запроса необходимо авторизоваться в приложении',
            EWSDisconnected:            'Ошибка при попытке подключения к серверу WebSocket',
            EGroupNotFound:             'Группы не найдены', 
            EGroupTrackNotFound:        'Нет связей трек-группа',
            ETrackZoneNotFound:         'Нет связей трек-зона',
            EEventNotFound:             'События не найдены',
            EPassword:                  'Введён невалидный пароль',
            EImei:                      'Введён невалидный IMEI',
            EFormFields:                'Ошибка заполнения формы'
        },

        EN: {

            YES:                        'Yes',
            NO:                         'No',

            UNIT_KMH:                   'km/h',

            ACCESS_READ:                'Read',
            ACCESS_READ_WRITE:          'Read and write',
            ACCESS_READ_WRITE_DELETE:   'Read, write and delete',
            ACCESS_NO:                  'No rights',

            EVENTS_SETTINGS:            'Settings',
            EVENTS_SETTINGS_DESCR:      'Select the type of event to configure notifications for it',
            EVENTS_SETTINGS_TYPE:       'Event type',
            EVENTS_SETTINGS_DISABLE:    'Disable',
            EVENTS_SETTINGS_MOBILE:     'Mobile',
            EVENTS_SETTINGS_WEB:        'Web',
            EVENTS_SETTINGS_BOTH:       'Everywhere',
            EVENTS_SETTINGS_INFORM:     'Inform',
            EVENTS_SETTINGS_WARNING:    'Warning',
            EVENTS_SETTINGS_ALERT:      'Alert',
            EVENTS_SETTINGS_AUDIO:      'Sound',
            EVENTS_SETTINGS_TOAST:      'Toast',
            EVENTS_SETTINGS_VIBRATE:    'Vibrate',
            EVENTS_SETTINGS_CONFIRM:    'Need confirm',
            EVENTS_SETTINGS_COLOR:      'Color',
            EVENTS_SETTINGS_LEVEL:      'Level',

            PAGE_AUTH:                  'Authorization',
            PAGE_DASHBOARD:             'Dashboard',
            PAGE_EVENTS:                'Events',
            PAGE_PROFILE:               'Profile',
            PAGE_USERS:                 'Users',
            PAGE_USER:                  'User',
            PAGE_ZONES:                 'Zones',
            PAGE_ZONE:                  'Zone',
            PAGE_ZONE_CREATE:           'New Zone',
            PAGE_GROUPS:                'Groups',
            PAGE_GROUP:                 'Group',
            PAGE_OBJECTS:               'Objects',
            PAGE_OBJECT:                'Object',
            PAGE_TRACKS:                'Tracks',
            PAGE_TRACK:                 'Track',
            PAGE_TEST:                  'Test form',
            PAGE_REPORTS:               'Reports',

            DASHBOARD_MAP_POPUP_OBJECT: 'Object',
            DASHBOARD_MAP_POPUP_SPY:    'Focused',

            // Buttons text
            BTN_ACCEPT:                 'Accept',
            BTN_ADD:                    'Add',
            BTN_CREATE:                 'Create',
            BTN_NEW:                    'New',
            BTN_EDIT:                   'Edit',
            BTN_SAVE:                   'Save',
            BTN_CANCEL:                 'Cancel',
            BTN_DELETE:                 'Delete',
            BTN_CTRL:                   'Control',
            BTN_REQUEST:                'Request',
            BTN_SHOW:                   'Show',
            BTN_UPLOAD:                 'Upload',
            BTN_DOWNLOAD:               'Download',
            BTN_CLOSE:                  'Close',
            BTN_SELECT:                 'Select',
            BTN_LOGIN:                  'Log-in',
            BTN_LOGOUT:                 'Logout',
            BTN_GOOD:                   'Yes',
            BTN_THINK_MORE:             'Think more',
            BTN_HIDE:                   'Hide',
            BTN_CHANGE:                 'Change',

            SEARCH:                     'Search',
            SEARCH_PLACEHOLDER:         'Search...',

            PAGINATION_PAGE:            'Page',
            PAGINATION_PREV:            'Prev',
            PAGINATION_NEXT:            'Next',

            USER_TYPE_NO_TYPE:          'No group',
            USER_TYPE_OPERATOR:         'Operator',
            USER_TYPE_ADMINISTRATOR:    'Administrator',

            LAT:                        'Latitude',
            LON:                        'Longitude',

            LOGIN:                      'Login',
            PASSWORD:                   'Password',
            TYPE:                       'Group',
            ACCESS:                     'Access',

            PROFILE_HELLO:              'Hello',

            FIELDS_VALUE_NOT_SET:       'Not set', 

            USER_CREATE:                'Create',
            USER_NEW:                   'New user',
            USER_LOGIN:                 'Name',
            USER_UUID:                  'UUID',
            USER_EMAIL:                 'E-mail',
            USER_PASS:                  'Password',
            USER_ACCESS:                'Rights',
            USER_TYPE:                  'Group',
            USER_PHONE:                 'Phone',
            USER_UPDATE_SUCCESS:        'User profile updated',
            USER_DELETE:                'User deleting',
            USER_DELETE_TEXT:           'This user will be deleted without possibility of restoration. Are you sure you want to delete it?',
            USER_DELETE_SUCCESS:        'User deleted',

            USERS:                      'Users',
            USERS_LOADING:              'Users loading...',
            USERS_EMPTY:                'Users empty',
            USERS_CREATE:               'Create user',
            USERS_CREATE_LOGIN:         'Login',
            USERS_CREATE_PASS:          'Password',
            USERS_CREATE_TYPE:          'Type',
            USERS_CREATE_ACCESS:        'Access',
            USERS_CREATE_SUCCESS:       'New user added',
            USERS_CREATE_ERROR_LOGIN:   'Incorrect login',
            USERS_CREATE_ERROR_PASS:    'Incorrect password',
            USERS_CREATE_ERROR_TYPE:    'User group not specified',
            USERS_CREATE_ERROR_ACCESS:  'Access level not specified',
            USERS_DELETE:               'Delete user',

            OBJECTS:                    'Objects',
            OBJECTS_LOADING:            'Objects loading...',
            OBJECTS_EMPTY:              'Objects not found',
            OBJECTS_CREATE:             'Create object',
            OBJECTS_NAME:               'Name',
            OBJECTS_IMEI:               'IMEI',
            OBJECTS_GROUP:              'Group',
            OBJECTS_USER:               'User',
            OBJECTS_ERROR_NAME:         'Enter a name',
            OBJECTS_ERROR_IMEI:         'Enter the IMEI',
            OBJECTS_ERROR_IMEI_FILTER:  'The IMEI must contain 15 characters',
            OBJECTS_ERROR_GROUP:        'Select a group',
            OBJECTS_ERROR_USER:         'Select a user',
            OBJECTS_PASSWORD:           'Password',
            OBJECTS_PASSWORD_REPEAT:    'Repeat password',
            OBJECTS_ERROR_PASSWORD:     'Enter object password',
            OBJECTS_ERROR_PASSWORD_REPEAT: 'Repeat object password',
            OBJECTS_CREATE_SUCCESS:     'New object added successfully',
            OBJECTS_UPDATE_SUCCESS:     'The object was successfully updated',
            OBJECTS_DELETE:             'Delete object',
            OBJECTS_DELETE_SUCCESS:     'The object was successfully deleted',

            OBJECT_UUID :               'UUID',
            OBJECT_NAME :               'Name',
            OBJECT_IMEI :               'IMEI',
            OBJECT_USER :               'Owner',
            OBJECT_GROUP :              'Group',
            OBJECT_PASSWORD:            'Object password',
            OBJECT_PASSWORD_REPEAT:     'Repeat object password',
            OBJECT_ERROR_PASSWORD:      'Enter object password',
            OBJECT_ERROR_PASSWORD_REPEAT: 'Repeat object password',

            GROUPS:                     'Groups',
            GROUPS_LOADING:             'Groups loading...',
            GROUPS_EMPTY:               'Groups not found',
            GROUPS_CREATE:              'New group',
            GROUPS_NAME:                'Name',
            GROUPS_UUID:                'UUID',
            GROUPS_COLOR:               'Group color', 
            GROUPS_COLOR_DESCR:         'The color only applies to the default car icon', 
            GROUPS_ICON:                'Group objects icon', 
            GROUPS_ICON_INCORRECT:      'Failed to load selected image', 
            GROUPS_USER:                'Owner',
            GROUPS_CREATE_SUCCESS:      'A new group has been created successfully',
            GROUPS_UPDATE_SUCCESS:      'The group has been successfully updated',
            GROUPS_DELETE:              'Group delete',
            GROUPS_DELETE_DESCR:        'This group will be deleted without possibility of restoration. Are you sure you want to delete it?',
            GROUPS_DELETE_SUCCESS:      'The group has been successfully deleted',
            GROUPS_ERROR_NAME:          'Enter a name',
            GROUPS_ERROR_USER:          'Select the owner',

            EVENTS:                     'Events',
            EVENTS_EMPTY:               'Events not found',
            EVENTS_TYPE_UNDEFINED:      'Event without type',
            
            EVENT_TYPE_POINT:           'Point for object',

            EVENT_TYPE_EDIT_GROUP:      'Group updated',
            EVENT_TYPE_EDIT_GROUPTRACK: 'Group tracks updated',
            EVENT_TYPE_EDIT_OBJECT:     'Object updated',
            EVENT_TYPE_EDIT_POINT:      'Point updated',
            EVENT_TYPE_EDIT_TRACK:      'Track updated',
            EVENT_TYPE_EDIT_TRACKZONE:  'Track zones updated',
            EVENT_TYPE_EDIT_USER:       'User updated',
            EVENT_TYPE_EDIT_ZONE:       'Zone updated',

            EVENT_TYPE_DELETE_GROUP:      'Group deleted',
            EVENT_TYPE_DELETE_GROUPTRACK: 'Group tracks deleted',
            EVENT_TYPE_DELETE_OBJECT:     'Object deleted',
            EVENT_TYPE_DELETE_POINT:      'Point deleted',
            EVENT_TYPE_DELETE_TRACK:      'Track deleted',
            EVENT_TYPE_DELETE_TRACKZONE:  'Track zones deleted',
            EVENT_TYPE_DELETE_USER:       'User deleted',
            EVENT_TYPE_DELETE_ZONE:       'Zone deleted',
            EVENT_TYPE_LOWBATTERY:        'Low battery',

            EVENT_HIGHSPEED:            'High speed',
            EVENT_LOWSPEED:             'Low speed',
            EVENT_EXITED:               'Exited from track',
            EVENT_ENTERFORBIDDEN:       'Enter on forbidden track',
            EVENT_ENTERALLOWED:         'Enter on allowed track',
            EVENT_STOPFORBIDDEN:        'Stopped at a forbidden place',
            EVENT_STOPALLOWED:          'Stopped',
            EVENT_LOWBATTERY:           'Low battery',
            EVENT_CHARGEON:             'Charge ON',
            EVENT_CHARGEOFF:            'Charge OFF',
            EVENT_EMERGENCY:            'Emergency',
            EVENT_ASSISTANCE:           'Need assistance',
            EVENT_GATEOPEN:             'Gate open',
            EVENT_GATECLOSE:            'Gate close',
            EVENT_TRACKLOADEXCEEDED:    'Exceeding the maximum number of cars on the track',
            EVENT_INSUFFICIENTDISTANCE: 'Insufficient Distance', 

            EVENTS_ALERT_DESC:          'This is an important notification. To continue, you must confirm viewing it.',

            ZONES:                      'Zones',
            // ZONES_LOADING:              'Zones loading...',
            // ZONES_EMPTY:                'Zones empty',
            // ZONES_CREATE_ERROR_NAME:    'Enter the name of the zone',
            // ZONES_CREATE_ERROR_NAME_CNT:'The name of the new zone must contain no more than 256 characters',
            ZONES_CREATE_ERROR_TYPE:    'Select zone type',
            // ZONES_CREATE_ERROR_FIGURE:  'Select zone figure',
            // ZONES_CREATE_ERROR_ZINDEX:  'Select the Z-Index value',
            // ZONES_CREATE_ERROR_TIME:    'Select correct time',
            // ZONES_FIGURE_CIRCLE:        'Circle',
            // ZONES_FIGURE_LINE:          'Line',
            // ZONES_FIGURE_POLYGON:       'Polygon',
            // ZONES_FIGURE_SECTOR:        'Sector',
            ZONES_TYPE_ORDINARY:        'Ordinary',
            ZONES_TYPE_GATE:            'Gate',
            ZONES_TYPE_ROADWORKS:       'Road works',
            ZONES_TYPE_ACCIDENT:        'Traffic accident',
            ZONES_TYPE_DANGER:          'Dangerous intersection',
            ZONES_TYPE_STOP:            'Stop',
            ZONES_TYPE_ROAD_CLOSED:     'Road closed',
            ZONES_DELETE:               'Zone delete',
            ZONES_DELETE_TEXT:          'This zone will be deleted without possibility of restoration. Are you sure you want to delete it?',
            // ZONES_TIP_LIST_DESCR:       'Click the zone to watch the list',
            // ZONES_TIP_LIST_DESCR_PAN:   'Touch the zone to watch the list',

            // ZONES_LIST_EXPAND:          'Show list',
            // ZONES_LIST_NARROW:          'Hide list',
            // ZONES_FORM_EXPAND:          'Show form',
            // ZONES_FORM_NARROW:          'Hide form',

            ZONE_NAME:                  'Zone name',
            ZONE_TYPE:                  'Zone type',
            ZONE_FIGURE:                'Figure',
            // ZONE_BG:                    'Background',
            // ZONE_STROKE:                'Stroke',
            // ZONE_STROKE_ENABLE:         'Stroke enable',
            // ZONE_DISPLAY:               'Activity',
            // ZONE_ZINDEX:                'Z-index',
            // ZONE_LINE_DISTANСE:         'Line distanсe',
            // ZONE_LINE_COLOR:            'Line color',
            // ZONE_OPEN_TIME:             'Time starts',
            // ZONE_CLOSE_TIME:            'Time ends',

            //Zones2
            ZONE_LIST:                   'List of zones',
            ZONE_CREATE:                 'Create zone',
            ZONE_EDIT:                   'Edit zone',
            ZONE_SEARCH_PLACEHOLDER:     'Search by name, UUID',
            ZONE_SEARCH_MESSAGE:         'Enter at least 3 symbols',
            ZONE_FIGURE_TYPE:            'Figure type',
            ZONE_FIGURE_STROKE:          'Line color',
            ZONE_FIGURE_FILL:            'Fill color',
            ZONE_FIGURE_THICKNESS:       'Thickness',
            ZONE_AVAILABILITY:           'Zone availability',
            ZONE_DISPLAY:                'Show on main page',
            ZONE_ENTER_NAME:             'Enter zone name',
            ZONE_ENTER_ZINDEX:           'Enter Z-index zone',
            ZONE_DRAW_MESSAGE:           'Draw zone on map',
            ZONE_TIME_START:             'Start time',
            ZONE_TIME_END:               'End time',
            ZONE_ISSTROKE:               'Zone outline',
            ZONES_CREATE_SUCCESS:        'New zone added successfully',
            ZONES_UPDATE_SUCCESS:        'The zone has been successfully updated',
            ZONES_DELETE_SUCCESS:        'Zone successfully deleted',
            ZONES_TRACKS:                'Tracks of zone',
            

            TRACKS:                     'Tracks',
            TRACKS_LOADING:             'Tracks loading...',
            TRACKS_EMPTY:               'Tracks not found',
            TRACKS_CREATE:              'Create track',
            TRACKS_CREATE_SUCCESS:      'A new track has been created successfully',
            TRACKS_CARS_IN_TRACK:       'Cars on track',
            TRACKS_NAME:                'Name',
            TRACKS_AVAILABLE:           'Track available',
            TRACKS_MAX_CARS:            'Max cars',
            TRACKS_MIN_CARS_INTERVAL:   'Min cars interval',
            TRACKS_MAX_SPEED:           'Max speed',
            TRACKS_MAX_SPEED_OFFSET:    'Max speed offset',
            TRACKS_MIN_SPEED:           'Min speed',
            TRACKS_MIN_SPEED_OFFSET:    'Min speed offset',
            TRACKS_IS_STOP:             'Allowed to stop',
            TRACKS_MAX_STOP_TIME:       'Max stop time',
            TRACKS_USER:                'Track owner',
            TRACKS_DESCR:               'Description',
            TRACKS_DIRECTION:           'Direction',
            TRACKS_DIRECTION_NO:        'No',
            TRACKS_DIRECTION_CLOCKWISE: 'Clockwise',
            TRACKS_DIRECTION_COUNTER_CLOCKWISE: 'Counter clockwise',
            TRACKS_UPDATE_SUCCESS:      'The track has been successfully updated',
            TRACKS_DELETE:              'Track delete',
            TRACKS_DELETE_DESCR:        'This track will be deleted without possibility of restoration. Are you sure you want to delete it?',
            TRACKS_DELETE_SUCCESS:      'Track successfully deleted',

            TRACK:                      'Track',

            TRACK_CREATE_NAME:          'Track name',
            TRACK_CREATE_NAME_ERROR:    'Enter track name',
            TRACK_CREATE_USER:          'Owner',
            TRACK_CREATE_USER_ERROR:    'Choose track owner',
            TRACK_CREATE_DIRECTION:     'Direction',
            TRACK_CREATE_MAX_CARS:      'Max cars',
            TRACK_CREATE_MIN_CARS_INTERVAL: 'Minimal interval between cars',
            TRACK_CREATE_MAX_SPEED:     'Max speed',
            TRACK_CREATE_MAX_SPEED_OFFSET: 'Max speed offset',
            TRACK_CREATE_MIN_SPEED:     'Min speed',
            TRACK_CREATE_MIN_SPEED_OFFSET: 'Min speed offset',
            TRACK_CREATE_IS_STOP:       'Choose track direction',
            TRACK_CREATE_MAX_STOP_TIME: 'Stop time [max]',

            TRACK_GROUPS:               'Track groups',
            TRACK_GROUPS_LOADING:       'Groups loading...',
            TRACK_GROUPS_CREATE:        'Adding a group to a track',
            TRACK_GROUPS_CREATE_SUCCESS:'The group has access to the selected track',
            TRACK_GROUPS_DELETE:        'Removing a group from a track',
            TRACK_GROUPS_DELETE_TEXT:   'This group will lose access to the selected track. Are you sure you want to do this?',
            TRACK_GROUPS_DELETE_SUCCESS:'The group has lost access to the track',

            TRACK_ZONES_LIST:                'Track zones',
            TRACK_ZONES_LOADING:             'Zones loading...',
            TRACK_ZONES_CREATE:              'Adding a zone to a track', 
            TRACK_ZONES_LIST_CREATE_SUCCESS: 'Track successfully added in selected zone',
            TRACK_ZONES_LIST_UPDATE_SUCCESS: 'Track zone successfuly updated', 
            TRACK_ZONES_LIST_DELETE:         'Deleting a track from a group',
            TRACK_ZONES_LIST_DELETE_TEXT:    'Will the track be removed from this zone from track? Have you thought well?',
            TRACK_ZONES_LIST_DELETE_SUCCESS: 'Zone successfully deleted from track',
            TRACK_ZONES_LIST_TYPE_MAIN:      'Container zone',
            TRACK_ZONES_LIST_TYPE_PARK:      'Parking',
            TRACK_ZONES_LIST_TYPE_SPD:       'Speed control',
            TRACK_ZONES_LIST_TYPE_GATE:      'Gate',
            TRACK_ZONES_LIST_TYPE_CLOSE:     'Restricted area', 
            TRACK_ZONES_LIST_TYPE_ERROR:     'Zone type not selected',
            TRACK_ZONES_LIST_TYPE:           'Track zone type',
            TRACK_ZONES_LIST_AVAILABLE:      'Active',
            TRACK_ZONES_LIST_SELECT:         'Select zone',
            TRACK_ZONES_LIST_SELECT_ERROR:   'No zone selected',
            TRACK_ZONES_LIST_MIN_SPEED:      'Minimum speed',
            TRACK_ZONES_LIST_MIN_SPEED_ERROR:'Minimum speed not specified',
            TRACK_ZONES_LIST_MAX_SPEED:      'Maximum speed',
            TRACK_ZONES_LIST_MAX_SPEED_ERROR:'Maximum speed not specified',

            REPORTS_TYPE:               'Report type',
            REPORTS_TYPE_EVENTS:        'Events',
            REPORTS_TYPE_TRACKS:        'Tracks',
            REPORTS_TYPE_GROUPS:        'Groups',
            REPORTS_EVENTS_TYPES:       'Events types',
            REPORTS_START:              'Date, time [from]',
            REPORTS_STOP:               'Date, time [till]',
            REPORTS_PLACE:              'Location',

            ERROR_AUTH_LOGIN:           'Enter login',
            ERROR_AUTH_PASS:            'Enter password',

            EUserPermissionDenied:      'Invalid login or password entered',
            EZoneNotFound:              'No zones available',
            EObjectNotFound:            'No objects available',
            ETrackNotFound:             'No tracks available',
            EConnection:                'There may be no internet access. Refresh the page.',
            EUnknown:                   'A system error has occurred [#1]; please contact the system administrator',
            EPointNotFound:             'Events not found',
            EUnauthRequest:             'For this request, you need to log in to the application.',
            EWSDisconnected:            'Error when trying to connect to WebSocket server', 
            EGroupNotFound:             'Groups not found', 
            EGroupTrackNotFound:        'No track-group links',
            ETrackZoneNotFound:         'No track-zone links',
            EEventNotFound:             'Events not found',
            EPassword:                  'Invalid password entered',
            EImei:                      'Invalid IMEI entered',
            EFormFields:                'Form fields error'
        }
    },

    getters: {

        getTranslate: (state, getters) => state[getters.getLang]
    }
}