<template>
    <div class="page page--zones">
        <div class="tms-zones">
            <TMSZonesMap mode="read" />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import TMSZonesMap from "@/components/TMSZonesMap"

export default {
    name: "ViewZones",

    components: {
        TMSZonesMap
    },

    methods: {
        ...mapActions([
            
            "readZones"
        ])
    },

    async created() {
        await this.readZones({
            payload: { "c.limit": 15 },
            isUseStore: true
        })
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/scss/components/zones.scss";
</style>
