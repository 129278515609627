<template>

    <div class="tms-tracks-create">

        <div
            v-if="'success' in trackCreateMsg && !trackCreateMsg.success"
            class="my-4 alert alert--danger tms-tracks-create__alert"
        >
            {{ trackCreateMsg.text }}
        </div>

        <form class="tms-tracks-create__form" @submit.prevent="submitForm($event)">

            <div class="row">
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackName" 
                        v-model="TMSTrackName"
                        :placeholder="getTranslate.TRACK_CREATE_NAME"
                        @input="clearError('TMSTrackName')"
                        class="my-3 input input--block"
                        :class="{ 'is-invalid' : formInvalids.TMSTrackName }"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <select
                        id="TMSTrackDirection" 
                        v-model="TMSTrackDirection"
                        :placeholder="getTranslate.TRACK_CREATE_DIRECTION"
                        class="my-3 input input--block"
                    >
                        <option value="null" selected>...</option>
                        <option v-for="(direction, index) in trackDirections" :key="index" :value="direction.value">{{ direction.name }}</option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackMaxspeed" 
                        v-model="TMSTrackMaxspeed"
                        :placeholder="getTranslate.TRACK_CREATE_MAX_SPEED" 
                        class="my-3 input input--block"
                        type="number"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackMaxspeedoffset" 
                        v-model="TMSTrackMaxspeedoffset"
                        :placeholder="getTranslate.TRACK_CREATE_MAX_SPEED_OFFSET" 
                        class="my-3 input input--block"
                        type="number"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackMinspeed" 
                        v-model="TMSTrackMinspeed"
                        :placeholder="getTranslate.TRACK_CREATE_MIN_SPEED" 
                        class="my-3 input input--block"
                        type="number"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackMinspeedoffset" 
                        v-model="TMSTrackMinspeedoffset"
                        :placeholder="getTranslate.TRACK_CREATE_MIN_SPEED_OFFSET" 
                        class="my-3 input input--block"
                        type="number"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackMaxcars" 
                        v-model="TMSTrackMaxcars"
                        :placeholder="getTranslate.TRACK_CREATE_MAX_CARS" 
                        class="my-3 input input--block"
                        type="number"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackMincarsinterval" 
                        v-model="TMSTrackMincarsinterval"
                        :placeholder="getTranslate.TRACK_CREATE_MIN_CARS_INTERVAL" 
                        class="my-3 input input--block"
                        type="number"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackIsstop"
                        v-model="TMSTrackIsstop"
                        type="checkbox"
                        class="my-2"
                    />
                    <label for="TMSTrackIsstop">{{ getTranslate.TRACK_CREATE_IS_STOP }}</label>
                </div>
                <div class="col-12 col-md-6">
                    <input
                        id="TMSTrackMaxstoptime" 
                        v-model="TMSTrackMaxstoptime"
                        :placeholder="getTranslate.TRACK_CREATE_MAX_STOP_TIME" 
                        class="my-3 input input--block"
                        type="number"
                    />
                </div>
            </div>

            <div class="mt-3 btns btns--right tms-tracks-create__btns">
                <button
                    type="button"
                    class="btn"
                    @click="$emit('closeModal', true)"
                >
                    {{ getTranslate.BTN_CANCEL }}
                </button>
                <button
                    class="btn btn--primary"
                    type="submit"
                >
                    <template v-if="trackCreateProcess">
                        <div class="spinner-border" role="status"></div>
                    </template>
                    <template v-else>
                        {{ getTranslate.BTN_CREATE }}
                    </template>
                </button>
            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSGroupsCreate',

    data() {

        return {

            TMSTrackName:           null,
            TMSTrackDirection:      null,
            TMSTrackMaxcars:        null,
            TMSTrackMincarsinterval:null,
            TMSTrackMaxspeed:       null,
            TMSTrackMaxspeedoffset: null,
            TMSTrackMinspeed:       null,
            TMSTrackMinspeedoffset: null,
            TMSTrackMaxstoptime:    null,
            TMSTrackIsstop:         null,

            formInvalids: {},
            valid: false
        }
    },

    computed: {

        ...mapState({

            tracks:             state => state.tracks.tracks,
            trackCreateProcess: state => state.tracks.trackCreateProcess,
            trackCreateMsg:     state => state.tracks.trackCreateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        trackDirections() {

            return [

                { name: this.getTranslate.TRACKS_DIRECTION_NO,                  value: 'no'                 },
                { name: this.getTranslate.TRACKS_DIRECTION_CLOCKWISE,           value: 'clockwise'          },
                { name: this.getTranslate.TRACKS_DIRECTION_COUNTER_CLOCKWISE,   value: 'counter_clockwise ' }
            ]
        },
    },

    methods: {

        ...mapActions([

            'createTrack'
        ]),

        ...mapMutations([

            'setCreateTrackMsg'
        ]),

        clearError(field_id) {

            if(this.formInvalids[field_id]) {

                delete this.formInvalids[field_id]
            }
        },

        submitForm() {

            let data = { options: {} }

            this.formInvalids = {}

            if(this.TMSTrackName) {

                data.name = this.TMSTrackName
                this.valid = true

            } else {

                this.formInvalids['TMSTrackName'] = true
                this.valid = false
            }

            if(this.TMSTrackDirection)
                data.options.direction = this.TMSTrackDirection

            if(this.TMSTrackIsstop) 
                data.options.is_stop = this.TMSTrackIsstop

            if(this.TMSTrackMaxcars) 
                data.options.max_cars = this.TMSTrackMaxcars

            if(this.TMSTrackMaxspeed) 
                data.options.max_speed = this.TMSTrackMaxspeed

            if(this.TMSTrackMaxspeedoffset) 
                data.options.max_speed_offset = this.TMSTrackMaxspeedoffset

            if(this.TMSTrackMinspeed) 
                data.options.min_speed = this.TMSTrackMinspeed

            if(this.TMSTrackMinspeedoffset) 
                data.options.min_speed_offset = this.TMSTrackMinspeedoffset

            if(this.TMSTrackMaxstoptime) 
                data.options.max_stop_time = this.TMSTrackMaxstoptime

            if(this.TMSTrackMincarsinterval) 
                data.options.min_cars_interval = this.TMSTrackMincarsinterval

            if(this.valid) {

                this.createTrack(data)
            }
        }
    },

    mounted() {

        this.setCreateTrackMsg({})
    },

    watch: {

        trackCreateMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal', false)
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>