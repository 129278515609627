export default {

    state: {

        profile: {},
        profileProcess: false,
        profileMsg: {},

        profileUpdateProcess: false,
        profileUpdateMsg: {}
    },

    actions: {

        /**
         * Получает профиль пользователя
         */
        async readProfile({state, getters, dispatch}) {

            const token = getters.getToken

            if(token) {

                state.profileProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/users/${localStorage.getItem('TMS_UUID')}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.profile = request

                } else {

                    state.profileMsg = { success: false }
                    state.profileMsg.text = getters.getTranslate[request.error] || request.error
                }
            
                state.profileProcess = false
                
            } else {

                state.profileMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет профиль пользователя
         * 
         * @param {Object} payload - поля формы редактирования профиля пользователя
         */
        async updateProfile({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.profileUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/users`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.profile = request
                    state.profileUpdateMsg = { success: true, text: getters.getTranslate.USER_UPDATE_SUCCESS }

                } else {

                    state.profileUpdateMsg = { success: false }
                    state.profileUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.profileUpdateProcess = false

            } else {

                state.profileUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setUpdateProfileMsg: (state, data) => state.profileUpdateMsg = data
    }
}