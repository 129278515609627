<template>

    <div class="page page--objects">

        <h1 class="page__header">{{ getTranslate.PAGE_GROUPS }}</h1>

        <div class="page__content">
            <TMSGroups />
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import TMSGroups from '@/components/TMSGroups'

export default {

    name: 'ViewGroups',

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    components: {

        TMSGroups
    }
}
</script>