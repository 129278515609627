<template>

    <div class="page page--draw">

        <h1 class="page__header">Тест рисования зон</h1>

        <div class="page__content">
            <LeafletDraw />
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import LeafletDraw from '@/components/LeafletDraw'

export default {

    name: 'ViewDraw',

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    components: {

        LeafletDraw
    }
}
</script>