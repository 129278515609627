<template>

    <div class="page page--track">

        <h1 class="page__header">{{ pageHeader || getTranslate.PAGE_TRACKS }}</h1>

        <div class="page__content">

            <TMSTrack :trackUuid="trackUuid" />
            <TMSTrackGroups :trackUuid="trackUuid" />

            <div class="d-block d-md-flex">
                <div class="w100">
                    <TMSTrackZonesMap :trackUuid="trackUuid" />
                </div>
                <div class="w100">
                    <TMSTrackZonesList :trackUuid="trackUuid" />
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import TMSTrack             from '@/components/TMSTrack'
import TMSTrackGroups       from '@/components/TMSTrackGroups'
import TMSTrackZonesList    from '@/components/TMSTrackZonesList'
import TMSTrackZonesMap     from '@/components/TMSTrackZonesMap'

export default {

    name: 'ViewTrack',

    props: {

        trackUuid: {

            required: true,
            type: String
        }
    },

    methods: {

        ...mapActions([

            'readTrackZones'
        ]),

        ...mapMutations([

            'setPageHeader'
        ])
    },

    computed: {

        ...mapState({

            pageHeader: state => state.pageOptions.pageHeader,
            trackZones: state => state.trackZones.trackZones,
        }),

        ...mapGetters([

            'getTranslate'
        ])    
    },

    components: {

        TMSTrack,
        TMSTrackGroups,
        TMSTrackZonesList,
        TMSTrackZonesMap
    },

    beforeUnmount() {

        this.setPageHeader(null)
    }
}
</script>