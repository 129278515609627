export default {

    state: {

        trackZonesMap:          {},
        trackZonesMapProcess:   false,
        trackZonesMapMsg:       {}
    },

    actions: {

        /**
         * Получает зоны трека для отображения на карте
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readTrackZonesMap({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token
            let result = {}

            if(token) {

                state.trackZonesMapProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                // Запрашиваем связи трек-зона
                const requestTrackZonesMap = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/tracks_zones${params}`, 
                    method: 'GET', 
                    token
                })

                if(!requestTrackZonesMap.error) {

                    let params = ''

                    let trackZonesMap = {}
                    let trackZonesMapUuid = []
                    
                    // Формируем объект с зонами трека { UUID_зоны_трека: свойства_сзоны_трека }
                    // Получаем массив UUID зон трека из связок
                    requestTrackZonesMap.array.map( zone => {

                        trackZonesMap[zone.zoneUuid] = zone
                        trackZonesMapUuid.push(zone.zoneUuid) 
                    })

                    

                    for( let uuid of trackZonesMapUuid) {

                        params.length ?
                            params += '&uuid=' + uuid :
                            params = '?uuid=' + uuid
                    }

                    params.length ? 
                        params += '&c.limit=1000' : 
                        params += '?c.limit=1000'

                    // Запрашиваем зоны трека
                    const requestZones = await dispatch('fetchRequest', {
                        address: `${rootState.config.httpServer}/zones${params}`, 
                        method: 'GET', 
                        token
                    })

                    if(!requestZones.error) {

                        requestZones.array.map(zone => {

                            if(trackZonesMap[zone.uuid]) {

                                trackZonesMap[zone.uuid].zone = zone
                            }
                        })

                        state.trackZonesMap = { array: trackZonesMap, c: requestTrackZonesMap.c }
                        state.trackZonesMapMsg = { success: true }

                        result = state.trackZonesMap

                    } else {

                        state.trackZonesMapMsg = { success: false, text: getters.getTranslate[requestZones.error] || requestZones.error }
                        result = { error: requestZones.error }
                    }

                } else {

                    state.trackZonesMsg = { success: false, text: getters.getTranslate[requestTrackZonesMap.error] || requestTrackZonesMap.error }
                    result = { error: requestTrackZonesMap.error }
                }

                state.trackZonesMapProcess = false

            } else {

                state.trackZonesMapMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        }
    },

    mutations: {

        setTrackZones:      (state, data)   => state.trackZones     = data,
        setTrackZonesMsg:   (state, data)   => state.trackZonesMsg  = data
    }
}