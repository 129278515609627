export default {

    actions: {

        /**
         * payload: { address: 'адрес запроса', method: 'метод', token: true|false, data: 'параметры запроса', type: 'json|нативный' }
         */
        async fetchRequest({rootState, getters, dispatch}, payload) {

            let parameters = {

                method: payload.method,
                cache: 'no-cache',
                referrerPolicy: 'origin-when-cross-origin',
                headers: new Headers()
            }

            if(payload.token) {

                parameters.headers.append('Authorization', 'Bearer ' + rootState.token.token)
            } 

            if(payload.data) {

                if(payload.type) {

                    payload.type == 'json' ?
                        parameters.body = JSON.stringify(payload.data) :
                        parameters.body = payload.data

                } else {

                    parameters.body = JSON.stringify(payload.data)
                }
            }

            try {

                const request = await fetch(payload.address, parameters)

                const response = await request.text()

                const result = JSON.parse( response )

                if(request.ok) {

                    return result

                } else if(result.details && result.details.length && result.details[0].value) {

                    if(result.details[0].value == 'ETokenExpired' || result.details[0].value == 'ETokenError') {

                        dispatch('logout')

                        return { error: 'ETokenExpires' }

                    } else {

                        return { error: getters.getTranslate[result.details[0].value] || result.details[0].value }
                    }

                } else {

                    return { error: 'EUnknown' }
                }

            } catch {

                return { error: 'EConnection' }
            }
        },

        logout() {

            localStorage.clear()
            window.location.reload()
        }
    }
}