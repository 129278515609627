import { createRouter, createWebHistory } from 'vue-router'

import store        from '@/store/index.js'

import Dashboard    from '../views/Dashboard.vue'
import Auth         from '../views/Auth.vue'
import Profile      from '../views/Profile.vue'
import Users        from '../views/Users.vue'
import User         from '../views/User.vue'
import Objects      from '../views/Objects.vue'
import Obj          from '../views/Object.vue'
import Groups       from '../views/Groups.vue'
import Group        from '../views/Group.vue'
import Zones        from '../views/Zones.vue'
import ZonesCreate  from '../views/ZonesCreate.vue'
import ZonesEdit    from '../views/ZonesEdit.vue'
import Tracks       from '../views/Tracks.vue'
import Track        from '../views/Track.vue'
import Reports      from '../views/Reports.vue'
import Test         from '../views/Test.vue'
import ViewDraw     from '../views/ViewDraw.vue'

const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),

    routes: [

        {
            path: '/',
            name: store.getters.getTranslate.PAGE_DASHBOARD,
            component: Dashboard,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' }
                ]
            }
        },

        {
            path: '/auth',
            name: store.getters.getTranslate.PAGE_AUTH,
            component: Auth,
            meta: {
                onlyNotAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_AUTH }
                ]
            }
        },

        {
            path: '/profile',
            name: store.getters.getTranslate.PAGE_PROFILE,
            component: Profile,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_PROFILE }
                ]
            }
        },

        {
            path: '/zones',
            name: store.getters.getTranslate.PAGE_ZONES,
            component: Zones,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_ZONES }
                ]
            }
        },

        {
            path: '/zones/create',
            name: store.getters.getTranslate.PAGE_ZONE_CREATE,
            component: ZonesCreate,
            props: true,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_ZONE_CREATE }
                ]
            }
        },

        {
            path: '/zones/:uuid',
            name: store.getters.getTranslate.PAGE_ZONE,
            component: ZonesEdit,
            props: true,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_ZONE }
                ]
            }
        },

        {
            path: '/groups',
            name: store.getters.getTranslate.PAGE_GROUPS,
            component: Groups,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_GROUPS }
                ]
            }
        },

        {
            path: '/groups/:uuid',
            name: store.state.pageOptions.pageHeader || store.getters.getTranslate.PAGE_GROUP,
            props: true,
            component: Group, 
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_GROUPS, link: '/groups' },
                    { name: store.getters.getTranslate.PAGE_GROUP }
                ]
            }
        },

        {
            path: '/objects',
            name: store.getters.getTranslate.PAGE_OBJECTS,
            component: Objects,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_OBJECTS }
                ]
            }
        },

        {
            path: '/objects/:uuid',
            name: store.state.pageOptions.pageHeader || store.getters.getTranslate.PAGE_OBJECT,
            props: true,
            component: Obj, 
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_OBJECTS, link: '/objects' },
                    { name: store.getters.getTranslate.PAGE_OBJECT }
                ]
            }
        },

        {
            path: '/tracks',
            name: store.getters.getTranslate.PAGE_TRACKS,
            component: Tracks,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_TRACKS }
                ]
            }
        },

        {
            path: '/tracks/:trackUuid',
            name: store.getters.getTranslate.PAGE_TRACK,
            props: true,
            component: Track,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_TRACKS, link: '/tracks' },
                    { name: store.getters.getTranslate.PAGE_TRACK }
                ]
            }
        },

        {
            path: '/users',
            name: store.getters.getTranslate.PAGE_USERS,
            component: Users,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_USERS }
                ]
            }
        },

        {
            path: '/users/:uuid',
            name: store.state.pageOptions.pageHeader || store.getters.getTranslate.PAGE_USER,
            props: true,
            component: User,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_USERS, link: '/users' },
                    { name: store.getters.getTranslate.PAGE_USER }
                ]
            }
        },

        {
            path: '/reports',
            name: store.state.pageOptions.pageHeader || store.getters.getTranslate.PAGE_REPORTS,
            props: true,
            component: Reports,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_REPORTS }
                ]
            }
        },

        {
            path: '/test',
            name: store.state.pageOptions.pageHeader || store.getters.getTranslate.PAGE_TEST,
            props: true,
            component: Test,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: store.getters.getTranslate.PAGE_TEST }
                ]
            }
        },

        {
            path: '/draw',
            name: 'draw',
            props: true,
            component: ViewDraw,
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.PAGE_DASHBOARD, link: '/' },
                    { name: 'Draw' }
                ]
            }
        }
    ]
})

router.beforeEach((to, from, next) => {

    if(
        to.fullPath == '/auth' && 
        store.state.token.token
    ) {

        next('/') 
        return 
    }

    if(to.matched.some(record => record.meta.onlyAuth)) {

        if(store.state.token.token) {

            next() 
            return 
        }

        next('/auth') 

    } else {

        next() 
    }
})

// @TODO: Хорошо бы получать title приложения из .env или vue.config
router.afterEach( to => {

    const appName       = 'Track Management System [2.0]'
    const breadcrumbs   = to.meta?.breadcrumb
    const metaName      = breadcrumbs ? breadcrumbs[breadcrumbs.length - 1]?.name : false

    document.title = (metaName ? metaName + ' - ' : '') + appName
})

export default router