<template>

    <div class="page page--auth">

        <TMSAuth />

    </div>

</template>

<script>
import TMSAuth from '@/components/TMSAuth'

export default {

    name: 'ViewAuth',

    components: {

        TMSAuth
    }
}
</script>