export default {

    state: {

        trackZonesListTypes: [
            
            { type: 'main',     purpose: 'mainT'    },  // its main purpose is to draw track boundaries
            { type: 'park',     purpose: 'parkT'    },  // parking area
            { type: 'spd',      purpose: 'spdT'     },  // speed control area
            { type: 'gate',     purpose: 'gateT'    },  // gate control area
            { type: 'close',    purpose: 'closeT'   }   // closed control area
        ],

        trackZonesList:                 {},
        trackZonesListProcess:          false,
        trackZonesListMsg:              {},

        trackZonesListCreateProcess:    false,
        trackZonesListCreateMsg:        {},

        trackZonesListList:             {},
        trackZonesListListProcess:      {},
        trackZonesListListMsg:          {},

        trackZonesListUpdateProcess:    false,
        trackZonesListUpdateMsg:        {},

        trackZonesListDeleteProcess:    false,
        trackZonesListDeleteMsg:        {}
    },

    getters: {

        getTrackZonesListTypes: (state, getters) => {

            return state.trackZonesListTypes.map(item => { 
                
                return { 

                    name:       getters.getTranslate['TRACK_ZONES_LIST_TYPE_' + item.type.toUpperCase()], 
                    type:       item.type, 
                    purpose:    item.purpose
                }
            })
        }
    },

    actions: {

        /**
         * Создаёт новую связку трека с зоной
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
        async createTrackZonesList({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.trackZonesListCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/tracks_zones`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.trackZonesListCreateMsg = { success: true, text: getters.getTranslate.TRACK_ZONES_LIST_CREATE_SUCCESS }

                } else {

                    state.trackZonesListCreateMsg = { success: false }
                    state.trackZonesListCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.trackZonesListCreateProcess = false

            } else {

                state.trackZonesListCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает связки трек-группа
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readTrackZonesList({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token
            let result = {}

            if(token) {

                state.trackZonesListProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                // Запрашиваем связи трек-зона
                const requestTrackZonesList = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/tracks_zones${params}`, 
                    method: 'GET', 
                    token
                })

                if(!requestTrackZonesList.error) {

                    let params = ''

                    let trackZonesList = {}
                    let trackZonesListUuid = []
                    
                    // Формируем объект с зонами трека { UUID_зоны_трека: свойства_сзоны_трека }
                    // Получаем массив UUID зон трека из связок
                    requestTrackZonesList.array.map( zone => {

                        trackZonesList[zone.zoneUuid] = zone
                        trackZonesListUuid.push(zone.zoneUuid) 
                    })

                    for( let uuid of trackZonesListUuid) {

                        params.length ?
                            params += '&uuid=' + uuid :
                            params = '?uuid=' + uuid
                    }

                    // Запрашиваем зоны трека
                    const requestZones = await dispatch('fetchRequest', {
                        address: `${rootState.config.httpServer}/zones${params}`, 
                        method: 'GET', 
                        token
                    })

                    if(!requestZones.error) {

                        requestZones.array.map(zone => {

                            if(trackZonesList[zone.uuid]) {

                                trackZonesList[zone.uuid].zone = zone
                            }
                        })

                        state.trackZonesList = { array: trackZonesList, c: requestTrackZonesList.c }
                        state.trackZonesListMsg = { success: true }

                        result = state.trackZonesList

                    } else {

                        state.trackZonesListMsg = { success: false, text: getters.getTranslate[requestZones.error] || requestZones.error }
                        result = { error: requestZones.error }
                    }

                } else {

                    state.trackZonesListMsg = { success: false, text: getters.getTranslate[requestTrackZonesList.error] || requestTrackZonesList.error }
                    result = { error: requestTrackZonesList.error }
                }

                state.trackZonesListProcess = false

            } else {

                state.trackZonesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Получает зоны для добавления в трек
         * 
         * @param {Object} payload - параметры запроса листа [см.swagger]
         */
        async readTrackZonesListList({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token
            let result = {}

            if(token) {

                state.trackZonesListProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(Array.isArray(payload[param])) {

                            payload[param].map( item => {

                                params.length ?
                                    params += '&' + param + '=' + item :
                                    params = '?' + param + '=' + item
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/zones${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    let zones = {}

                    request.array.map( zone => zones[zone.uuid] = zone )

                    state.trackZonesListList = {

                        array: zones,
                        c: request.c
                    }

                    state.trackZonesListListMsg = { success: true }

                    result = state.zones

                } else {

                    state.trackZonesListList = {}

                    state.trackZonesListListMsg = { success: false }
                    state.trackZonesListListMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.trackZonesListProcess = false

            } else {

                state.trackZonesListListMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Обновляет свойства зоны трека
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
        async updateTrackZonesList({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.trackZonesListUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/tracks_zones`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.trackZonesListUpdateMsg = { success: true, text: getters.getTranslate.TRACK_ZONES_LIST_UPDATE_SUCCESS }

                } else {

                    state.trackZonesListUpdateMsg = { success: false }
                    state.trackZonesListUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.trackZonesListUpdateProcess = false

            } else {

                state.trackZonesListUpdateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаление связку трек-группа
         * 
         * @param {Object} payload - payload.uuid: UUID трека
         */
        async deleteTrackZonesList({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.trackZonesListDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/tracks_zones/${payload.trackUuid}/${payload.zoneUuid}`,
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.trackZonesListDeleteMsg = { success: true, text: getters.getTranslate.TRACK_ZONES_LIST_DELETE_SUCCESS }

                } else {

                    state.trackZonesListDeleteMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.trackZonesListDeleteProcess = false

            } else {

                state.trackZonesListDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setTrackZonesList:          (state, data)   => state.trackZonesList          = data,
        setTrackZonesListMsg:       (state, data)   => state.trackZonesListMsg       = data,
        setCreateTrackZonesListMsg: (state, data)   => state.trackZonesListCreateMsg = data,
        setUpdateTrackZonesListMsg: (state, data)   => state.trackZonesListUpdateMsg = data,
        setDeleteTrackZonesListMsg: (state, data)   => state.trackZonesListDeleteMsg = data
    }
}