export default {

    state: {

        token: null
    },

    getters: {

        getToken: state => state.token
    },

    actions: {

        /**
         * Добавляет в хранилище token, полученный в случае успешной авторизации
         * 
         * @param {String} payload - token для запросов после успешной авторизации
         */
        addToken ({state}, payload) {

            if(payload) {

                state.token = payload
                localStorage.setItem('TMS_TOKEN', payload)

            } else if(localStorage.getItem('TMS_TOKEN')) {

                state.token = localStorage.getItem('TMS_TOKEN')

                return state.token

            } else {

                state.token = null
            }

            return false
        }
    }
}
