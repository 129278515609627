<template>

    <div class="tms-track-zones-delete">

        <div
            v-if="'success' in trackZonesListDeleteMsg && !trackZonesListDeleteMsg.success"
            class="alert alert--danger"
        >
            {{ getTranslate[trackZonesListDeleteMsg.text] || trackZonesListDeleteMsg.text }}
        </div>

        <div class="my-4">Эта зона будет удалена из трека.</div>

        <form @submit.prevent="submitForm()">

            <div class="btns">

                <button
                    class="btn"
                    @click="$emit('closeModal', false)"
                >
                    {{ getTranslate.BTN_CANCEL }}
                </button>

                <button
                    type="submit"
                    class="btn btn--primary"
                >
                    {{ getTranslate.BTN_DELETE }}
                </button>

            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSTrackZonesDelete',

    props: {

        zoneUuid: {

            required: true,
            type: String
        },

        trackUuid: {

            required: true,
            type: String
        }
    },

    computed: {

        ...mapState({

            trackZonesListDeleteProcess:   state => state.trackZonesList.trackZonesListDeleteProcess,
            trackZonesListDeleteMsg:       state => state.trackZonesList.trackZonesListDeleteMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'deleteTrackZonesList'
        ]),

        ...mapMutations([

            'setDeleteTrackZonesListMsg'
        ]),

        submitForm() {

            let data = {

                zoneUuid: this.zoneUuid,
                trackUuid: this.trackUuid
            }

            this.deleteTrackZonesList(data)
        }
    },

    mounted() {

        this.setDeleteTrackZonesListMsg({})
    },

    watch: {

        trackZonesDeleteListMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal', false)
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>