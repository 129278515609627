<template>

    <div class="page page--dashboard">

        <TMSDashboard />
        <TMSEventsToast />

    </div>

</template>

<script>
import TMSDashboard     from '@/components/TMSDashboard'
import TMSEventsToast   from '@/components/TMSEventsToast'

export default {

    name: 'ViewDashboard',

    components: {

        TMSDashboard,
        TMSEventsToast
    }
}
</script>