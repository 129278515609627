export default {

    state: {

        pageHeader: null
    },

    mutations: {

        setPageHeader: (state, data) => state.pageHeader = data
    }
}