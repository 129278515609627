export default {

    state: {

        tracks:             {},
        tracksProcess:      false,
        tracksMsg:          {},

        trackCreateProcess: false,
        trackCreateMsg:     {},

        track:              {},
        trackProcess:       false,
        trackMsg:           {},

        trackUpdateProcess: false,
        trackUpdateMsg:     {},

        trackDeleteProcess: false,
        trackDeleteMsg:     {}
    },

    actions: {

        /**
         * Создаёт новый трек
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
        async createTrack({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.trackCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/tracks`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.trackCreateMsg = { success: true, text: getters.getTranslate.TRACKS_CREATE_SUCCESS }
                    state.tracks.array[request.uuid] = request

                } else {

                    state.trackCreateMsg = { success: false }
                    state.trackCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.trackCreateProcess = false

            } else {

                state.trackCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает треки
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readTracks({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.tracksProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload[param].map( item => {

                                params.length ?
                                    params += '&' + param + '=' + item :
                                    params = '?' + param + '=' + item
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/tracks${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    let tracks = {}

                    request.array.map( track => tracks[track.uuid] = track )

                    state.tracks = {

                        array: tracks,
                        c: request.c
                    }

                    state.tracksMsg = { success: true }

                    result = state.tracks

                } else {

                    state.tracksMsg = { success: false }
                    state.tracksMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.tracksProcess = false

            } else {

                state.tracksMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Получает трек по UUID
         * 
         * @param {Object} payload - uuid трека
         */
        async readTrack({state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.trackProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/tracks/${payload.uuid}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.track = request
                    state.trackMsg = { success: true }

                    result = state.track

                } else {

                    state.trackMsg = { success: false }
                    state.trackMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.trackProcess = false

            } else {

                state.trackMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Обновляет трек
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
        async updateTrack({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.trackUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/tracks`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.trackUpdateMsg = { success: true, text: getters.getTranslate.TRACKS_UPDATE_SUCCESS }

                } else {

                    state.trackUpdateMsg = { success: false }
                    state.trackUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.trackUpdateProcess = false

            } else {

                state.trackUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаление трека
         * 
         * @param {Object} payload - payload.uuid: UUID трека
         */
        async deleteTrack ({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.trackDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/tracks/${payload.uuid}`,
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.trackDeleteMsg = { success: true, text: getters.getTranslate.TRACKS_DELETE_SUCCESS }
                    delete state.tracks.array[payload.uuid]

                } else {

                    state.trackDeleteMsg = { success: false }
                    state.trackDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.trackDeleteProcess = false

            } else {

                state.trackDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCreateTrackMsg:  (state, data)   => state.trackCreateMsg             = data,
        setUpdateTrackMsg:  (state, data)   => state.trackUpdateMsg             = data,
        setDeleteTrackMsg:  (state, data)   => state.trackDeleteMsg             = data,
        insertTrack:        (state, data)   => state.tracks.array[data.uuid]    = data
    }
}