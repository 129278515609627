export default {

    state: {

        objects: {

            array: {}
        },
        objectsProcess:     false,
        objectsMsg:         {},

        objCreateProcess:   false,
        objCreateMsg:       {},

        objInsertProcess:   false,
        objInsertMsg:       {},

        obj:                {},
        objProcess:         false,
        objMsg:             {},

        objUpdateProcess:   false,
        objUpdateMsg:       {},

        objDeleteProcess:   false,
        objDeleteMsg:       {}
    },

    actions: {

        /**
         * Создаёт нового объекта
         * 
         * @param {Object} payload - объект с полями формы создания нового объекта
         */
        async createObj({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.objectsCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/objects`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.objCreateMsg = { success: true, text: getters.getTranslate.OBJECTS_CREATE_SUCCESS }

                } else {

                    state.objCreateMsg = { success: false }
                    state.objCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.objectsCreateProcess = false

            } else {

                state.objCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает объекты
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readObjects({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.objectsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload[param].map( item => {

                                params.length ?
                                    params += '&' + param + '=' + item :
                                    params = '?' + param + '=' + item
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/objects${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    let objects = {}

                    request.array.map( obj => objects[obj.uuid] = obj )

                    state.objects = {

                        array: objects,
                        c: request.c
                    }

                    state.objectsMsg = { success: true }

                    result = state.objects

                } else {

                    state.objectsMsg = { success: false }
                    state.objectsMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.objectsProcess = false

            } else {

                state.objectsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Получает объекта по UUID
         * 
         * @param {String} payload - payload.uuid: UUID объекта в системе
         */
        async readObject({state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.objProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/objects/${payload.uuid}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.objects.array[request.uuid] = request
                    state.obj = request
                    state.objMsg = { success: true }

                    result = request

                } else {

                    state.objMsg = { success: false }
                    state.objMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.objProcess = false

            } else {

                state.objMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Обновляет объект
         * 
         * @param {Object} payload - объект с полями формы создания объекта
         */
        async updateObj({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.objUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/objects`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.objUpdateMsg = { success: true, text: getters.getTranslate.OBJECTS_UPDATE_SUCCESS }

                } else {

                    state.objUpdateMsg = { success: false }
                    state.objUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.objUpdateProcess = false

            } else {

                state.objUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаление объекта
         * 
         * @param {Object} payload - payload.uuid - UUID объекта
         */
        async deleteObj({state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.objDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${this.getters.getHttpServer}/objects/${payload.uuid}`,
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.objDeleteMsg = { success: true, text: getters.getTranslate.OBJECTS_DELETE_SUCCESS }

                } else {

                    state.objDeleteMsg = { success: false }
                    state.objDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.objDeleteProcess = false

            } else {

                state.objDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setObject:          (state, data) => state.obj                  = data,
        setObjectMsg:       (state, data) => state.objMsg               = data,
        setObjGroup:        (state, data) => state.obj.group            = data,
        setCreateObjMsg:    (state, data) => state.objCreateMsg         = data,
        setUpdateObjMsg:    (state, data) => state.objUpdateMsg         = data,
        setDeleteObjMsg:    (state, data) => state.objDeleteMsg         = data,
        insertObject:       (state, data) => state.objects[data.uuid]   = data
    }
}