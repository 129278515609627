export default {

    state: {

        trackGroups:                    {},
        trackGroupsProcess:             false,
        trackGroupsMsg:                 {},

        trackGroupsCreateList:          {},
        trackGroupsCreateListProcess:   false, 
        trackGroupsCreateListMsg:       {}, 

        trackGroupsCreateProcess:       false,
        trackGroupsCreateMsg:           {},

        trackGroupsDeleteProcess:       false,
        trackGroupsDeleteMsg:           {}
    },

    actions: {

        /**
         * Создаёт новую связку трека с группой
         * 
         * @param {Object} payload - параметры запроса [см.swagger]
         */
        async createTrackGroups({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.trackGroupsCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/groups_tracks`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.trackGroupsCreateMsg = { success: true, text: getters.getTranslate.TRACK_GROUPS_CREATE_SUCCESS }

                } else {

                    state.trackGroupsCreateMsg = { success: false }
                    state.trackGroupsCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.trackGroupsCreateProcess = false

            } else {

                state.trackGroupsCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает группы для добавления в трек
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readTrackGroupsCreateList({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token
            let result = {}

            if(token) {

                state.trackGroupsCreateListProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/groups${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.trackGroupsCreateList = request
                    state.trackGroupsCreateListMsg = { success: true }
                    result = state.trackGroupsCreateList

                } else {

                    state.trackGroupsCreateList = {}
                    state.trackGroupsCreateListMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                    result = {}
                }

                state.trackGroupsCreateListProcess = false

            } else {

                state.trackGroupsCreateListMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Получает связки трек-группа
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readTrackGroups({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken
            let result = {}

            if(token) {

                state.trackGroupsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                // Запрашиваем связи трек-группа
                const requestGroupsUuid = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/groups_tracks${params}`, 
                    method: 'GET', 
                    token
                })

                if(!requestGroupsUuid.error) {

                    let params = ''

                    // Получаем массив UUID групп трека из связок
                    const groupsUuids = requestGroupsUuid.array.map( group => group.groupUuid )

                    for( let uuid of groupsUuids) {

                        params.length ?
                            params += '&uuid=' + uuid :
                            params = '?uuid=' + uuid
                    }

                    // Запрашиваем группы трека
                    const requestTrackGroups = await dispatch('fetchRequest', {
                        address: `${rootState.config.httpServer}/groups${params}`, 
                        method: 'GET', 
                        token
                    })

                    if(!requestTrackGroups.error) {

                        let trackGroups = {}

                        requestTrackGroups.array.map( group => trackGroups[group.uuid] = group )

                        state.trackGroups = {

                            array: trackGroups,
                            c: requestGroupsUuid.c
                        }

                        state.trackGroupsMsg = { success: true }

                        result = state.trackGroups

                    } else {

                        state.trackGroupsMsg = { success: false, text: getters.getTranslate[requestTrackGroups.error] || requestTrackGroups.error }
                        result = { error: requestTrackGroups.error }
                    }

                } else {

                    state.trackGroupsMsg = { success: false, text: getters.getTranslate[requestGroupsUuid.error] || requestGroupsUuid.error }
                    result = { error: requestGroupsUuid.error }
                }

                state.trackGroupsProcess = false

            } else {

                state.trackGroupsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        },

        /**
         * Удаление связку трек-группа
         * 
         * @param {Object} payload - payload.uuid: UUID трека
         */
        async deleteTrackGroup({rootState, state, getters, dispatch}, payload) {

            const token = getters.getToken

            if(token) {

                state.trackGroupsDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/groups_tracks/${payload.groupUuid}/${payload.trackUuid}`,
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.trackGroupsDeleteMsg = { success: true, text: getters.getTranslate.TRACK_GROUPS_DELETE_SUCCESS }

                } else {

                    state.trackGroupsDeleteMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.trackGroupsDeleteProcess = false

            } else {

                state.trackGroupsDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setTrackGroups:             (state, data) => state.trackGroups              = data,
        setTrackGroupsMsg:          (state, data) => state.trackGroupsMsg           = data,
        setTrackGroupsCreateListMsg:(state, data) => state.trackGroupsCreateListMsg = data, 
        setCreateTrackGroupsMsg:    (state, data) => state.trackGroupsCreateMsg     = data,
        setUpdateTrackGroupsMsg:    (state, data) => state.trackGroupsUpdateMsg     = data,
        setDeleteTrackGroupsMsg:    (state, data) => state.trackGroupsDeleteMsg     = data,
        insertTrackGroups:          (state, data) => state.trackGroups[data.uuid]
    }
}