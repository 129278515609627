<template>

    <div class="tms-object">

        <div
            v-if="'success' in objUpdateMsg"
            class="alert"
            :class="{

                'alert--success' : objUpdateMsg.success,
                'alert--danger' : !objUpdateMsg.success
            }"
        >
            <div class="alert__text">
                {{ objUpdateMsg.text }}
            </div>
            <div class="alert__close" @click="setUpdateObjMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <form @submit.prevent="submitForm($event)">

            <div class="list">

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.OBJECT_UUID }}: </h4>
                    <span class="list__item-content">
                        {{ obj.uuid }}
                        <span class="material-symbols-outlined list__item-content-icon" @click="copyUUID()">
                            content_copy
                        </span>
                    </span>
                </div>

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.OBJECT_NAME }}: </h4>
                    <template v-if="!showEditForm">
                        <span class="list__item-content">{{ obj.name }}</span>
                    </template>
                    <template v-else>
                        <input
                            type="text"
                            id="TMSObjectName" 
                            class="input input--block"
                            :class="{ 'is-invalid' : 'TMSObjectName' in this.formInvalids }"
                            @keyup="delete formInvalids.TMSObjectName"
                            v-model="TMSObjectName"
                        />
                    </template>
                </div>

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.OBJECT_IMEI }}: </h4>
                    <template v-if="!showEditForm">
                        <span class="list__item-content">{{ obj.imei }}</span>
                    </template>
                    <template v-else>
                        <input
                            type="text"
                            id="TMSObjectImei" 
                            class="input input--block"
                            :class="{ 'is-invalid' : 'TMSObjectImei' in this.formInvalids }"
                            @keyup="delete formInvalids.TMSObjectImei"
                            v-model="TMSObjectImei"
                        />
                    </template>
                </div>

                <div class="list__item">
                    <h4 class="list__item-title">{{ getTranslate.OBJECT_GROUP }}: </h4>
                    <template v-if="!showEditForm">
                        <span class="list__item-content">{{ TMSObjectGroup ? TMSObjectGroup.name : '—' }}</span>
                    </template>
                    <template v-else>
                        <select
                            id="TMSObjectGroup" 
                            class="input input--block"
                            v-model="TMSObjectGroup"
                        >
                            <option value="null" disabled>...</option>
                            <option 
                                v-for="(group, index) in groups.array" 
                                :key="index" 
                                :value="index" 
                                :selected="TMSObjectGroup && TMSObjectGroup.uuid == group.uuid"
                            >
                                {{ group }}
                                {{ group.name }}
                            </option>
                        </select>
                    </template>
                </div>

                <template v-if="showEditForm">
                    <div class="list__item">
                        <h4 class="list__item-title">{{ getTranslate.OBJECT_PASSWORD }}: </h4>
                        <input
                            id="TMSObjectPassword" 
                            class="input input--block"
                            type="password"
                            maxlength="15"
                            autocomplete="off"
                            v-model="TMSObjectPassword"
                        />
                    </div>
                    <div class="list__item">
                        <h4 class="list__item-title">{{ getTranslate.OBJECT_PASSWORD_REPEAT }}: </h4>
                        <input
                            id="TMSObjectPasswordRepeat" 
                            class="input input--block"
                            type="password"
                            maxlength="15"
                            autocomplete="off"
                            v-model="TMSObjectPasswordRepeat"
                        />
                    </div>
                </template>

            </div>

            <div class="container-fluid" v-if="!showEditForm">
                <div class="btns btns--right my-4">
                    <a 
                        href="#"
                        class="btn btn--round btn--secondary"
                        @click.prevent="showEditForm = true"
                    >
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </a>
                </div>
            </div>

            <div class="container-fluid" v-if="showEditForm">
                <div class="btns my-4">
                    <button
                        class="btn"
                        type="button"
                        @click="showEditForm = false"
                    >
                        {{ getTranslate.BTN_CANCEL }}
                    </button>

                    <button
                        class="btn btn--primary"
                        type="submit"
                    >
                        {{ getTranslate.BTN_SAVE }}
                    </button>
                </div>
            </div>

        </form>

    </div>

</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSObject',

    data() {

        return {

            valid: true,

            TMSObjectName: null,
            TMSObjectImei: null,
            TMSObjectUser: null,
            TMSObjectGroup: null,
            TMSObjectPassword: null,
            TMSObjectPasswordRepeat: null,

            formInvalids: {},

            showEditForm: false
        }
    },

    props: {

        uuid: {

            required: true,
            type: String
        }
    },

    computed: {

        ...mapState({

            users:              state => state.users.users,
            groups:             state => state.groups.groups,

            obj:                state => state.objects.obj,
            objUpdateProcess:   state => state.objects.objUpdateProcess,
            objUpdateMsg:       state => state.objects.objUpdateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readUser',
            'readGroups',
            'readGroup',
            'readObject',
            'updateObj'
        ]),

        ...mapMutations([

            'setUpdateObjMsg',
            'setObjGroup', 
            'setPageHeader'
        ]),

        submitForm() {

            this.formInvalids = {}
            this.formErrors = []

            let data = {

                uuid: this.uuid
            }

            if(this.TMSObjectName) {

                data.name = this.TMSObjectName

            } else {

                this.formInvalids.TMSObjectName = true
                this.formErrors.push('Имя объекта')
            }

            if(this.TMSObjectImei) {

                data.imei = this.TMSObjectImei

            } else {

                this.formInvalids.TMSObjectImei = true
                this.formErrors.push('IMEI')
            }

            if(this.TMSObjectGroup) {

                data.groupUuid = this.TMSObjectGroup

            } else {

                this.formInvalids.TMSObjectGroup = true
                this.formErrors.push('Группа')
            }

            if(this.TMSObjectPassword) {

                data.password = this.TMSObjectPassword
            }

            if( 
                this.TMSObjectPassword && !this.TMSObjectPasswordRepeat || 
                this.TMSObjectPassword !== this.TMSObjectPasswordRepeat
            ) {

                this.formInvalids.TMSObjectPasswordRepeat = true
                this.formErrors.push('Пароль')
            }

            if(this.formErrors.length == 0) {

                this.updateObj(data)
            }
        },

        async copyUUID() {

            await navigator.clipboard.writeText(this.obj.uuid)
        }
    },

    mounted() {

        this.readObject({ uuid: this.uuid }).then( obj => {

            if(!obj.error) {

                this.setPageHeader(obj.name)

                if(obj.groupUuid) {

                    this.readGroup({ uuid: obj.groupUuid }).then( group => {

                        if(!group.error) {

                            this.TMSObjectGroup = group
                        }
                    })
                }
            }
        })

        this.readGroups()
    },

    beforeUnmount() {

        this.setUpdateObjMsg({})
        this.setPageHeader(null)
    },

    watch: {

        obj(obj) {

            if(!obj.error) {

                this.setPageHeader(obj.name)
            
                this.TMSObjectName = obj.name
                this.TMSObjectImei = obj.imei

                this.readUser({ uuid: obj.userUuid }).then(user => {

                    if(!user.error) {

                        this.TMSObjectUser = user
                    }
                })

                this.readGroup({ uuid: obj.groupUuid }).then(group => {

                    if(!group.error) {

                        this.TMSObjectGroup = group
                    }
                })
            }
        },

        objUpdateMsg(msg) {

            if(msg && msg.success) {

                this.readObject({ uuid: this.uuid }) 
                this.showEditForm = false
            }
        }
    }
}
</script>