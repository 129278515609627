<template>

    <div class="tms-track-zones">

        <div class="card tms-track-zones__wrapper">

            <h2 class="page__subheader">{{ getTranslate.TRACK_ZONES_LIST }}</h2>

            <div
                v-if="'success' in trackZonesListMsg && !trackZonesListMsg.success"
                class="alert alert--danger"
            >
                <div class="alert__text">
                    {{ trackZonesListMsg.text }}
                </div>
            </div>

            <div
                v-if="'success' in trackZonesListCreateMsg"
                class="alert alert--success"
            >
                <div class="alert__text">
                    {{ trackZonesListCreateMsg.text }}
                </div>
                <div class="alert__close" @click="setCreateTrackZonesListMsg({})">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div>
            </div>

            <div
                v-if="'success' in trackZonesListUpdateMsg"
                class="alert alert--success"
            >
                <div class="alert__text">
                    {{ trackZonesListUpdateMsg.text }}
                </div>
                <div class="alert__close" @click="setUpdateTrackZonesListMsg({})">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div>
            </div>

            <div
                v-if="trackZonesListDeleteMsg.success"
                class="alert alert--success"
            >
                <div class="alert__text">
                    {{ trackZonesListDeleteMsg.text }}
                </div>
                <div class="alert__close" @click="setDeleteTrackZonesListMsg({})">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div>
            </div>

            <div class="list tms-track-zones__list" v-if="trackZonesList.array">
                <div class="spinner-overlay" v-if="trackZonesListProcess">
                    <div class="spinner spinner--lg" role="status"></div>
                </div>
                <div class="list__item tms-track-zones__zone" v-for="(zone, index) in trackZonesList.array" :key="index">
                    <TMSTrackZonesListZone :zone="zone" @trackZoneDeleteProps="trackZoneDeleteProps($event)" />
                </div>
            </div>

            <div class="container-fluid my-3">
                <div class="row d-flex align-items-center">
                    <div class="col">
                        <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="trackZonesList.c?.isBefore || trackZonesList.c?.isAfter">
                            <div class="tms-pagination__prev">
                                <button 
                                    type="button" 
                                    aria-label="Previous page" 
                                    class="tms-pagination__prev-btn"
                                    :class="{ 'tms-pagination__prev-btn--disabled' : !trackZonesList.c.isBefore }"
                                    :disabled="!trackZonesList.c.isBefore"
                                    @click.prevent="prevPage(trackZonesList.c.start)"
                                >
                                    <span class="material-symbols-outlined">
                                        chevron_left
                                    </span>
                                </button>
                            </div>
                            <div class="tms-pagination__current">
                                <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                            </div>
                            <div class="tms-pagination__next">
                                <button 
                                    type="button" 
                                    aria-label="Next page" 
                                    class="tms-pagination__next-btn" 
                                    :class="{ 'tms-pagination__next-btn--disabled' : !trackZonesList.c.isAfter }"
                                    :disabled="!trackZonesList.c.isAfter"
                                    @click.prevent="nextPage(trackZonesList.c.stop)"
                                >
                                    <span class="material-symbols-outlined">
                                        chevron_right
                                    </span>
                                </button>
                            </div>
                        </nav>
                    </div>
                    <div class="col">
                        <div class="btns btns--right">
                            <a 
                                href="#"
                                class="btn btn--round btn--secondary"
                                @click.prevent="showCreateZone = !showCreateZone"
                            >
                                <span class="material-symbols-outlined">
                                    add
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <form @submit.prevent="submitTrackZonesList()" class="tms-track-zones__form" v-show="showCreateZone">

                <div class="list">  

                    <div class="list__item">
                        <div class="list__item-title">{{ getTranslate.TRACK_ZONES_LIST_SELECT }}</div>
                        <div class="list__item-value">
                            <span class="tms-track-zones__form-zone">{{ newZoneName || '' }}</span>
                            <a 
                                href="#"
                                :class="{ 'ms-2' : newZoneName }"  
                                @click.prevent="showTrackZoneCreate = true"
                            >
                                {{ newZoneName ? getTranslate.BTN_CHANGE : getTranslate.BTN_SELECT }} 
                            </a>
                        </div>
                    </div>

                    <div class="list__item">
                        <div class="list__item-title">{{ getTranslate.TRACK_ZONES_LIST_TYPE }}</div>
                        <div class="list__item-value">
                            <select
                                id="TMSTrackZonesListType"
                                class="input input--block"
                                :class="{ 'is-invalid' : formInvalids.TMSTrackZonesListType }"
                                @change="TMSTrackZonesListType = $event.target.value"
                            >
                                <option value="null" selected>...</option>
                                <option v-for="(trackZone, index) in getTrackZonesListTypes" :key="index" :value="trackZone.purpose">{{ trackZone.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="list__item">
                        <div class="list__item-title">{{ getTranslate.TRACK_ZONES_LIST_MIN_SPEED }}</div>
                        <div class="list__item-value">
                            <input
                                id="TMSTrackZonesListMinSpeed" 
                                v-model="TMSTrackZonesListMinSpeed"
                                type="number"
                                class="input input--block"
                            />
                        </div>
                    </div>

                    <div class="list__item">
                        <div class="list__item-title">{{ getTranslate.TRACK_ZONES_LIST_MAX_SPEED }}</div>
                        <div class="list__item-value">
                            <input
                                id="TMSTrackZonesListMaxSpeed" 
                                v-model="TMSTrackZonesListMaxSpeed"
                                type="number"
                                class="input input--block"
                            />
                        </div>
                    </div>

                </div>

                <div class="card__control">
                    <div class="btns tms-track-zones__create">
                        <button
                            type="submit"
                            class="btn btn--primary"
                        >
                            {{ getTranslate.BTN_ADD }}
                        </button>
                    </div>
                </div>

            </form>

        </div>

        <transition name="bounce">
            <Modal
                v-if="showTrackZoneCreate"
                max-width="600"
                :header="getTranslate.TRACK_ZONES_LIST_CREATE"
                size="lg"
                :show="showTrackZoneCreate"
                @closeModal="showTrackZoneCreate = false, deleteTrackZoneUUID = null"
            >
                <template v-slot:body>
                    <TMSTrackZonesListCreate :trackUuid="trackUuid" @selectZone="selectZoneForTrack($event)" @closeModal="showTrackZoneCreate = false" />
                </template>
            </Modal>
        </transition>

        <transition name="bounce">
            <Modal
                v-if="showTrackZoneDelete"
                max-width="600"
                :header="getTranslate.TRACK_ZONES_LIST_DELETE"
                size="lg"
                :show="showTrackZoneDelete"
                @closeModal="showTrackZoneDelete = false, deleteTrackZoneUUID = null"
            >
                <template v-slot:body>
                    <TMSTrackZonesListDelete :zoneUuid="deleteTrackZoneUUID" :trackUuid="trackUuid" @closeModal="showTrackZoneDelete = false, deleteTrackZoneUUID = null" />
                </template>
            </Modal>
        </transition>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Modal                    from '@/components/Modal'
import TMSTrackZonesListZone    from '@/components/TMSTrackZonesListZone'
import TMSTrackZonesListCreate  from '@/components/TMSTrackZonesListCreate'
import TMSTrackZonesListDelete  from '@/components/TMSTrackZonesListDelete'

export default {

    name: 'TMSTrackZonesList',

    data() {

        return {

            TMSTrackZonesListSelected:  null,
            TMSTrackZonesListType:      null,
            TMSTrackZonesListMinSpeed:  null,
            TMSTrackZonesListMaxSpeed:  null,

            showCreateZone:             false,
            showTrackZoneCreate:        false, 
            showTrackZoneDelete:        false,

            newZoneName:                '',
            deleteTrackZoneUUID:        null,

            formInvalids:               {},
            valid:                      false,

            pageNumber:                 1
        }
    },

    props: {

        trackUuid: {

            required: true,
            type: String
        }
    },

    components: {

        Modal,
        TMSTrackZonesListZone, 
        TMSTrackZonesListCreate,
        TMSTrackZonesListDelete
    },

    computed: {

        ...mapState({

            trackZonesList:             state => state.trackZonesList.trackZonesList,
            trackZonesListProcess:      state => state.trackZonesList.trackZonesListProcess,
            trackZonesListMsg:          state => state.trackZonesList.trackZonesListMsg,

            trackZonesListCreateMsg:    state => state.trackZonesList.trackZonesListCreateMsg,
            trackZonesListUpdateMsg:    state => state.trackZonesList.trackZonesListUpdateMsg,
            trackZonesListDeleteMsg:    state => state.trackZonesList.trackZonesListDeleteMsg,

            profile:                    state => state.profile.profile
        }),

        ...mapGetters([

            'getTranslate',
            'getTrackZonesListTypes'
        ])
    }, 

    methods: {

        ...mapActions([

            'readTrackZonesList',
            'createTrackZonesList',
            'updateTrackZonesList', 
            'deleteTrackZones'
        ]),

        ...mapMutations([

            'setTrackZonesList',
            'setTrackZonesListMsg',
            'setCreateTrackZonesListMsg',
            'setUpdateTrackZonesListMsg',
            'setDeleteTrackZonesListMsg'
        ]),

        trackZoneDeleteProps(event) {

            this.showTrackZoneDelete = event.show
            this.deleteTrackZoneUUID = event.uuid
        },

        prevPage(cursor) {

            let params = {

                'c.limit': 6, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor),
                trackUuid: this.trackUuid
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTrackZonesList(params).then(msg => {
                
                if(!msg.error) {
                    
                    this.pageNumber-- 
                }
            })
        },

        nextPage(cursor) {

            let params = {

                'c.limit': 6, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor),
                trackUuid: this.trackUuid
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            this.readTrackZonesList(params).then(msg => {
                
                if(!msg.error) {
                    
                    this.pageNumber++
                }
            })
        },

        selectZoneForTrack(event) {

            this.TMSTrackZonesListSelected = event.uuid
            this.newZoneName = event.name
            this.showTrackZoneCreate = false
        },

        submitTrackZonesList() {

            this.formInvalids = {}
            this.valid = false

            let data = {

                is_set:     true,
                trackUuid:  this.trackUuid,
                userUuid:   this.profile.uuid
            }

            if(this.TMSTrackZonesListSelected) {

                data.zoneUuid = this.TMSTrackZonesListSelected
                this.valid = true

            } else {

                this.formInvalids.TMSTrackZonesListSelected = true
                this.valid = false
            }

            if(this.TMSTrackZonesListType) {

                data.purpose = this.TMSTrackZonesListType
                this.valid = true

            } else {

                this.formInvalids.TMSTrackZonesListType = true
                this.valid = false
            }

            if(this.TMSTrackZonesListMinSpeed) 
                data.min_speed = this.TMSTrackZonesListMinSpeed

            if(this.TMSTrackZonesListMaxSpeed) 
                data.max_speed = this.TMSTrackZonesListMaxSpeed

            if(this.valid) {

                this.createTrackZonesList(data)
            }
        }
    },

    mounted() {

        this.readTrackZonesList({'c.limit': 6, trackUuid: this.trackUuid })
    },

    beforeUnmount() {

        this.setTrackZonesList({})
        this.setTrackZonesListMsg({})
        this.setCreateTrackZonesListMsg({})
        this.setUpdateTrackZonesListMsg({})
        this.setDeleteTrackZonesListMsg({})
    },

    watch: {

        trackZonesListCreateMsg(msg) {

            if(msg && msg.success) {

                this.readTrackZonesList({ 'c.limit': 6, trackUuid: this.trackUuid })
                this.showCreateZone = false
            }
        },

        trackZonesListDeleteMsg(msg) {

            if(msg && msg.success) {

                this.readTrackZonesList({ 'c.limit': 6, trackUuid: this.trackUuid })
                this.deleteTrackZoneUuid = null
                this.showTrackZoneDelete = false
            }
        },
        
        showCreateZone() {

            this.setUpdateTrackZonesListMsg({})
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/track-zones';
</style>