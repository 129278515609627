<template>
    <div class="tms-zones-tracks">

        <div 
            v-if="'success' in tracksMsg && !tracksMsg.success"
            class="mb-4 alert alert--danger tms-track-groups-create__message" 
        >
            <div class="alert__text">
                {{ tracksMsg.text }}
            </div>
        </div>

        <div v-if="isShowSearch" class="tms-zones-tracks__search">
            <div class="input-group input-wrapper">
                <input 
                    type="text" 
                    class="input input--block search__input" 
                    :placeholder="getTranslate.SEARCH_PLACEHOLDER" 
                    v-model="inputSearch"
                >
                <template v-if="inputSearch.length > 2">
                    <span class="material-symbols-outlined input-wrapper__icon" @click="inputSearch = ''">
                        cancel
                    </span>
                </template>
                <template v-else>
                    <span class="material-symbols-outlined input-wrapper__icon">
                        search
                    </span>
                </template>
            </div>
        </div>

        <div v-if="tracksProcess">
            <Loader />
        </div>

        <div v-if="tracksZone && !tracksProcess" class="list list--trans">
            <div class="list__item" v-for="(track, index) in tracksZone.array" :key="index">
                <div class="row">
                    <div class="col-12 d-flex align-items-center">
                        <router-link class="list__item-link" :to="'/tracks/' + track.uuid">{{ track.name }}</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="tracksZone && !tracksProcess" class="container-fluid my-3">
            <nav class="tms-pagination" role="navigation" aria-label="Pagination Navigation" v-if="tracksZone.c?.isBefore || tracksZone.c?.isAfter">
                <div class="tms-pagination__prev">
                    <button 
                        type="button" 
                        aria-label="Previous page" 
                        class="tms-pagination__prev-btn"
                        :class="{ 'tms-pagination__prev-btn--disabled' : !tracksZone.c.isBefore }"
                        :disabled="!tracksZone.c.isBefore"
                        @click.prevent="prevPage(tracksZone.c.start)"
                    >
                        <span class="material-symbols-outlined">
                            chevron_left
                        </span>
                    </button>
                </div>
                <div class="tms-pagination__current">
                    <div class="tms-pagination__current-text">{{ getTranslate.PAGINATION_PAGE }} {{ pageNumber }}</div>
                </div>
                <div class="tms-pagination__next">
                    <button 
                        type="button" 
                        aria-label="Next page" 
                        class="tms-pagination__next-btn" 
                        :class="{ 'tms-pagination__next-btn--disabled' : !tracksZone.c.isAfter }"
                        :disabled="!tracksZone.c.isAfter"
                        @click.prevent="nextPage(tracksZone.c.stop)"
                    >
                        <span class="material-symbols-outlined">
                            chevron_right
                        </span>
                    </button>
                </div>
            </nav>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Loader from "@/components/Loader"

export default {
    
    name: 'TMSZonesTracksList',

    data() {

        return {

            inputSearch: '',
            tracksZone: null,
            isShowSearch: true,
            pageNumber: 1,
            tracksZoneOnPage: 6
        }
    },

    computed: {

        ...mapState({

            tracksMsg: state => state.tracks.tracksMsg,
            tracksProcess: state => state.tracks.tracksProcess
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    components: {

        Loader
    },

    methods: {

        ...mapActions([

            'readTracks'
        ]),

        async prevPage(cursor) {

            let params = {
                'c.limit': this.tracksZoneOnPage, 
                'c.direction': 'before', 
                'c.cursor': encodeURIComponent(cursor),
                zoneUuid: this.$route.params.uuid
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            // Получаем треки зоны
            this.tracksZone = await this.readTracks(params)
            
            this.pageNumber--
        },

        async nextPage(cursor) {

            let params = {
                'c.limit': this.tracksZoneOnPage, 
                'c.direction': 'after', 
                'c.cursor': encodeURIComponent(cursor),
                zoneUuid: this.$route.params.uuid
            }

            if(this.inputSearch) 
                params['c.q'] = this.inputSearch

            // Получаем треки зоны
            this.tracksZone = await this.readTracks(params)
            
            this.pageNumber++
        }
    },

    async created() {

        const params = {
            'c.limit': this.tracksZoneOnPage,
            zoneUuid: this.$route.params.uuid
        }

        // Получаем треки зоны
        this.tracksZone = await this.readTracks(params)

        if (this.tracksZone?.error) this.isShowSearch = false
    }, 

    watch: {

        async inputSearch(input) {

            let params = {
                'c.limit': this.tracksZoneOnPage, 
                zoneUuid: this.$route.params.uuid
            }

            if (input.length > 2)
                params['c.q'] = input

            // Получаем треки зоны
            this.tracksZone = await this.readTracks(params)

            this.pageNumber = 1
        }
    }
}
</script>