<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div 
                class="modal-container" 
                :class="{ 
                    'modal-container--md' : size && size == 'md',
                    'modal-container--lg' : size && size == 'lg',
                    'modal-container--xl' : size && size == 'xl'
                }
            ">
                <div class="modal-header">
                    <slot name="header">
                        <div class="modal-header-text">
                            {{ header }}
                        </div>
                        <div class="modal-header-subtext d-none d-md-block" v-if="fieldsRequired">
                            <span class="modal-header-warning">⚹</span> {{ getTranslate.FORM_REQUIRED }}
                        </div>
                    </slot>
                    <div class="modal-header-close material-symbols-outlined" @click="$emit('closeModal', true)">
                        clear
                    </div>
                </div>
                <div class="modal-body">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

    name: 'ComponentModal',

    data() {

        return {

            modal: null
        }
    },

    props: {

        header: {

            type: String,
            required: true
        },

        fieldsRequired: {
            type: Boolean
        },

        size: {

            type: String,
            required: false
        },

        show: {

            type: Boolean,
            required: false,
            default: true
        }
    },

    emits: [

        'closeModal'
    ],

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    mounted() {

        const self = this
        const modalWrapper = document.getElementsByClassName('modal-wrapper')[0]
        const modalBody = document.getElementsByClassName('modal-container')[0]
        const scrollbarWidth = window.innerWidth - document.body.offsetWidth

        document.body.style.overflow = this.show ? 'hidden' : ''
        document.body.style.paddingRight = this.show ? scrollbarWidth + 'px' : ''

        // Переносим модальное окно в корень body [головняк с позиционированием = disable]
        this.modal = document.getElementsByClassName('modal-mask')[0]
        document.body.appendChild(this.modal)

        // Клик вне модального окна закрывает его
        modalWrapper.addEventListener('mousedown', function(event) {

            if(!modalBody.contains(event.target)) {

                self.$emit('closeModal', true)
            }
        })

        // Закрытие модального окна по нажатию Esc
        document.addEventListener('keyup', event => event.code == "Escape" ? this.$emit('closeModal', true) : null )
    },

    beforeUnmount() {

        document.body.style.overflow = ''
        document.body.style.paddingRight = ''

        document.body.removeChild(this.modal)
    },

    watch: {

        show(mode) {

            const scrollbarWidth = window.innerWidth - document.body.offsetWidth

            document.body.style.overflow = mode ? 'hidden' : ''
            document.body.style.paddingRight = mode ? scrollbarWidth + 'px' : ''
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/modal.scss';
</style>