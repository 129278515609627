<template>
    <div class="tms-events-alert__item">
        <div class="tms-events-alert__title">{{ getTranslate[event.name] }}</div>
        <div class="tms-events-alert__object">
            <div class="row">
                <div class="col-12 col-md-3">
                    <span class="tms-events-alert__object-descr">Объект: </span>
                </div>
                <div class="col-12 col-md-9">
                    <router-link :to="'/objects/' + event.obj.uuid" class="tms-events-alert__object-link">{{ event.obj.name }}</router-link>
                </div>
            </div>
        </div>
        <div class="tms-events-alert__track">
            <div class="row">
                <div class="col-12 col-md-3">
                    <span class="tms-events-alert__track-descr">Трек: </span>
                </div>
                <div class="col-12 col-md-9">
                    <router-link :to="'/tracks/' + event.track.uuid" class="tms-events-alert__track-link">{{ event.track.name }}</router-link>
                </div>
            </div>
        </div>
        <div class="tms-events-alert__speed">
            <div class="row">
                <div class="col-12 col-md-3">
                    <span class="tms-events-alert__speed-descr">Скорость: </span>
                </div>
                <div class="col-12 col-md-9">
                    <div class="tms-events-alert__speed-link">{{ buildSpeed(event.spd) }}</div>
                </div>
            </div>
        </div>
        <div class="tms-events-alert__speed">
            <div class="row">
                <div class="col-12 col-md-3">
                    <span class="tms-events-alert__speed-descr">Дата, время: </span>
                </div>
                <div class="col-12 col-md-9">
                    <div class="tms-events-alert__speed-link">{{ new Date(event.ntm).toLocaleString() }}</div>
                </div>
            </div>
        </div>
        <div class="tms-events-alert__map" :id="event.type + event.ntm + event.track.uuid"></div>
        <!--div class="tms-events-alert__descr">{{ getTranslate.EVENTS_ALERT_DESC }}</div-->
        <div class="btns justify-content-md-end tms-events-alert__btns">

            <button 
                class="btn btn--secondary" 
                @click="
                    deleteEventAlert(event.eventId)
                "
            >
                {{ getTranslate.BTN_CLOSE }}
            </button>

            <button 
                class="btn btn--primary" 
                @click="
                    submitEventConfirm(event)
                "
            >
                <template v-if="!confirmEventAlert">
                    {{ getTranslate.BTN_ACCEPT }}
                </template>
                <template v-else>
                    <div class="spinner-border" role="status"></div>
                </template>
            </button>

        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Leaflet from 'leaflet'

export default {

    name: 'TMSEventsAlertItem',

    data() {

        return {

            map: {},
            marker: {}
        }
    },

    props: {

        event: {

            required: true,
            type: Object
        }
    },

    computed: {

        ...mapState({

            ws:                 state => state.ws.ws,
            confirmEventAlert:  state => state.events.confirmEventAlert
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'sendEventConfirm'
        ]),

        ...mapMutations([

            'deleteEventAlert'
        ]),

        buildSpeed(num) {

            return num ? num.toFixed(1) + ' км/ч' : '-'
        },

        submitEventConfirm(event) {

            let data = {

                action: 'EditA',

                eventConfirm: {

                    eventId: event.eventId,
                    trackUuid: event.track.uuid,
                    type: event.type,
                    confirm: true
                }
            }

            this.ws.send(JSON.stringify(data))
        }
    },

    mounted() {

        this.map = Leaflet.map(this.event.type + this.event.ntm + this.event.track.uuid, {

            center: [this.event.coords.lat, this.event.coords.lon],
            zoomControl: false,
            zoom: 13,
            minZoom: 10,
            maxZoom: 19,
            attributionControl: false
        })

        // Добавляем управление зумом
        Leaflet.control.zoom({ position: 'bottomright' }).addTo(this.map)

        // Основная карта
        Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: 10,
            maxZoom: 19,
            noWrap: true
        }).addTo(this.map)

        // Карта НАМИ и НИЦИАМТ
        Leaflet.tileLayer("https://dev.shatl-t.ru/Tiles/{z}/{x}/{y}.png", {
            minZoom: 10,
            maxZoom: 19,
            // errorTileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        }).addTo(this.map)

        this.marker = new Leaflet.marker([ this.event.coords.lat, this.event.coords.lon ], {

            icon: Leaflet.icon({

                iconUrl:        require('@/assets/images/icons/icon-map-marker.png'),
                iconRetinaUrl:  require('@/assets/images/icons/icon-map-marker.png'),
                iconSize:       [40, 40], // Размер иконки маркера
                iconAnchor:     [20, 40], // Сдвиг иконки маркера относительно lat/lon
                shadowSize:     [0, 0]    // Размер тени иконки маркера
            })
        })

        this.marker.addTo(this.map)
    }
}
</script>