<template>
    <div class="tms-events-toast">
        <div class="tms-events-toast__event" v-for="(event, index) in eventsToast" :key="index">
            <h5 class="tms-events-toast__event-name">
                {{ getTranslate[event.name] }}
                <span 
                    class="material-symbols-outlined tms-events-toast__event-name-icon tms-events-toast__event-name-icon--map"
                    @click="focusOnPoint(event.coords)"
                >
                    location_on
                </span>
                <span 
                    class="material-symbols-outlined tms-events-toast__event-name-icon tms-events-toast__event-name-icon--close" 
                    @click="deleteEventToast(event.eventId)"
                >
                    close
                </span>
            </h5>
            <router-link :to="'/objects/' + event.obj.uuid" class="tms-events-toast__event-link">{{ event.obj.name }}</router-link>
            <router-link :to="'/tracks/' + event.track.uuid" class="tms-events-toast__event-link">{{ event.track.name }}</router-link>
            <div class="tms-events-toast__event-time">{{ new Date(event.ntm).toLocaleString() }}</div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'

export default {

    name: 'TMSEventsToast',

    computed: {

        ...mapState({

            eventsToast: state => state.events.eventsToast
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapMutations([

            'setEventOnMap',
            'deleteEventToast'
        ]),

        focusOnPoint(coords) {

            this.setEventOnMap(coords)

            if(this.$route.fullPath !== '/') {

                this.$router.push('/')
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/events-toast.scss';
</style>