export default {
    
    state: {

        lang: 'RU'
    },

    getters: {

        getLang: state => state.lang
    },
    
    actions: {

        updateLanguage({state}, payload) {
            
            localStorage.setItem('TMS_LANG', payload)
            state.lang = payload

            document.getElementsByTagName('html')[0].setAttribute('lang', payload.toLowerCase())
        }
    }
}