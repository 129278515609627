export default {

    state: {

        httpServer: '',
        wsServer:   ':9001'
    },

    getters: {

        getHttpServer:  state => state.httpServer,
        getWsServer:    state => state.wsServer,
        
        getUDSHTTP:     state => state.UDS_HTTP,
        getUDSWS:       state => state.UDS_WS,
    },

    mutations: {

        setHttpServer:  (state, data) => state.httpServer    = data,
        setWsServer:    (state, data) => state.wsServer      = data
    }
}