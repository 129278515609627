export default {

    state: {

        reports: {},
        reportsProcess: false,
        reportsMsg: {}
    },

    actions: {

        /**
         * Запрос отчётов по событиям или трекам
         * 
         * @param {Array} typeArray         - Массив типов событий; если не заполнено, то все
         * @param {Array} objectUuidArray   - Массив UUID объектов; если не заполнено, то все
         * @param {Array} trackUuidArray    - Массив UUID треков; если не заполнено, то все
         * @param {Array} groupUuidArray    - Массив UUID групп; если не заполнено, то все
         */
        async readReports({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token
            let result = {}

            if(token) {

                state.reportsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(param !== 'reportType') {

                            if(typeof payload[param] == 'object' && payload[param].length) {

                                payload[param].map( item => {

                                    params.length ?
                                        params += '&' + param + '=' + item :
                                        params = '?' + param + '=' + item
                                })

                            } else {

                                params.length ?
                                    params += '&' + param + '=' + payload[param] :
                                    params = '?' + param + '=' + payload[param]
                            }
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/reports/${payload.reportType}${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.reports = request
                    state.reportsMsg = { success: true }

                    result = request

                } else {

                    state.reportsMsg = { success: false }
                    state.reportsMsg.text = getters.getTranslate[request.error] || request.error

                    result = { error: request.error }
                }

                state.reportsProcess = false

            } else {

                state.reportsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
                result = { error: getters.getTranslate.EUnauthRequest }
            }

            return result
        }
    },

    mutations: {

        setReports:     (state, data) => state.reports      = data,
        setReportsMsg:  (state, data) => state.reportsMsg   = data
    }
}