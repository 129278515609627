<template>
    <div class="page page--zones">
        <div class="tms-zones">
            <TMSZonesMap v-if="zone" mode="edit" :zone="zone" />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import TMSZonesMap from "@/components/TMSZonesMap"

export default {
    name: "ViewZonesEdit",
    
    data() {
        return {
            zone: null
        }
    },

    components: {
        TMSZonesMap
    },

    methods: {
        ...mapActions([

            "readZoneByUuid"
        ]),

        async readZone(uuid) {
            if (uuid) {
                const data = await this.readZoneByUuid(uuid)

                if (!data.error) {
                    
                    this.zone = data
                } else {
                    //Сообщение об ошибке (Зона по такому uuid не найдена)
                    //...

                    this.$router.push("/zones")
                }
            }
        }
    },
    
    async created() {
        await this.readZone(this.$route.params.uuid)
    },

    async beforeRouteUpdate(to) {
        this.zone = null

        await this.readZone(to.params.uuid)
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/scss/components/zones.scss";
</style>
