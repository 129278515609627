<template>
    <div class="tms-events-alert" v-if="Object.keys(eventsAlert).length">
        <div class="tms-events-alert__event" v-for="(event, index) in eventsAlert" :key="index">
            <TMSEventsAlertItem :event="event" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TMSEventsAlertItem from '@/components/TMSEventsAlertItem'

export default {

    name: 'TMSEventsAlert',

    components: {

        TMSEventsAlertItem
    },

    computed: {

        ...mapState({

            eventsAlert: state => state.events.eventsAlert
        })
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/events-alert.scss';
</style>