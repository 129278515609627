<template>
    <div class="tms-zones-sidebar" :class="{ show: isOpen }">
        <div class="tms-zones-sidebar-container">
            <div class="tms-zones-sidebar__header">
                <div class="d-flex justify-content-between h-100">
                    <div class="d-flex align-items-center">
                        <h2>{{ title }}</h2>
                    </div>
                    <div class="d-flex align-items-center">
                        <button
                            type="button"
                            class="header-btn"
                            @click="$emit('hidden')"
                        >
                            <span class="material-symbols-outlined">arrow_back</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="tms-zones-sidebar__body">
                <slot name="body" />
            </div>

            <div class="tms-zones-sidebar__footer">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {

        isOpen: {
            type: Boolean,
            required: true
        },

        title: {
            type: String,
            required: true
        }
    },

    emits: {
        
        hidden: null
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/scss/components/zones-map-sidebar.scss";
</style>
