import { createStore } from 'vuex'

import config           from '@/store/modules/config.js'            // Конфигурация приложения
import requests         from '@/store/modules/requests.js'          // Хелпер для запросов к серверу
import ws               from '@/store/modules/ws.js'                // Хелпер для работы через WebSocket
import elements         from '@/store/modules/elements.js'          // Состояние элементов интерфейса (меню, блоки: открыто/закрыто)
import pageOptions      from '@/store/modules/pageOptions.js'       // Кастомные свойства страниц
import auth             from '@/store/modules/auth.js'              // Авторизация пользователя
import profile          from '@/store/modules/profile.js'           // Профиль пользователя
import token            from '@/store/modules/token.js'             // Токен для работы с запросами
import language         from '@/store/modules/language.js'          // Язык перевода текстов приложения
import translater       from '@/store/modules/translater.js'        // Языковые константы
import dashboard        from '@/store/modules/dashboard.js'         // Главный экран
import events           from '@/store/modules/events.js'            // События
import objects          from '@/store/modules/objects.js'           // Объекты
import users            from '@/store/modules/users.js'             // Пользователи
import zones            from '@/store/modules/zones.js'             // Управление геозонами
import groups           from '@/store/modules/groups.js'            // Группы объектов
import testForm         from '@/store/modules/testForm.js'          // Тестовая форма
import tracks           from '@/store/modules/tracks.js'            // Треки
import trackGroups      from '@/store/modules/trackGroups.js'       // Связки трек-группы
import trackZonesList   from '@/store/modules/trackZonesList.js'    // Зоны трека для вывода списком с пагинацией
import trackZonesMap    from '@/store/modules/trackZonesMap.js'     // Зоны трека для отображения на карте
import points           from '@/store/modules/points.js'            // Точки
import reports          from '@/store/modules/reports.js'           // Отчёты [события, треки, группы]

export default createStore({

    modules: {

        config,
        requests,
        ws,
        elements,
        pageOptions,
        auth,
        profile,
        token,
        language,
        translater,
        dashboard,
        events,
        objects,
        users,
        zones,
        groups,
        testForm,
        tracks,
        trackGroups,
        trackZonesList, 
        trackZonesMap, 
        points,
        reports
    }
})