<template>

    <div class="tms-test">

        <div 
            v-if="testRequestMsg && 'success' in testRequestMsg"
            class="d-block alert alert--dark" 
            :class="{
                'alert--success': testRequestMsg.success,
                'alert--danger': !testRequestMsg.success
            }"
            style="word-wrap: break-word"
        >
            <div class="my-4">
                <h4>Status: {{ testRequestMsg.success ? 'OK' : 'FAIL' }}</h4>
            </div>

            <div>
                <h5>Response:</h5>
                <pre>{{ testResponse }}</pre>
            </div>

        </div>

        <form action="" @submit.prevent="submitForm($event.target.elements)" class="container-fluid tms-test__form">

            <div class="row">

                <div class="col-xl-6">
                    <div class="form-group">
                        <label for="testAddress">Адрес</label>
                        <input class="form-control input input--block" type="text" id="testAddress" required v-model="TMSTestFormAddress" />
                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="form-group">
                        <label for="testMethod">Метод</label>
                        <select
                            id="TMSMethodTypeSelect" 
                            class="custom-select input input--block" 
                            required 
                            v-model="TMSMethodTypeSelect"
                        >
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="PUT">PUT</option>
                            <option value="DELETE">DELETE</option>
                        </select>
                    </div>
                </div>

            </div>

            <h3 class="mt-5 mb-3">Параметры запроса</h3>

            <div v-if="fieldsCounter">

                <div class="my-4 row" v-for="field of fieldsCounter" :key="field">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label :for="`testFieldKey_${field}`">Ключ</label>
                            <input class="form-control input input--block" type="text" :id="`testFieldKey_${field}`" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label :for="`testFieldValue_${field}`">Значение</label>
                            <input class="form-control input input--block" type="text" :id="`testFieldValue_${field}`" />
                        </div>
                    </div>
                </div>

            </div>

            <div class="btns mx-n2">

                <button type="button" class="btn btn--secondary mb-4 mx-2" @click.prevent="fieldsCounter--">-</button>
                <button type="button" class="btn btn--secondary mb-4 mx-2" @click.prevent="fieldsCounter++">+</button>

                <button type="submit" class="btn btn--primary mb-4 mx-2 ms-auto">Послать</button>

            </div>

        </form>

    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {

    name: 'TestForm',

    data() {

        return {

            fieldsCounter: 1,
            TMSTestFormAddress: '',
            TMSMethodTypeSelect: 'GET'
        }
    },

    computed: {

        ...mapState({

            httpServer:             state => state.config.httpServer,

            testResponse:           state => state.testForm.testResponse,
            testRequestProcess:     state => state.testForm.testRequestProcess,
            testRequestMsg:         state => state.testForm.testRequestMsg
        })
    },

    methods: {

        ...mapActions([

            'sendTestRequest'
        ]),

        submitForm(fields) {

            let data = {
                params: {}
            }

            if(fields.testAddress.value) data.address = fields.testAddress.value

            data.method = this.TMSMethodTypeSelect

            for(let i = 1; i <= this.fieldsCounter; i++) {

                const keyName   = `testFieldKey_${i}`
                const valueName = `testFieldValue_${i}`

                const keyInput      = fields[keyName]
                const valueInput    = fields[valueName]

                if(keyInput.value && valueInput.value) {

                    data.params[keyInput.value] = valueInput.value
                }
            }

            this.sendTestRequest(data)
        }
    },

    mounted() {

        this.TMSTestFormAddress = this.httpServer
    }
}
</script>

