export default {

    state: {

        zones:          {},
        zonesProcess:   false,
        zonesMsg:       {}
    },

    actions: {

        /**
         * Создание новой зоны
         *
         * @param {Object} payload - объект с полями формы создания нового автомобиля
         */
        async createZone({ rootState, state, getters, dispatch }, payload) {

            const token = rootState.token.token;

            if (token) {

                const request = await dispatch("fetchRequest", {
                    address: `${rootState.config.httpServer}/zones`,
                    method: "POST",
                    token,
                    data: payload
                })

                if (!request.error) {

                    state.zonesMsg = { success: true, text: getters.getTranslate.ZONES_CREATE_SUCCESS }

                    return request

                } else {

                    return request.error
                }                

            } else {

                state.zonesMsg = { success: false, text: getters.getTranslate.EUnauthRequest };
            }
        },

        /**
         * Получение всех зон
         *
         * @param {Object} payload - параметры запроса листа [см.swagger]
         * @param {Boolean} isUseStore - параметр сохранения в Store
         */
        async readZones(
            { rootState, state, getters, commit, dispatch },
            { payload, isUseStore }
        ) {

            const token = rootState.token.token

            if (token) {

                let params = ""

                if (payload) {

                    for (let param in payload) {

                        if (Array.isArray(payload[param])) {

                            payload[param].map( item => {

                                params.length ? 
                                    params += "&" + param + "=" + item : 
                                    params = "?" + param + "=" + item
                            })

                        } else {

                            params.length ? 
                                params += "&" + param + "=" + payload[param] : 
                                params = "?" + param + "=" + payload[param]
                        }
                    }
                }

                const request = await dispatch("fetchRequest", {
                    address: `${rootState.config.httpServer}/zones${params}`,
                    method: "GET",
                    token
                })

                if (!request.error) {
                    if (isUseStore) commit("setZones", request)

                    state.zonesMsg = { success: true }
                } else {
                    state.zonesMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                return request

            } else {

                state.zonesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получение зоны по UUID
         *
         * @param {String} payload - UUID зоны
         */
        async readZoneByUuid({ rootState, state, getters, dispatch }, payload) {

            const token = rootState.token.token;

            if (token) {

                const request = await dispatch("fetchRequest", {
                    address: `${rootState.config.httpServer}/zones/${payload}`,
                    method: "GET",
                    token
                })

                return request

            } else {

                state.zonesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновление выбранной зоны
         *
         * @param {Object} payload - свойства зоны [см.swagger]
         */
        async updateZone({ rootState, state, getters, dispatch }, payload) {

            const token = rootState.token.token

            if (token) {

                const request = await dispatch("fetchRequest", {
                    address: `${rootState.config.httpServer}/zones`,
                    method: "PUT",
                    token,
                    data: payload
                })

                if (!request.error) {

                    state.zonesMsg = { success: true, text: getters.getTranslate.ZONES_UPDATE_SUCCESS }

                    return request

                } else {

                    return request.error
                }  

            } else {

                state.zonesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаление зоны
         *
         * @param {String} uuid - UUID удаляемой зоны
         */
        async deleteZone({ rootState, state, getters, dispatch }, uuid) {

            const token = rootState.token.token

            if (token) {

                const request = await dispatch("fetchRequest", {
                    address: `${rootState.config.httpServer}/zones/${uuid}`,
                    method: "DELETE",
                    token
                })

                if (!request.error) {

                    state.zonesMsg = { success: true, text: getters.getTranslate.ZONES_DELETE_SUCCESS }

                    return request

                } else {

                    return request.error
                }  
            } else {

                state.zonesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setZones: (state, zones) => state.zones = zones
    }
}