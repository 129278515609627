<template>

    <div class="tms-group-delete">

        <div
            v-if="'success' in groupDeleteMsg && !groupDeleteMsg.success"
            class="alert alert--danger"
        >
            {{ getTranslate[groupDeleteMsg.text] || groupDeleteMsg.text }}
        </div>

        <div class="my-4">{{ getTranslate.GROUPS_DELETE_DESCR }}</div>

        <form @submit.prevent="submitForm()">

            <div class="btns">

                <button
                    class="btn"
                    @click="$emit('showDeleteGroup', false)"
                >
                    Отмена
                </button>

                <button
                    type="submit"
                    :loading="groupDeleteProcess"
                    :disabled="groupDeleteProcess"
                    class="btn btn--primary"
                >
                    <span>Удалить</span>
                </button>

            </div>

        </form>

    </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSGroupDelete',

    props: {

        uuid: {

            required: true,
            type: String
        }
    },

    computed: {

        ...mapState({

            groupDeleteProcess: state => state.groups.groupDeleteProcess,
            groupDeleteMsg:     state => state.groups.groupDeleteMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'deleteGroup'
        ]),

        ...mapMutations([

            'setDeleteGroupMsg'
        ]),

        submitForm() {

            let data = {

                uuid: this.uuid
            }

            this.deleteGroup(data)
        }
    },

    mounted() {

        this.setDeleteGroupMsg({})
    },

    watch: {

        groupDeleteMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal', false)
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>