<template>
    <div class="tms-track-zones-map" id="TMSTrackZonesMap" ref="mapContainer"></div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { makeRenderCoords, makeRenderOptions } from '@/helpers/zones/drawZones.js'
import L from 'leaflet'   
import makePopup from '@/helpers/zones/makePopup.js'
import 'leaflet.sector/leaflet.sector.js'                   // Leaflet Sector - для Leaflet Draw Sector
import 'leaflet-event-forwarder/src/L.EventForwarder.js'    // Leaflet ловушка событий - ловит необработанные события на слое и передает его в слой выше
import '@maptiler/leaflet-maptilersdk'

export default {
    
    name: 'TMSTrackZonesMap',

    data() {

        return {

            key: 'TqS25W3SZjyvDdCkuP0Y',
            map: null,                  // объект карты 
            mapCenter: {                // центр карты, по дефолту - координаты Полигона НАМИ            
                lat: 56.349024, 
                lng: 37.312356
            },
            mapLayers: null,            // слои карты - для отрисовки карты Полигона НАМИ
            zonesLayer: L.featureGroup(),
            showSpecialLayers: false,   // показать / скрыть спец. карты

            mapZoomMax: 20,             // максимальный зум карты
            mapZoomMin: 5,              // минимальный зум карты     
            mapZoomCurrent: 16,         // текущий зум карты

            eventForwarder: null,       // ловушка событий
            selectedItems: [],          // список фигур для списка в попапе
            isPopupOpen: false,         // состояние попапа: открыть/закрыть
            popupLinks: null            // ссылки в Popup 
        }
    },

    props: {

        trackUuid: {

            required: true,
            type: String
        }
    },

    computed: {

        ...mapState({

            trackZonesMap:          state => state.trackZonesMap.trackZonesMap,
            uuidToLeafletIdLinks:   state => state.zones.uuidToLeafletIdLinks // массив связей leaflet_id - uuid зоны
        }),

        ...mapGetters([

            'getTranslate',
            'getZonesArrayIsNotEmpty',  // объект зон не пустой - зоны загружены
            'getZonesSortedByZindex',   // зоны отсортированные по z-index
        ])
    },

    methods: {

        ...mapActions([

            'readTrackZonesMap'
        ]),

        ...mapMutations([

            'setZones',
            'setZonesPart',
            'setMapZoom',               // мутация для установки уровня зума
        ]),

        /**
         * * Инициализация ловушки событий
         */
        initEventForwarder() {
            this.eventForwarder = new L.eventForwarder({
                map: this.map,
                events: {
                    click: true
                }
            })

            this.eventForwarder.enable()
        },

        /**
         * * Инициализация попапа
         */
        initPopup() {

            this.popup = L.popup()

            // очищаем список выбранных зон при закрытии попапа
            this.popup.on('remove', () => {
                this.selectedItems = []
                this.isPopupOpen = false

                //Очистка ссылок из Popup
                this.clearPopupLinks()
            })
        },

        /**
         * * Выбор зон для всплывающего окна
         */
        selectItem(e) {
            this.selectedItems.push(e.target)

            const popLocation = e.latlng
            const popContent = makePopup(popLocation, this.selectedItems)

            this.popup.setLatLng(popLocation).setContent(popContent)   
            
            this.isPopupOpen = true
        },
        
        /**
         * * Определение коэффициента метр / пиксель при текущем масштабе карты
         * * источник: Leaflet-corridor
         * * не в computed, так как, при изменении масштаба карты, необходимо пересчитывать значение (карта не реактивна)
         */
         getMetersPerPixel() {

            const centerLatLng = this.map.getCenter()                       // get map center
            const pointC = this.map.latLngToContainerPoint(centerLatLng)    // convert to containerpoint (pixels)
            const pointX = L.point(pointC.x + 10, pointC.y)                 // add 10 pixels to x
            const latLngX = this.map.containerPointToLatLng(pointX)         // convert containerpoints to latlng's

            return centerLatLng.distanceTo(latLngX) / 10                    // calculate distance between c and x (latitude)
        },

        /**
         * * Рендеринг зон
         */
        renderZones() {

            if(this.trackZonesMap.array) {
                
                this.map.removeLayer(this.zonesLayer)
                this.zonesLayer.clearLayers()

                for (let zoneUuid of Object.keys(this.trackZonesMap.array)) {

                    const trackZone = this.trackZonesMap.array[zoneUuid]
                    const zone = trackZone.zone

                    let layer = null

                    // рендеринг линии
                    if ('line' in zone.zone) {

                        const coords = makeRenderCoords(zone.zone.line.array)
                        const options = makeRenderOptions(zone, this.getMetersPerPixel())
                    
                        layer = L.polyline(coords, options)
                    }

                    // рендеринг многоугольника
                    if ('polygon' in zone.zone) {
                        const coords = makeRenderCoords(zone.zone.polygon.array)
                        const options = makeRenderOptions(zone)

                        layer = L.polygon(coords, options)
                    }

                    // рендеринг окружности
                    if ('circle' in zone.zone) {
                        const options = makeRenderOptions(zone)
                        const { lat, lon } = zone.zone.circle.point
                        const { radius } = zone.zone.circle

                        layer = L.circle([ lat, lon ], { ...options, radius })
                    }

                    // рендеринг сектора
                    if ('sector' in zone.zone) {
                        const options = makeRenderOptions(zone)
                        const { lat, lon } = zone.zone.sector.circle.point
                        const outerRadius = zone.zone.sector.circle.radius
                        const innerRadius = 0
                        const { startBearing, endBearing } = zone.zone.sector

                        layer = new L.Sector({
                            ...options,
                            center: [ lat, lon ],
                            outerRadius,
                            innerRadius,
                            startBearing,
                            endBearing
                        })
                    }

                    layer.uuid = zone.uuid
                    layer.name = zone.name

                    if(layer) {

                        layer.addTo(this.zonesLayer).on('click', this.selectItem)
                    }
                }

                this.map.addLayer(this.zonesLayer)
            }

            this.zonesAreShown = true
        },

        clearPopupLinks () {
            if (this.popupLinks) {

                for (let link of this.popupLinks) {
                    link.removeEventListener('click', this.popupLinkHandler )                        
                }
            }          

            this.popupLinks = null
        },

        popupLinkHandler(e) {
            e.preventDefault()

            //Очистка ссылок из Popup
            this.clearPopupLinks()

            this.$router.push(e.target.pathname)
        }
    },

    created() {

        //fix for closing popup on map with animateZoom
        L.Popup.prototype._animateZoom = function (e) {
            if (!this._map) {
                return
            }
            let pos = this._map._latLngToNewLayerPoint(
                this._latlng,
                e.zoom,
                e.center
            ),
            anchor = this._getAnchor()
            L.DomUtil.setPosition(this._container, pos.add(anchor))
        }

        this.readTrackZonesMap({ trackUuid: this.trackUuid, "c.limit": 1000 })
    }, 

    mounted() {

        // установка центра карты
        if (localStorage.getItem('TMS_MAP_CENTER')) {
            let { lat, lng } = JSON.parse(localStorage.getItem('TMS_MAP_CENTER'))
            this.mapCenter = [ lat, lng ]
        }

        // установка активных слоев
        if (localStorage.getItem('TMS_MAP_LAYERS')) {
            this.showSpecialLayers = JSON.parse(localStorage.getItem('TMS_MAP_LAYERS'))
        }

        // установка зума
        if (localStorage.getItem('TMS_MAP_ZOOM')) {
            this.mapZoomCurrent = JSON.parse(localStorage.getItem('TMS_MAP_ZOOM'))
        }

        // добавление карты
        this.map = L.map(this.$refs.mapContainer, {
            center: this.mapCenter,
            zoomControl: false,
            zoom: this.mapZoomCurrent,
            minZoom: this.mapZoomMin,
            maxZoom: this.mapZoomMax
        })

        // основная карта
        const mtLayer = L.maptilerLayer({
            apiKey: this.key,
            language: L.MaptilerLanguage.RUSSIAN,
            style: L.MaptilerStyle.OPENSTREETMAP //STREETS, BASIC, OPENSTREETMAP 
        }).addTo(this.map)

        const maptilerMap = mtLayer.getMaptilerSDKMap()

        maptilerMap.on('load', () => {
            //Remove copyright            
            const mapContainer = document.getElementById('TMSTrackZonesMap')
            const copyrightLink = Array.from(mapContainer.getElementsByTagName("a")).find(el => el.href == 'https://www.maptiler.com/')
            copyrightLink.remove()
        })

        // карта НАМИ и НИЦИАМТ
        this.mapLayers = L.tileLayer("https://dev.shatl-t.ru/Tiles/{z}/{x}/{y}.png", {
            minZoom: this.mapZoomMin,
            maxZoom: this.mapZoomMax
        })

        // добавляем управление зумом
        L.control.zoom({ position: 'bottomright' }).addTo(this.map)

        // сохраняем в браузер локацию, на которой находится карта клиента
        this.map.on('moveend', () => {

            localStorage.setItem('TMS_MAP_CENTER', JSON.stringify(this.map.getCenter()))
        })

        // изменение масштаба карты
        this.map.on('zoomend', () => this.renderZones())

        if(this.trackZonesMap.array) {

            this.renderZones()
        }

        // инициализируем попап
        this.initPopup()

        // инициализируем L ловушку событий
        this.initEventForwarder()

        const resizeObserver = new ResizeObserver(() => this.map.invalidateSize())
        resizeObserver.observe(this.$refs.mapContainer)
    },

    watch: {

        // показать / скрыть спец. карты
        showSpecialLayers() {

            localStorage.setItem('TMS_MAP_LAYERS', this.showSpecialLayers)

            if (this.showSpecialLayers) {
                this.mapLayers.addTo(this.map)
            } else {
                this.map.removeLayer(this.mapLayers)
            }
        },

        trackZonesMap() {

            this.renderZones()
        },

        // открываем попап
        isPopupOpen:  {
            handler (value) {
                
                if (value) {
                    this.popup.openOn(this.map)

                    this.popupLinks = document.getElementsByClassName('tms-zones-map__tip-link')

                    for (let link of this.popupLinks) {
                        link.addEventListener('click', this.popupLinkHandler )                        
                    }
                }
            },

            deep: true  
        }

    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/track-zones-map';
</style>