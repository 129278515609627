<template>

    <div class="tms-profile">

        <div
            v-if="'success' in profileUpdateMsg"
            class="alert"
            :class="{

                'alert--success' : profileUpdateMsg.success,
                'alert--danger' : !profileUpdateMsg.success
            }"
        >
            <div class="alert__text">
                {{ profileUpdateMsg.text }}
            </div>
            <div class="alert__close" @click="setUpdateProfileMsg({})">
                <span class="material-symbols-outlined">
                    close
                </span>
            </div>
        </div>

        <form @submit.prevent="submitForm($event)">

            <div class="list">

                <div class="list__item">
                    <div class="list__item-title">{{ getTranslate.USER_UUID }}: </div>
                    <span class="list__item-value">
                        {{ profile.uuid }}
                    </span>
                </div>

                <div class="list__item">
                    <div class="list__item-title">{{ getTranslate.USER_LOGIN }}: </div>
                    <template v-if="!showEditForm">
                        <span class="list__item-value">{{ profile.login }}</span>
                    </template>
                    <template v-else>
                        <input 
                            type="text"
                            id="TMSProfileLogin" 
                            class="input input--block"
                            v-model="TMSProfileLogin"
                        />
                    </template>
                </div>

                <div class="list__item" v-if="showEditForm">
                    <div class="list__item-title">{{ getTranslate.USER_PASS }}: </div>
                    <input 
                        id="TMSProfilePass" 
                        :type="showPassword ? 'text' : 'password'"
                        v-model="TMSProfilePass"
                        autocomplete="off"
                        class="input input--block"
                    />
                </div>

                <div class="list__item">
                    <div class="list__item-title">{{ getTranslate.USER_TYPE }}: </div>
                    <template v-if="showEditForm && profile.type == 'adminT'">
                        <select
                            id="TMSProfileType" 
                            v-model="TMSProfileType"
                            class="input input--block"
                        >
                            <option value="null" selected>...</option>
                            <option v-for="(item, index) in profileTypeSelect" :key="index" :value="item.value">{{ item.name }}</option>
                        </select>
                    </template>
                    <template v-else>
                        <span class="list__item-value">{{ profileType }}</span>
                    </template>
                </div>

                <div class="list__item">
                    <div class="list__item-title">{{ getTranslate.USER_ACCESS }}: </div>
                    <template v-if="!showEditForm && profile.type == 'adminT'">
                        <select
                            id="TMSProfileAccess" 
                            v-model="TMSProfileAccess"
                            class="input input--block"
                        >
                            <option value="null" selected>...</option>
                            <option v-for="(item, index) in profileAccessSelect" :key="index" :value="item.value">{{ item.name }}</option>
                        </select>
                    </template>
                    <template v-else>
                        <span class="list__item-value">{{ profileAccess }}</span>
                    </template>
                </div>
            </div>

            <div class="container-fluid" v-if="!showEditForm">
                <div class="btns btns--right my-4">
                    <a 
                        href="#"
                        class="btn btn--round btn--secondary"
                        @click.prevent="showEditForm = true"
                    >
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </a>
                </div>
            </div>

            <div class="container-fluid" v-if="showEditForm">
                <div class="btns my-4">
                    <button
                        class="btn"
                        type="button"
                        @click="showEditForm = false"
                    >
                        {{ getTranslate.BTN_CANCEL }}
                    </button>

                    <button
                        class="btn btn--primary"
                        type="submit"
                    >
                        {{ getTranslate.BTN_SAVE }}
                    </button>
                </div>
            </div>

        </form>

    </div>

</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: 'TMSProfile',

    data() {

        return {

            valid: true,

            TMSProfileLogin: null,
            TMSProfilePass: null,
            TMSProfileType: null,
            TMSProfileAccess: null,

            showEditForm: false,
            showPassword: false,

            formValid: false
        }
    },

    computed: {

        ...mapState({

            profile:            state => state.profile.profile,
            profileUpdateMsg:   state => state.profile.profileUpdateMsg
        }),

        ...mapGetters([

            'getTranslate'
        ]),

        profileTypeSelect() {

            return [

                { name: this.getTranslate.USER_TYPE_NO_TYPE,          value: 'noT' },
                { name: this.getTranslate.USER_TYPE_OPERATOR,         value: 'operT' },
                { name: this.getTranslate.USER_TYPE_ADMINISTRATOR,    value: 'adminT' }
            ]
        },

        profileAccessSelect() {

            return [

                { name: this.getTranslate.ACCESS_NO,                value: 'nA'     },
                { name: this.getTranslate.ACCESS_READ,              value: 'rA'     },
                { name: this.getTranslate.ACCESS_READ_WRITE,        value: 'rwA'    },
                { name: this.getTranslate.ACCESS_READ_WRITE_DELETE, value: 'rwdA'   }
            ]
        },

        profileAccess() {

            switch(this.profile.access) {

                case 'nA':      return this.getTranslate.ACCESS_NO
                case 'rA':      return this.getTranslate.ACCESS_READ
                case 'rwA':     return this.getTranslate.ACCESS_READ_WRITE
                case 'rwdA':    return this.getTranslate.ACCESS_READ_WRITE_DELETE
                default:        return this.getTranslate.ACCESS_NO
            }
        },

        profileType() {

            switch(this.profile.type) {

                case 'noT':     return this.getTranslate.USER_TYPE_NO_TYPE
                case 'operT':   return this.getTranslate.USER_TYPE_OPERATOR
                case 'adminT':  return this.getTranslate.USER_TYPE_ADMINISTRATOR
                default:        return this.getTranslate.USER_TYPE_NO_TYPE
            }
        }
    },

    methods: {

        ...mapActions([

            'readProfile',
            'updateProfile'
        ]),

        ...mapMutations([

            'setPageHeader',
            'setUpdateProfileMsg'
        ]),

        submitForm() {

            let data = {

                uuid:   this.profile.uuid,
                type:   this.TMSProfileType,
                access: this.TMSProfileAccess
            }

            this.formInvalids = {}
            this.formErrors = []

            if(this.TMSProfileLogin) {

                data.login = this.TMSProfileLogin
                this.valid = true

            } else {

                this.formInvalids['TMSProfileLogin'] = true
                this.valid = false
            }

            if(this.TMSProfilePass) {

                data.password = this.TMSProfilePass
            }

            if(this.valid) {

                this.showEditForm = false
                this.updateProfile(data)
            }
        },

        async copyUUID() {

            await navigator.clipboard.writeText(this.profile.uuid)
        }
    },

    mounted() {

        this.readProfile()
    },

    beforeUnmount() {

        this.setPageHeader(null)
        this.setUpdateProfileMsg({})
    },

    watch: {

        profile(profile) {

            this.TMSProfileLogin    = profile.login
            this.TMSProfilePass     = ''
            this.TMSProfileType     = profile.type
            this.TMSProfileAccess   = profile.access
        }
    }
}
</script>